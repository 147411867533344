import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GroupChannelDto } from 'apis/types/chat.type';

type Props = {
  channel: GroupChannelDto;
};

const ChatWelcomeMessage: React.FC<Props> = ({ channel }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* S : 채널 생성 후 첫 화면 문구 (이 문구 하단으로 채팅이 쌓이면서 상단으로 사라집니다.) */}
      <div className="my-10 flex flex-col items-center text-center">
        <img className="size-16 rounded-2" src={channel?.coverUrl} />
        {/* span 안에 해당 채널명이 들어갑니다. */}
        <div className="mt-3 text-20 font-semibold text-black-title">
          {t('chat.firstCreateMessage.welcomeTo')}
          <br />
          <span className="text-primary-100">{channel?.name}</span>
        </div>
        {/* span 안에 해당 채널명이 들어갑니다. */}
        <div className="mt-3 text-14 text-gray-700">
          <Trans i18nKey="chat.firstCreateMessage.startMessage">
            <span className="font-medium">{{ channelName: channel?.name } as any}</span>
          </Trans>
        </div>
      </div>
      {/* E : 채널 생성 후 첫 화면 문구 */}
    </>
  );
};
export default ChatWelcomeMessage;
