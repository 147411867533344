import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  className: string;
  children?: ReactNode;
};

const BackButton: React.FC<Props> = ({ className, children }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  );
};
export default BackButton;
