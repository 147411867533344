import _ from 'lodash';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { modalStackState } from 'recoil/modalStack';

const useModalStack = () => {
  const setModalStack = useSetRecoilState(modalStackState);
  const clear = useResetRecoilState(modalStackState);

  const push = (modalComponent: any) => {
    setModalStack((modalStack) => [...modalStack, modalComponent]);
  };

  const remove = (key: string) => {
    setModalStack((modalStack) => modalStack.filter((modalComponent) => modalComponent.key !== key));
  };

  const pop = () => {
    setModalStack((modalStack) => modalStack.slice(0, -1));
  };

  const update = _.curry((key: string, componentProps: any) => {
    setModalStack((modalStack) =>
      modalStack.map((modal) => {
        if (modal.key === key) {
          return { ...modal, componentProps: { ...modal.componentProps, ...componentProps } };
        }
        return modal;
      })
    );
  });

  return { push, remove, pop, clear, update };
};

export default useModalStack;
