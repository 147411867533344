import { atom, selectorFamily } from 'recoil';

import { ChatMessage } from '../../types/common';

export type ChatMessageState = {
  [key in number]: ChatMessage;
};

const chatMessagesState = atom<ChatMessageState>({
  key: 'chatMessagesState',
  default: {}
});

export const chatMessageStateByMessageId = selectorFamily<ChatMessage, number>({
  key: 'chatMessageStateByMessageId',
  get:
    (id) =>
    ({ get }) => {
      return get(chatMessagesState)[id];
    }
  // set:
  //   (id) =>
  //   ({ set }, newValue) => {
  //     return set(chatMessagesState, (state) => {
  //       return {
  //         ...state,
  //         [id]: newValue as ChatMessage,
  //       };
  //     });
  //   },
});

export const chatMessageIdsState = atom<number[]>({
  key: 'chatMessageIdsState',
  default: []
});

// export const chatMessagesState = selectorFamily({
//   key: "chatMessagesState",
//   get:
//     (messageId: number) =>
//     ({ get }) => {
//       return get(chatMessageState(messageId));
//     },
//   set:
//     (messageId: number) =>
//     ({ set }, message) => {
//       set(chatMessageState(messageId), message);
//       set(chatMessageIdsState, (prev) => [...prev, messageId]);
//     },
// });

// UnreadIndicator를 표시하기 위한 메세지 id
export const lastUnreadMessageIdState = atom<number>({
  key: 'lastUnreadMessageIdState',
  default: 0
});

export default chatMessagesState;
