import Web3 from 'web3';

import ERC20_ABI from '../components/wallet/jsons/ERC20.json';

import { CRETA_META } from 'components/wallet/constants';

export const isWebView = () => {
  return typeof window !== 'undefined' && chrome?.webview != null;
  // return !(typeof window !== 'undefined' && chrome?.webview != null);
};

export const getWeb3Webview = () => {
  return new Web3(new Web3.providers.HttpProvider('https://polygon-rpc.com'));
};

/**
 * 런처와 이벤트 통신시 txhash 값을 만들어주는 함수
 */
export const createApproveData = (from: string, to: string, amount: string): string => {
  const web3 = getWeb3Webview();

  const contract = new web3.eth.Contract(ERC20_ABI as any, CRETA_META.contractAddress, { from });
  const amountWei = web3.utils.toWei(amount.toString(), 'ether');
  const txData = contract.methods.transfer(to, amountWei).encodeABI();
  return txData;
};

/**
 * 현재 가스 가격을 찾는 함수
 */
export const getGasPrice = async () => {
  const web3 = getWeb3Webview();

  const gas = await web3.eth.getGasPrice();
  return gas;
};

/**
 * 런처와 통신 후 txhash 만 응답에 받기때문에 트랜잭션 정보를 가져오기 위한 함수
 * 시간이 조금 걸려서 2초 대기하는 로직추가
 */
export const getTransactionByHash = async (hash: string) => {
  const web3 = getWeb3Webview();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    console.log('Waiting for transaction to be mined...');
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 2초 대기

    const receipt = await web3.eth.getTransactionReceipt(hash);
    if (receipt) return receipt;
  }
};
