import { useMemo } from 'react';

import { User } from '@sendbird/chat';

import { getIsMaster, getIsStaff } from '../libs/helper';
import { ChatMessage, CustomMessageType } from '../types/common';

import { useCurrentChannel } from './use-current-channel';
import useCurrentUser from './useCurrentUser';

const useMessage = ({ message }: { message?: ChatMessage }) => {
  // const currentChannel = useRecoilValue(groupChannelState);
  const { data: currentChannel } = useCurrentChannel();
  const { currentUser } = useCurrentUser();

  const data = useMemo(() => {
    if (!message) {
      return {};
    }

    try {
      if (!message.data) return {};

      // 파이썬 임시 변환용

      const replaceData = message.data
        .replace(/:\s*True/g, ': true')
        .replace(/:\s*False/g, ': false')
        .replace(/:\s*None/g, ': null')
        .replace(/'/g, '"');

      return JSON.parse(replaceData);
    } catch (error) {
      console.log('Failed to parse message data', message);
      console.log(error);
      return {};
    }
  }, [message?.data]);

  const customType = useMemo(() => {
    if (!message) {
      return CustomMessageType.TEXT;
    }

    if (!message.customType) {
      return CustomMessageType.TEXT;
    }

    if (message.customType === 'SENDBIRD:AUTO_EVENT_MESSAGE') {
      return CustomMessageType.SYSTEM;
    }

    return message.customType as CustomMessageType;
  }, [message?.customType]);

  const messageType = useMemo(() => {
    if (data.type === 'CHANNEL_CREATE') {
      return CustomMessageType.CREATED_CHANNEL;
    } else if (data.type === 'CHANNEL_CHANGE') {
      return CustomMessageType.CHANNEL_CHANGE;
    }

    return CustomMessageType.UNKNOWN;
  }, [customType, data]);

  const user = useMemo(() => {
    if (!message) {
      return null;
    }
    return message.sender;
  }, [message]);

  const isMine = useMemo(() => {
    if (customType === CustomMessageType.SYSTEM) return false;
    if (customType === CustomMessageType.MASTER_TRANSFER) {
      return `${data.user?.id}` === currentUser?.userId;
    }

    return user?.userId === currentUser?.userId;
  }, [customType, user]);

  const isMaster = getIsMaster({ channel: currentChannel, user });
  const isStaff = getIsStaff({ channel: currentChannel, user });

  const userId = useMemo(() => {
    if (customType === CustomMessageType.SYSTEM) return 'system';
    if (customType === CustomMessageType.MASTER_TRANSFER) {
      return data.user?.id;
    }
    return user?.userId;
  }, [customType, user]);

  const isLikedByMe = useMemo(() => {
    if (!currentUser) {
      return false;
    }

    if (!message) {
      return false;
    }

    return message.reactionUserIds.indexOf((currentUser as User).userId) > -1;
  }, [message, currentUser]);

  return {
    data,
    customType,
    messageType,
    user,
    isLikedByMe,
    userId,
    isMine,
    isMaster,
    isStaff
  };
};

export default useMessage;
