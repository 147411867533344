import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilState } from 'recoil';

import AddUserModal from '../add-user-modal';
import EnterAtOnceModal from '../enter-at-once-modal';

import SelectedUserInput from './selected-user-input';

import { CheckIcon, PlusIcon } from 'assets/iicon';
import classNames from 'components/styled/util';
import { multiCoinSendMapState } from 'components/wallet/recoil';
import useModalStack from 'routers/modal/useModalStack';

interface UserSelectProps {
  userList: any[];
  setUserList: Dispatch<SetStateAction<any[]>>;

  channelId: string;

  singleSelect?: boolean;
}

const UserSelect = (props: UserSelectProps) => {
  const { userList, setUserList, channelId, singleSelect = false } = props;

  const { t } = useTranslation();

  const modalStack = useModalStack();
  const [_, setMap] = useRecoilState(multiCoinSendMapState);

  const onClickAddUser = () => {
    modalStack.push({
      Component: AddUserModal,
      componentProps: {
        initialData: userList,
        onSubmit: (list: any[]) => {
          setMap((prev) => {
            const newMap = prev.filter((item) => list.some((user) => user.id === item.id));
            return newMap;
          });
          setUserList(list);
        },
        channelId,
        singleSelect
      },
      key: 'send-coin-add-user-modal'
    });
  };

  const onClickRemove = (v: any, index: number) => {
    setUserList((prev) => prev.filter((item) => item.id !== v.id));

    setMap((prev) => {
      if (index === -1) return prev;
      return [...prev.filter((obj) => obj.id !== v?.id)];
    });
  };

  const onOpenEnterAtOnce = () => {
    modalStack.push({
      key: 'enter-at-once-modal',
      Component: EnterAtOnceModal,
      componentProps: {
        userList: userList
      }
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <h3 className="text-16 font-semibold">
          <span className="text-20 text-primary-100">{userList.length}</span>
          &nbsp;User
        </h3>

        {userList.length > 0 && (
          <button
            className="flex items-center rounded-1 border border-gray-500 bg-gray-100 px-2 py-[4.5px] text-13 text-black-title"
            onClick={onOpenEnterAtOnce}
          >
            <CheckIcon className="size-4 text-black-title" />
            {t('sendCoin.enterAtOnce.title')}
          </button>
        )}
      </div>

      <div className="scroll-none max-h-[328px] overflow-y-scroll rounded-2 border border-gray-300 bg-white p-5">
        {userList.map((v, i) => {
          return <SelectedUserInput key={v.id} v={v} onClickRemove={(v) => onClickRemove(v, i)} />;
        })}

        <button
          className={classNames(
            'group flex h-10 w-full items-center justify-center gap-1 rounded-1.5 bg-gray-100 py-2 text-14 font-medium text-gray-500 transition-all hover:bg-gray-200 hover:text-black-title',
            { 'mt-2.5': userList.length > 0 }
          )}
          onClick={onClickAddUser}
        >
          <PlusIcon className="size-4 text-gray-600 transition-all group-hover:text-black-title" />
          {t('sendCoin.addUser.button')}
        </button>
      </div>
    </div>
  );
};

export default UserSelect;
