export enum FileType {
  File = 'FILE',
  Gif = 'GIF',
  Photo = 'PHOTO',
  Video = 'VIDEO'
}

export interface UploadToS3Response {
  localUrl: string;
  cloudFrontUrl: string;
  fileKey: string;
  fileName?: string;
  fileSize: number;
  fileType: FileType;
  contentType: string;
  orgFileName: string;
}

export const downloadS3File = async (url: string) => {
  try {
    // URL에서 파일명 추출
    const fileName = url.split('/').pop() || 'downloaded-file';

    // fetch를 사용하여 파일 다운로드
    const response = await fetch(url);
    const blob = await response.blob();

    // 파일 다운로드 링크 생성
    const fileUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);

    // 다운로드 실행
    document.body.appendChild(link);
    link.click();

    // 정리
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
    URL.revokeObjectURL(fileUrl); // 메모리 누수 방지를 위해 URL 해제
  } catch (error) {
    console.error('파일 다운로드 중 오류 발생:', error);
  }
};
