import SendbirdChat, { type MetaData } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelListOrder,
  GroupChannelListQuery,
  type GroupChannelListQueryParams,
  GroupChannelModule,
  MembershipFilter,
  MyMemberStateFilter,
  PublicGroupChannelListQuery,
  type PublicGroupChannelListQueryParams
} from '@sendbird/chat/groupChannel';
import type {
  ApplicationUserListQueryParams,
  BannedUserListQueryParams,
  MessageRetrievalParams,
  ModuleNamespaces,
  OperatorListQueryParams,
  Poll,
  PollListQuery,
  PreviousMessageListQuery,
  PreviousMessageListQueryParams,
  User,
  UserMessage
} from '@sendbird/chat/lib/__definition';
import { OpenChannelModule } from '@sendbird/chat/openChannel';
import axios from 'axios';
import { uniqBy } from 'lodash';

import { SENDBIRD_ERROR_USER_NOT_FOUND } from '../constants/common';
import { ChatChannel } from '../types/common';

import { SuperClubUserInterface } from './ApiRepository';
import { getUser } from './chatApi';

type SendBirdModule = SendbirdChat & ModuleNamespaces<[GroupChannelModule, OpenChannelModule]>;

interface SendBirdUserInterface {
  user_id: string;
  nickname: string;
  profile_url: string;
  access_token: string;
  is_online: boolean;
  is_active: boolean;
  is_created: boolean;
  phone_number: string;
  require_auth_for_profile_image: boolean;
  session_tokens: string[];
  last_seen_at: number;
  discovery_keys: string[];
  preferred_languages: string[];
  has_ever_logged_in: boolean;
}

let sb: SendBirdModule | null = null;

export const APP_ID = process.env.REACT_APP_SENDBIRD_APP_KEY || '';
// console.log("APP_ID", APP_ID);
export const API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN || '';
const SENDBIRD_API_URL = `https://api-${APP_ID}.sendbird.com`;
// console.log("SENDBIRD_API_URL", SENDBIRD_API_URL);
export declare type KeyValuePair = [string, string | null];

const sbAxiosInstance = axios.create({
  baseURL: `${SENDBIRD_API_URL}/v3/`,
  headers: {
    'Content-Type': 'application/json; charset=utf8',
    'Api-Token': API_TOKEN
  }
});

function getSendBird(): SendBirdModule {
  if (!sb) {
    sb = SendbirdChat.init({
      appId: APP_ID,
      modules: [new GroupChannelModule(), new OpenChannelModule()],
      localCacheEnabled: true
    });
    return sb;
  }

  return sb;
}

export default getSendBird;

export async function getAccessToken(userId: string) {
  try {
    const response = await sbAxiosInstance.get<SendBirdUserInterface>(`users/${userId}`);

    return response.data.access_token;
  } catch (e) {
    const errorData = (e as any).response.data;
    if (errorData.error === true) {
      if (errorData.code === SENDBIRD_ERROR_USER_NOT_FOUND) {
        const access_token = await createUser(userId);
        return access_token;
      }
    }
    throw e;
  }
}

export async function createUser(userId: string) {
  // userId를 가지고 공통 서버에서 유저정보를 가져옵니다
  const usersStateData: SuperClubUserInterface = await getUser(userId);
  // 공통서버에서 가져온 유저정보를 샌드버드 필요한 정보를 추출해 넣어 유저를 생성합니다.
  const response = await axios.post<SendBirdUserInterface>(
    `${SENDBIRD_API_URL}/v3/users`,
    {
      user_id: userId,
      nickname: usersStateData.username,
      profile_url: usersStateData.profile_image_url,
      issue_access_token: true,
      session_token_expires_at: 1542945056625,
      metadata: {
        name: usersStateData.username
      }
    },
    {
      headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Api-Token': API_TOKEN
      }
    }
  );
  return response.data.access_token;
}

export const fetchGroupChannel = async (channelUrl: string, withoutCache: boolean = false) => {
  if (withoutCache) {
    const groupChannel = await getSendBird().groupChannel.getChannelWithoutCache(channelUrl);
    return groupChannel;
  }

  const groupChannel = await getSendBird().groupChannel.getChannel(channelUrl);
  return groupChannel;
};

export const fetchInvitedChannels = async (): Promise<GroupChannel[]> => {
  const params: GroupChannelListQueryParams = {
    myMemberStateFilter: MyMemberStateFilter.INVITED,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    limit: 100 // The value of the pagination limit could be set up to 100.
  };

  const query: GroupChannelListQuery = getSendBird().groupChannel.createMyGroupChannelListQuery(params);
  const channels: GroupChannel[] = [];
  while (query.hasNext) {
    const nextChannels = await query.next();
    channels.push(...nextChannels);
  }

  return channels;
};

export const fetchGroupChannels = async (): Promise<GroupChannel[]> => {
  const params: GroupChannelListQueryParams = {
    myMemberStateFilter: MyMemberStateFilter.JOINED,
    order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    includeEmpty: true,
    limit: 100 // The value of the pagination limit could be set up to 100.
  };

  const query: GroupChannelListQuery = getSendBird().groupChannel.createMyGroupChannelListQuery(params);
  const channels: GroupChannel[] = [];
  while (query.hasNext) {
    const nextChannels = await query.next();
    channels.push(...nextChannels);
  }

  return channels;
};

export const fetchPublicChannels = async (): Promise<GroupChannel[]> => {
  const params: PublicGroupChannelListQueryParams = {
    membershipFilter: MembershipFilter.ALL,
    includeEmpty: true,
    limit: 100 // The value of the pagination limit could be set up to 100.
  };

  const query: PublicGroupChannelListQuery = getSendBird().groupChannel.createPublicGroupChannelListQuery(params);
  const channels: GroupChannel[] = [];
  while (query.hasNext) {
    const nextChannels = await query.next();
    channels.push(...nextChannels);
  }

  return channels;
};

export const fetchAllChannels = async () => {
  const groupChannels = await fetchGroupChannels();
  const publicChannels = await fetchPublicChannels();
  const channels = [...groupChannels, ...publicChannels];
  return uniqBy(channels, 'url');
};

export const deleteGroupChannel = async (channel_url: string) => {
  const channel: GroupChannel = await getSendBird().groupChannel.getChannel(channel_url);
  return await channel.delete();
};

export const fetchUsers = async (params?: ApplicationUserListQueryParams): Promise<User[]> => {
  const query = getSendBird().createApplicationUserListQuery(params);
  const users = await query.next();

  return users;
};

export const fetchOperators = async (channel: GroupChannel, params?: OperatorListQueryParams): Promise<User[]> => {
  const query = channel.createOperatorListQuery(params);

  const operators = await query.next();
  return operators;
};

export const fetchBannedUsers = async (channel: GroupChannel, params?: BannedUserListQueryParams): Promise<User[]> => {
  const query = channel.createBannedUserListQuery(params);

  const bannedUsers = await query.next();
  return bannedUsers;
};

export const fetchPollList = async (channel: ChatChannel, limit?: number | undefined) => {
  const groupChannel = await fetchGroupChannel(channel.url);
  const query: PollListQuery = groupChannel.createPollListQuery(limit);
  const polls: Poll[] = await query.next();
  return polls;
};

export const fetchMessages = async (channelUrl: string, params: PreviousMessageListQueryParams) => {
  const groupChannel = await fetchGroupChannel(channelUrl);
  const query: PreviousMessageListQuery = groupChannel.createPreviousMessageListQuery({
    limit: 30,
    reverse: false,
    // messageTypeFilter: MessageTypeFilter.ALL,
    // replyType: ReplyType.ALL,
    includeThreadInfo: true,
    includeParentMessageInfo: true,
    includeMetaArray: true,
    includeReactions: true,
    ...params
  });

  const messages = await query.load();

  return messages;
};

export const fetchMessage = async (channelUrl: string, messageId: number) => {
  const groupChannel = await fetchGroupChannel(channelUrl);
  const params: MessageRetrievalParams = {
    messageId,
    channelType: groupChannel.channelType,
    channelUrl: groupChannel.url,
    includeParentMessageInfo: true,
    includeReactions: true,
    includeThreadInfo: true,
    includeMetaArray: true
  };
  const message = await getSendBird().message.getMessage(params);
  return message as UserMessage;
};

export const searchMessages = async (channelUrl: string, keyword: string) => {
  const groupChannel = await fetchGroupChannel(channelUrl);
  const query = getSendBird().createMessageSearchQuery({
    channelUrl: groupChannel.url,
    channelCustomType: groupChannel.customType,
    limit: 20,
    exactMatch: false,
    reverse: false,
    keyword
  });
  const messages = await query.next();
  return messages;
};

type ProfanityFilter = {
  custom_type: string;
  profanity_filter: {
    keywords: string;
    regex_filters: { regex: string }[];
  };
};

export async function setProfanityFilter(customType: string, keywords: string[]) {
  const response = await axios.put<ProfanityFilter>(
    `${SENDBIRD_API_URL}/v3/applications/settings_by_channel_custom_type/${customType}`,
    {
      custom_type: customType,
      profanity_filter: {
        keywords: keywords.join(','),
        type: 2,
        should_check_global: true
      }
    },
    {
      headers: {
        'Content-Type': 'application/json; charset=utf8',
        'Api-Token': API_TOKEN
      }
    }
  );
  return response.data;
}

export async function getProfanityFilter(customType: string) {
  const response = await axios.get<ProfanityFilter>(
    `${SENDBIRD_API_URL}/v3/applications/settings_by_channel_custom_type/${customType}`,
    {
      headers: {
        'Api-Token': API_TOKEN
      }
    }
  );
  return response.data;
}

export async function updateUserMetadata(userId: string, metadata: MetaData) {
  const response = await axios.put(
    `${SENDBIRD_API_URL}/v3/users/${userId}/metadata`,
    {
      metadata,
      upsert: true
    },
    {
      headers: {
        'Api-Token': API_TOKEN
      }
    }
  );
  return response?.data;
}

// export async function updateMessage(
//   channel: ChatChannel,
//   messageId: number,
//   params: UserMessageUpdateParams
// ) {
//   const groupChannel = await fetchGroupChannel(channel.url);
//   const updatedMessage = await groupChannel.updateUserMessage(
//     messageId,
//     params
//   );
//   return updatedMessage;
// }
export type UpdateMessageRequestBody = {
  message?: string;
  custom_type?: string;
  data?: string;
  mention_type?: string;
  mentioned_user_ids?: string[];
};

export async function updateGroupChannelMessage(
  channelUrl: string,
  messageId: number,
  data?: UpdateMessageRequestBody
) {
  const response = await sbAxiosInstance.put(`group_channels/${channelUrl}/messages/${messageId}`, {
    message_type: 'MESG',
    ...data
  });
  return response?.data;
}

export async function fetchUser(userId: string) {
  const queryParams: ApplicationUserListQueryParams = {
    limit: 20,
    userIdsFilter: [userId]
  };
  const query = getSendBird().createApplicationUserListQuery(queryParams);
  const users = await query.next();

  if (users.length > 0) {
    return users[0];
  }

  return null;
}

export async function acceptDMChannel(userId: string | number, channelUrl: string) {
  const response = await sbAxiosInstance.put(`group_channels/${channelUrl}/accept`, { user_id: `${userId}` });
  return response?.data;
}

export const postReportChannel = async ({
  channelType,
  channelUrl,
  payload
}: {
  channelType?: string;
  channelUrl?: string;
  payload?: any;
}) => {
  const response = await sbAxiosInstance.post(`report/${channelType}/${channelUrl}`, payload);
  return response.data;
};

export const postReportMessage = async ({
  channelType,
  channelUrl,
  messageId,
  payload
}: {
  channelType?: string;
  channelUrl?: string;
  messageId?: string;
  payload?: any;
}) => {
  const response = await sbAxiosInstance.post(`report/${channelType}/${channelUrl}/messages/${messageId}`, payload);
  return response.data;
};
