import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useRecoilValue } from 'recoil';

import { CRETA_META } from '../constants';
import { CRETA_WALLET_EXTENSION_ID, sendMessageToExtension } from '../extension';
import { usePostCoinTransferGroupsMutation } from '../hooks/use-mutation';
import { cretaWalletState, metamaskWalletState, multiCoinSendMapState } from '../recoil';
import { getWalletType, numberUI } from '../util';
import { generateTransactionConfig, submitTransaction, trackTransaction } from '../web3';

import { InfoIcon, WarningColorIcon } from 'assets/iicon';
import ModalLayout from 'components/alert/modal-layout';
import Button from 'components/jayden/Button';
import Input from 'components/jayden/Input';
import classNames from 'components/styled/util';
import useAlert from 'routers/modal/useAlert';
import useModalStack from 'routers/modal/useModalStack';

import useUserMe from 'hooks/use-user-me';
import { useWebViewWallet } from 'hooks/use-webview-wallet';

import { isWebView } from 'utils/wallet';

interface SendConfirmModalProps {
  close: () => void;
  type: 'user' | 'group';

  // 유저전송
  userList?: any[];

  // 그룹 전송
  groupData?: { id: number; wallet_address: string; wallet_type: 'CRETA' | 'METAMASK' }[];
  // 그룹전송 (선택한 드롭다운에서 value가 아닌 label만 보여주기용)
  params?: { category: string; subCategory: string };

  isCreta?: boolean;
}

const SendConfirmModal = (props: SendConfirmModalProps) => {
  const { type = 'user', userList = [], groupData = [], close = () => {}, params, isCreta = true } = props;
  const { t } = useTranslation();

  const myInfo = useUserMe();
  const modalStack = useModalStack();
  const map = useRecoilValue(multiCoinSendMapState);

  const [note, setNote] = useState('');
  const [noteFocus, setNoteFocus] = useState(false);

  const { mutateAsync } = usePostCoinTransferGroupsMutation({});

  const { isBusy: launcherButtonDisabled, onSendCoin } = useWebViewWallet();

  const onSubmitCreta = async () => {
    if (!myInfo) return;

    /**
     * 런처
     */

    if (isWebView()) {
      const from = myInfo.wallet_address_creta || '';
      const { amount } = map[0];

      const walletType = userList[0]?.wallet_type || 'CRETA';
      onSendCoin({ from, toUser: { ...map[0], walletType }, amount, note });
      return;
    }

    /**
     * 익스텐션
     */
    const transferMessage = {
      type: 'multi-transfer',
      value: {
        tokenAddress: '0x202655af326dE310491Cb54f120E02eE0da92b55', // CRETA Token
        recipients: map.map((v) => v.address),
        amounts: map.map((v) => v.amount)
      }
    };

    let transfers: { receiver: number; amount: string; wallet_address: string; wallet_type: 'CRETA' | 'METAMASK' }[] =
      [];

    if (userList.length > 0) {
      transfers = userList.map((v) => ({
        receiver: v.id,
        amount: map.find((obj) => obj.id === v.id)?.amount || '0',
        wallet_address: v.wallet_address,
        wallet_type: getWalletType(v)
      }));
    }

    if (groupData.length > 0) {
      transfers = groupData.map((v) => ({
        receiver: v.id,
        amount: map.find((obj) => obj.id === v.id)?.amount || '0',
        wallet_address: v.wallet_address,
        wallet_type: v.wallet_type
      }));
    }

    try {
      const resultEX = await sendMessageToExtension(CRETA_WALLET_EXTENSION_ID, transferMessage);

      const { data } = resultEX as any;

      const resultSC = await mutateAsync({
        status: 'COMPLETED',
        token_address: CRETA_META.contractAddress,
        token_type: 'CRETA',
        wallet_address: String(data.from),
        gas_price: String(numberUI(data.gasPrice)),
        transaction_fee: String(numberUI(data.transactionFee)),
        token_icon_url: data.tokenIconUrl,
        note,
        transaction_hash: data.transactionHash,
        wallet_type: 'CRETA',
        chain_id: data.chainId,
        transfers
      });

      console.log(`resultSC`, resultSC);
      modalStack.clear();
    } catch (e: any) {
      console.log(`catch`, e);
      if (e.error) {
        alert.open({ contents: t('wallet.cretaCoin.alert.insufficient.contents'), submitOnly: true });
      }
    }
  };

  const [isBusy, setIsBusy] = useState(false);
  const alert = useAlert();
  const onSubmitMetamask = async () => {
    if (!myInfo) return;
    if (!myInfo?.wallet_address_metamask) {
      console.log(`myInfo?.wallet_address_metamask`, myInfo?.wallet_address_metamask);
      return;
    }

    const transactionConfig: any = {
      from: myInfo.wallet_address_metamask,
      to: map[0].address,
      amount: map[0].amount,
      contractAddress: CRETA_META.contractAddress
    };

    let transfers: { receiver: number; amount: string; wallet_address: string; wallet_type: string }[] = [];

    if (userList.length > 0) {
      transfers = userList.map((v) => ({
        receiver: map[0].id,
        amount: map[0].amount,
        wallet_address: v.wallet_address,
        wallet_type: getWalletType(v)
      }));
    } else if (groupData.length > 0) {
      transfers = groupData.map((v) => ({
        receiver: v.id,
        amount: map.find((obj) => obj.id === v.id)?.amount || '0',
        wallet_address: v.wallet_address,
        wallet_type: v.wallet_type
      }));
    }

    let tx;

    /**
     * 메타마스크 트랜잭션 전송
     */
    try {
      setIsBusy(true);
      const transaction = await generateTransactionConfig(transactionConfig);
      console.log('🚀 ~ onSubmitMetamask ~ transaction:', transaction);
      const hashResult = await submitTransaction(transaction);
      console.log('🚀 ~ onSubmitMetamask ~ hash:', hashResult);

      if (hashResult) {
        const transactionResult = await trackTransaction(hashResult);

        if (transactionResult) {
          tx = transactionResult;
        }
      }
    } catch (error: any) {
      console.log('catch error: ', error);
      const isUserDenied = error.message.includes('User denied');
      alert.open({
        contents: isUserDenied ? t('setting.wallet.send.error.cancel') : t('setting.wallet.send.error.transfer'),
        submitOnly: true
      });
    }

    /**
     * 슈퍼클럽 서버에 히스토리 전송
     */
    try {
      if (tx) {
        const payload = {
          status: 'COMPLETED' as const,
          token_address: '0x202655af326dE310491Cb54f120E02eE0da92b55', // CRETA Token
          token_type: 'CRETA',
          wallet_address: String(tx.from),
          gas_price: String(numberUI(tx.effectiveGasPrice)),
          transaction_fee: String(Number(numberUI(tx.gasUsed)) * Number(numberUI(tx.effectiveGasPrice))),
          token_icon_url: CRETA_META.icon,
          note,
          transaction_hash: tx.transactionHash,
          wallet_type: 'METAMASK' as const,
          chain_id: null,
          transfers
        };

        console.log('metamask payload: ', payload);
        const resultSC = await mutateAsync(payload);
        console.log(`resultmt`, resultSC);
      }
    } catch (e) {
      console.log('sc server error: ', e);
    } finally {
      setIsBusy(false);
      modalStack.clear();
    }
  };

  const cretaWallet = useRecoilValue<{ tokens: any[]; nfts: any[] }>(cretaWalletState);
  const metamaskWallet = useRecoilValue(metamaskWalletState);
  const currentVolume =
    (isCreta ? cretaWallet : metamaskWallet).tokens.find((v) => v.symbol === CRETA_META.symbol)?.balance || 0;
  const totalVolume = map.reduce((acc, obj) => acc + Number(obj.amount), 0);
  const needMoreVolume = currentVolume - totalVolume > 0 ? null : Math.abs(currentVolume - totalVolume);

  const buttonBusy = () => {
    if (isWebView()) {
      return launcherButtonDisabled;
    } else {
      return isBusy;
    }
  };

  return (
    <ModalLayout
      className="h-[95vh] max-h-[700px] max-w-[480px]"
      close={close}
      title={
        <>
          <Trans i18nKey="sendCoin.userConfirm.title" values={{ count: userList.length || map.length }}>
            <span className="text-primary-100" />
          </Trans>
        </>
      }
    >
      <div className="flex h-full flex-col justify-between">
        <div className="overflow-auto">
          <div className="px-6">
            {type === 'user' && (
              <div className="gray-scrollbar flex max-h-[200px] flex-col gap-1.5 overflow-y-auto">
                {userList.map((v, i) => {
                  return (
                    <div key={`v.id_${i}`} className="rounded-2 bg-gray-100 p-3">
                      <div className="flex items-end justify-between">
                        <div className="flex items-center gap-2">
                          <img src={v.profile_image_url} className="size-10 rounded-full" />
                          <div>
                            <p className="text-13 text-secondary-100">{v.grade}</p>
                            <p className="text-16 font-medium text-black-title">
                              {v.username}
                              <span className="font-normal text-gray-600">#{v.hash}</span>
                            </p>
                          </div>
                        </div>

                        <div className="w-[142px] rounded-1.5 bg-gray-200 px-2.5 py-2 text-right text-16 font-semibold text-black-body">
                          {numberUI(map.find((m) => m.id === v.id)?.amount || 0)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {type === 'group' && (
              <div className={classNames('mb-2 flex flex-1 flex-col gap-1')}>
                <Input disabled value={params?.category} />
                <Input disabled value={params?.subCategory} />
              </div>
            )}

            <div className="h-5" />

            <div className="flex items-center justify-between rounded-2.5 bg-black-title px-6 py-[18px]">
              <p className="text-16 font-semibold text-white">{t('sendCoin.totalVolume')}</p>
              <p className="text-20 text-#ffd300">
                <span className="text-24 font-semibold">
                  {numberUI(map.reduce((acc, cur) => acc + Number(cur.amount), 0))}
                </span>
                &nbsp;CRETA
              </p>
            </div>

            {needMoreVolume && (
              <div className="mt-1.5 text-end text-15 text-#ff2a55">
                <div className="flex items-center justify-end gap-0.5">
                  <InfoIcon className="size-5 shrink-0" />
                  <Trans i18nKey="sendCoin.priceCheck.invalidate.message" values={{ volume: numberUI(needMoreVolume) }}>
                    <span className="font-medium" />
                  </Trans>
                </div>
              </div>
            )}

            <div className="mt-3 flex justify-center gap-2 rounded-2 bg-[#fff9def2] p-3 text-[#b46f00]">
              <WarningColorIcon className="shrink-0" />
              <p className="text-14 font-light">
                <Trans i18nKey="chat.sendCoin.userConfirm.description">
                  <span className="font-medium" />
                </Trans>
              </p>
            </div>
          </div>

          <div className="mt-6 px-5">
            <p className="text-16 font-semibold text-black-title">{t('sendCoin.confirmModal.note.title')}</p>
            <div
              className={classNames('relative mt-3 rounded-2 border px-4 py-[14px] pb-[34px]', {
                'border-black-body': noteFocus,
                'border-gray-300': !noteFocus
              })}
            >
              <textarea
                className="gray-scroll-6 h-[60px] w-full overflow-auto text-16 text-black-body"
                value={note}
                onFocus={() => setNoteFocus(true)}
                onBlur={() => setNoteFocus(false)}
                maxLength={100}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="absolute bottom-[14px] right-4 text-14 text-gray-600">
                <span className="font-medium text-primary-100">{Math.min(100, note.length)}</span>/100
              </div>
            </div>
          </div>
        </div>

        <div className="p-5">
          <Button
            color="primary"
            center
            className="w-full"
            disabled={!!needMoreVolume || buttonBusy()}
            size="lg"
            onClick={() => {
              if (isCreta) {
                onSubmitCreta();
              } else {
                onSubmitMetamask();
              }
            }}
          >
            {buttonBusy() && t('setting.wallet.send.button.transfer')}
            {!buttonBusy() && t('setting.wallet.send.button.submit')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default SendConfirmModal;
