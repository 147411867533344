const queryKeys = {
  vote: {
    getItem: (id: number | string) => ['vote', `${id}`]
  },
  report: {
    choices: 'report-choices'
  },

  user: {
    me: 'user-me',
    getItem: (id: number | string) => ['user', 'item', `${id}`],
    getMyClubIdList: () => ['user-my-club-id-list']
  },
  video: {
    all: ['video'],
    getVideoChannelList: (params?: any) => [...queryKeys.video.all, 'channel-list', { ...params }]
  }
};

export default queryKeys;
