import { atom } from 'recoil';

export const defaultWalletState = {
  tokens: [] as any[],
  nfts: [] as any[]
};

export const metamaskWalletState = atom({
  key: 'metamaskWalletState',
  default: defaultWalletState
});

export const cretaWalletState = atom({
  key: 'cretaWalletState',
  default: defaultWalletState
});

/**
 * 다중코인전송 state 관리
 */
export const multiCoinSendMapState = atom<{ id: number; amount: string; address: string }[]>({
  key: 'multiCoinSendTotalState',
  default: []
});
