import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { Member } from '@sendbird/chat/groupChannel';
import { isAxiosError } from 'axios';
import debounce from 'lodash/debounce';

import useCurrentUser from '../../../hooks/useCurrentUser';

import ChannelListItem, { FilterType } from './ChannelListItem';

import chatApi from 'apis/chat-api';
import { ReactComponent as CreateChannelIcon } from 'assets/icons/ic-create-channel.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Input from 'components/jayden/Input';
import Tooltip from 'components/jayden/Tooltip';
import classNames from 'components/styled/util';
import {
  useGetClubChannelsJoinable,
  useGetGroupChannelsAllJoined,
  useGetGroupChannelsJoinable
} from 'hooks/new/use-get-channels';

import useAlert from 'hooks/useAlert';

import { getChannelIdByChannelUrl } from 'utils/common';

enum OrderType {
  NEW,
  BEST
}

const ChannelList: React.FC = () => {
  const qc = useQueryClient();
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const { channelUrl } = useParams<{ channelUrl?: string; clubId?: string; dmUserId?: string }>();

  const navigate = useNavigate();
  const { show, close } = useAlert();
  const [filterType, setFilterType] = useState(FilterType.ON_CHAT);
  const [orderType, setOrderType] = useState<OrderType | null>(OrderType.NEW);
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);

  const [keyword, setKeyword] = useState('');

  const onChangeKeyword = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  }, 300);

  const { data: clubChats = [] } = useGetClubChannelsJoinable({
    search: keyword
  });
  const { data: allChats = [] } = useGetGroupChannelsJoinable({
    search: keyword
  });
  const { data: onChats = [], isLoading: isOnChatLoading } = useGetGroupChannelsAllJoined({
    search: keyword
  });

  const onChange = useCallback(
    (channel: any) => async () => {
      const channelUrl = channel.channelUrl;
      const joined = channel.members.find((member: Member) => member.userId === currentUser?.userId);

      try {
        if (!joined) {
          await chatApi.putGroupChannelChannelIdJoin({ channelId: getChannelIdByChannelUrl(channelUrl) });
        }
        navigate(`/channel/${channelUrl}`);
        qc.invalidateQueries(['channels']);
        setFilterType(FilterType.ON_CHAT);
      } catch (e) {
        let content = t('chat.alert.joinError.contents');

        if (isAxiosError(e)) {
          const message = e.response?.data?.message;
          switch (message) {
            case 'This is a private channel':
              content = t('chat.menu.alert.privateChannel');
              break;
            case 'The channel has reached its member limit':
              content = t('chat.menu.alert.overflow');
              break;
            case 'You are not a member of this club':
              content = t('chat.menu.alert.notClubMember');
              break;
          }
        }

        show({
          content,
          buttons: [
            {
              title: t('common.button.confirm'),
              onClick() {
                close();
              }
            }
          ]
        });
      }
    },
    [navigate, currentUser]
  );

  const onChangeOrderType = useCallback(
    (newOrderType: OrderType) => () => {
      setOrderType(newOrderType);
    },
    []
  );

  const onTabFilter = useCallback(
    (value: FilterType) => async () => {
      setFilterType(value);

      if (value === FilterType.ON_CHAT) {
        setOrderType(null);
      } else {
        if (orderType === null) {
          setOrderType(OrderType.BEST);
        }
      }

      if (value === FilterType.ALL) {
        // await fetchChannels();
      }
    },
    [orderType]
  );

  const tabs = [
    {
      key: FilterType.ON_CHAT,
      label: t('chat.menu.tabs.onChat'),
      count: onChats.length || 0
    },
    {
      key: FilterType.CLUBS,
      label: t('chat.menu.tabs.clubs'),
      count: clubChats.length || 0
    },
    {
      key: FilterType.ALL,
      label: t('chat.menu.tabs.all'),
      count: allChats.length || 0
    }
  ];

  const listWrapperHeight = useMemo(() => {
    const headerHight = isShowSearchInput ? 133 : 77;
    const filterHight = filterType === FilterType.ON_CHAT ? 0 : 41;
    const paddingTop = 14;
    return `calc(100vh - ${headerHight + filterHight}px - ${paddingTop}px)`;
  }, [isShowSearchInput, filterType]);

  const tabRefs = useRef<HTMLButtonElement[]>([]);

  useEffect(() => {
    if (channelUrl || isOnChatLoading) return;

    if (onChats.length > 0) {
      navigate(`/channel/${onChats[0].channelUrl}`);
    }

    if (onChats.length === 0) {
      setFilterType(FilterType.ALL);
    }
  }, [channelUrl, onChats, isOnChatLoading]);

  return (
    <div className="h-screen w-[300px] pt-3.5">
      {/* CHANNEL HEAD */}
      <div className="flex flex-col">
        <div className="mx-5 mb-3 flex items-center justify-between">
          <span className="text-20 font-semibold text-black-title">{t('chat.menu.header.title')}</span>
          <div className="flex gap-2">
            <div className="group relative">
              <button
                type="button"
                className="flex size-8 items-center justify-center rounded-2 transition-all hover:bg-gray-200"
                onClick={() => setIsShowSearchInput(!isShowSearchInput)}
              >
                <SearchIcon />
              </button>
              <Tooltip position="bottom">
                <span className="whitespace-nowrap">{t('chat.header.searchChannel.tooltip')}</span>
              </Tooltip>
            </div>
            <Link to={'create'} className="group relative">
              <button
                type="button"
                className="flex size-8 items-center justify-center rounded-2 transition-all hover:bg-gray-200"
              >
                <CreateChannelIcon />
              </button>
              <Tooltip position="bottom">
                <span className="whitespace-nowrap">{t('chat.header.createChannel.tooltip')}</span>
              </Tooltip>
            </Link>
          </div>
        </div>

        {/* CHANNEL SEARCH */}
        <div
          className={classNames('mx-5 h-14 pb-3 transition-all', {
            'overflow-hidden h-0 pb-0': !isShowSearchInput
          })}
        >
          <div className="w-[260px]">
            <div className="relative">
              <Input
                className="peer transition-all"
                type="text"
                placeholder={t('chat.menu.search.placeholder')}
                onChange={onChangeKeyword}
                suffix={
                  <SearchIcon className="absolute right-3 top-1/2 inline-block size-6 -translate-y-1/2 cursor-pointer text-gray-500 transition-all peer-focus:text-black-body" />
                }
              />
            </div>
          </div>
        </div>

        {/* CHANNEL TABS */}
        <div className="relative mx-5 mt-0 border-b border-gray-400">
          <div className="flex items-center gap-7">
            {tabs.map((tab, i) => (
              <button
                key={tab.key}
                ref={(el) => {
                  if (el) tabRefs.current[i] = el;
                }}
                type="button"
                className={classNames(
                  'group px-0.5 pb-2 text-14 text-gray-600 disabled:cursor-not-allowed disabled:opacity-60',
                  {
                    'font-semibold text-primary-100': filterType === tab.key
                  }
                )}
                onClick={onTabFilter(tab.key)}
                disabled={tab.count === 0}
              >
                <div className="flex items-center gap-0.5">
                  <span>{tab.label}</span>
                  <span
                    className={classNames('flex h-4 items-center rounded-2 px-1 text-11 font-medium transition-all', {
                      'text-white bg-primary-100': filterType === tab.key,
                      'text-gray-600 bg-gray-100 group-hover:text-gray-800': filterType !== tab.key
                    })}
                  >
                    {tab.count}
                  </span>
                </div>
              </button>
            ))}
          </div>

          {/* tab bar */}
          <div
            className="absolute bottom-0 h-0.5 bg-primary-100 transition-all"
            style={{
              width: tabRefs.current?.[filterType]?.offsetWidth || 0,
              left: tabRefs?.current?.[filterType]?.offsetLeft || 0
            }}
          />
        </div>
      </div>

      {/* CHANNEL BEST, NEW */}
      {/* 현재 참여중인 클럽, 북마크 된 채널을 제외한 리스트 정렬순서 : 1. 참여인원 많은 순 -> 2. 동순위 시 최근 개설일시 -> 3. 동순위 시 abc 순 */}
      {filterType !== FilterType.ON_CHAT && (
        <div className="inline-flex h-[41px] w-[300px] items-center justify-end gap-5 pr-5">
          <button type="button" onClick={onChangeOrderType(OrderType.BEST)} className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/ico/ic_check_bk.png`}
              className={classNames('size-4', {
                invisible: orderType !== OrderType.BEST,
                visible: orderType === OrderType.BEST
              })}
            />

            <span
              className={classNames('text-13 leading-[20px]', {
                'font-medium text-black-title': orderType === OrderType.BEST,
                'text-gray-600': orderType !== OrderType.BEST
              })}
            >
              {t('chat.menu.tabs.best')}
            </span>
          </button>

          <button type="button" onClick={onChangeOrderType(OrderType.NEW)} className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/img/ico/ic_check_bk.png`}
              className={classNames('size-4', {
                invisible: orderType !== OrderType.NEW,
                visible: orderType === OrderType.NEW
              })}
            />
            <span
              className={classNames('text-13 leading-[20px]', {
                'font-medium text-black-title': orderType === OrderType.NEW,
                'text-gray-600': orderType !== OrderType.NEW
              })}
            >
              {t('chat.menu.tabs.new')}
            </span>
          </button>

          {/* 현재 참여중인 클럽, 북마크 된 채널을 제외한 리스트 정렬순서 : 1. 최근 개설일시 -> 2. 동순위 시 참여인원 많은 순 -> 3. 동순위 시 abc 순  */}
        </div>
      )}

      <div className="overflow-y-auto p-0 transition-all" style={{ height: listWrapperHeight }}>
        {filterType === FilterType.ON_CHAT &&
          onChats.map((channel: any) => {
            const isNow = channelUrl === channel.channelUrl;
            const key = `${channel.channelUrl}-${channel.customType}`;

            return (
              <ChannelListItem
                key={key}
                channel={channel}
                isNow={isNow}
                currentUser={currentUser as User}
                onChange={onChange(channel)}
              />
            );
          })}

        {filterType === FilterType.CLUBS &&
          clubChats.map((channel: any) => {
            const isNow = channelUrl === channel.channelUrl;
            const key = `${FilterType.CLUBS}-${channel.channelUrl}`;
            return (
              <ChannelListItem
                key={key}
                channel={channel}
                isNow={isNow}
                currentUser={currentUser as User}
                onChange={onChange(channel)}
              />
            );
          })}

        {filterType === FilterType.ALL &&
          allChats.map((channel: any) => {
            const isNow = channelUrl === channel.channelUrl;
            const key = `${FilterType.ALL}-${channel.channelUrl}`;
            return (
              <ChannelListItem
                channel={channel}
                key={key}
                isNow={isNow}
                currentUser={currentUser as User}
                onChange={onChange(channel)}
              />
            );
          })}
      </div>

      {/* <div className="overflow-y-auto p-0 transition-all" style={{ height: listWrapperHeight }}>
        {bookMarkedChannel.map((channel) => {
          const isNow = currentChannel?.channelUrl === channel.url;
          const key = channel.url;
          return (
            <ChannelListItem
              key={key}
              isNow={isNow}
              currentUser={currentUser as User}
              channelUrl={channel.url}
              onChange={onChange(channel)}
              onToggleBookMark={onToggleBookMark(channel)}
            />
          );
        })}


        {unbookMarkedChannel.map((channel) => {
          const isNow = currentChannel?.channelUrl === channel.url;
          const key = channel.url;
          return (
            <ChannelListItem
              key={key}
              isNow={isNow}
              currentUser={currentUser as User}
              channelUrl={channel.url}
              onChange={onChange(channel)}
              onToggleBookMark={onToggleBookMark(channel)}
            />
          );
        })}
      </div> */}
    </div>
  );
};
export default ChannelList;
