import { atom } from 'recoil';

export type MessageMoreState = {
  popup: boolean;
  messageId: number | null;
};

const messageMoreState = atom<MessageMoreState>({
  key: 'messageMoreState',
  default: {
    popup: false,
    messageId: null
  }
});

export default messageMoreState;
