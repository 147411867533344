import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import chatApi, { PostGroupChannelPayload, PutGroupChannelInfoPayload } from 'apis/chat-api';

const useNewGroupChannel = () => {
  const qc = useQueryClient();

  const createChannel = useCallback(async (payload: PostGroupChannelPayload) => {
    try {
      const response = await chatApi.postGroupChannel(payload);

      return response;
    } catch (error) {
      console.error('useNewGroupChannel > createChannel', error);
    }
  }, []);

  const updateChannel = useCallback(async (payload: PutGroupChannelInfoPayload) => {
    try {
      const response = await chatApi.putGroupChannelInfo(payload);
      await qc.invalidateQueries({
        queryKey: ['channels', response.data.channelUrl]
      });
      return response;
    } catch (error) {
      console.error('useNewGroupChannel > updateChannel', error);
    }
  }, []);

  const deleteChannel = useCallback(async (channelId: string) => {
    try {
      const response = await chatApi.deleteGroupChannel({ channelId });

      return response;
    } catch (error) {
      console.error('useNewGroupChannel > deleteChannel', error);
    }
  }, []);

  const addBookmark = useCallback(async (channelId: string) => {
    const response = await chatApi.postGroupChannelsChannelIdBookmark({ channelId });
    await qc.invalidateQueries({
      queryKey: ['channels']
    });
    return response;
  }, []);

  const removeBookmark = useCallback(async (channelId: string) => {
    const response = await chatApi.putGroupChannelsChannelIdBookmark({ channelId });
    await qc.invalidateQueries({
      queryKey: ['channels']
    });
    return response;
  }, []);

  return {
    createChannel,
    updateChannel,
    deleteChannel,
    addBookmark,
    removeBookmark
  };
};

export default useNewGroupChannel;
