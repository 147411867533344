import React, { useCallback, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import useConnectionHandler from '../hooks/useConnectionHandler';
import useGroupChannelHandler from '../hooks/useGroupChannelHandler';
import getSendBird from '../libs/sendbird';

import chatApi from 'apis/chat-api';
import sendbirdApi from 'apis/sendbird-api';
import ChatDiv from 'layout/ChatDiv';

import useUserMe from 'hooks/use-user-me';
import useCurrentUser from 'hooks/useCurrentUser';

const Index = () => {
  const user = useUserMe();
  const { setCurrentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { channelUrl, dmUserId } = useParams<{ channelUrl?: string; dmUserId?: string }>();

  const initSendBird = useCallback(async () => {
    if (!user?.id) return;
    try {
      const { access_token } = await sendbirdApi.getUser({ userId: user.id.toString() });
      const sbUser = await getSendBird().connect(user.id.toString(), access_token);

      setCurrentUser(sbUser);

      // 샌드버드 정보를 초기화
      await getSendBird().setChannelInvitationPreference(false);
      await getSendBird().clearCachedData();

      if (pathname === '/') {
        navigate('/channel');
        return;
      }

      if (dmUserId) {
        const { data: dmChannel } = await chatApi.postDmChannels({ toUserId: Number(dmUserId) });

        if (dmChannel) {
          navigate(`/channel/${dmChannel.channelUrl}`);
          return;
        }
      }

      if (channelUrl) {
        navigate(`/channel/${channelUrl}`);
      }
    } catch (error) {
      console.error('initSendBird', error);
    }
  }, [user?.id]);

  useEffect(() => {
    initSendBird();
  }, [initSendBird]);

  useGroupChannelHandler();
  useConnectionHandler();

  return (
    <ChatDiv>
      <Outlet />
    </ChatDiv>
  );
};

export default Index;
