export enum ChannelType {
  CLUB = 'CLUB',
  DM = 'DM',
  GROUP = 'GROUP'
}

export interface InviteMessageDto {
  id: string;
  userId: number;
  content: string;
  createdAt: Date;
  lastSentAt: Date;
}

export interface InviteTokenDto {
  id: string;
  channelId: string;
  token: string;
  expiresAt: Date;
}

export interface OpenChannelDto {
  id: string;
  userId?: number;
  name: string;
  customType?: string;
  channelUrl: string;
  coverUrl?: string;
  data?: string;
  isDynamicPartitioned: boolean;
  isEphemeral: boolean;
  isFrozen: boolean;
  maxLengthMessage?: number;
  participantCount?: number;
  operators: any[]; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  freeze: boolean;
  createdAt: Date;
}

// 임시
export interface MemberDto {
  role: string;
  state: string;
  userId: string;
  isMuted: boolean;
  isActive: boolean;
  isOnline: boolean;
  isBlocked: boolean;
  metadata: any;
  nickname: string;
  lastSeenAt: number;
  mutedEndAt: number;
  profileUrl: string;
  mutedDescription: string;
  requireAuthForProfileImage: boolean;
}

export interface GroupChannelDto {
  id: string;
  clubId?: number;
  userId?: number;
  name: string;
  customType: ChannelType;
  channelUrl: string;
  coverUrl?: string;
  data?: string;
  lastMessage?: any; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  disappearingMessage?: any; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  freeze: boolean;
  hiddenState?: string;
  inviterId?: string;
  isAccessCodeRequired: boolean;
  isBroadcast: boolean;
  isCreated: boolean;
  isDiscoverable: boolean;
  isDistinct: boolean;
  isEphemeral: boolean;
  isFrozen: boolean;
  isHidden: boolean;
  isPublic: boolean;
  isPushEnabled: boolean;
  isSuper: boolean;
  joinedMemberCount?: number;
  maxLengthMessage?: number;
  memberCount?: number;
  members: MemberDto[]; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  messageOffsetTimestamp?: Date;
  messageSurvivalSeconds?: number;
  operators: any[]; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  smsFallback?: any; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  unreadMentionCount?: number;
  unreadMessageCount?: number;
  introduction?: string;
  memberIds: string[];
  masterIds: string[];
  staffIds: string[];
  participantsLimit?: number;
  noticeMessageIds: string[];
  transferringStaffIds: string[];
  forbiddenWords: string[];
  bookmarkUserIds: string[];
  sendMessageDisabledUserIds: string[];
  viewChannelDisabledUserIds: string[];
  viewChannelHistories: any[]; // 서버에 Json[] 로 정의 되어있는 필드로 나중에 수정 필요
  onlyClubMembers: boolean;
  createdAt: Date;
  updatedAt?: Date;
}

export interface GlobalBadWordDto {
  id: string;
  word: string;
}

export interface BadWordDto {
  id: string;
  word: string;
  groupChannelId?: string;
}
