import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useRecoilState } from 'recoil';

import { CloseIcon } from 'assets/iicon';
import Input from 'components/jayden/Input';
import classNames from 'components/styled/util';
import MaskingAddressBadge from 'components/wallet/components/masking-address-badge';
import { multiCoinSendMapState } from 'components/wallet/recoil';
import { numberUI } from 'components/wallet/util';

interface SelectedUserInputProps {
  v: any;
  onClickRemove: (v: any) => void;
}

const SelectedUserInput = (props: SelectedUserInputProps) => {
  const { v, onClickRemove } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const [map, setMap] = useRecoilState(multiCoinSendMapState);

  useEffect(() => {
    const foundItem = map.find((obj) => obj.id === v.id);
    if (foundItem) {
      setValue(foundItem.amount);
    }
  }, [map]);

  return (
    <div className="mb-1.5 rounded-2 bg-gray-100 p-3">
      <div className={classNames('flex items-center justify-between')}>
        <div className="flex items-center gap-2">
          <img src={v.profile_image_url} className="size-10 rounded-full" />
          <div>
            <p className="text-13 text-secondary-100">{v.grade}</p>
            <p className="text-16 font-medium text-black-title">
              {v.username}
              <span className="font-normal text-gray-600">#{v.hash}</span>
            </p>
          </div>
        </div>

        <MaskingAddressBadge address={v.wallet_address} />
      </div>

      <div className="mt-3 flex gap-2">
        <Input
          ref={ref}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (value !== '') setValue(numberUI(value));
            setTimeout(() => setIsFocused(false), 200);
          }}
          placeholder={t('wallet.sendCoin.input.placeholder')}
          className="h-10 grow bg-white text-right text-16 font-semibold text-black-body"
          value={value}
          onChange={(e) => {
            const { value } = e.target;
            if (/^\d*\.?\d{0,8}$/.test(value)) {
              setValue(value);
              setMap((prev) => {
                const foundItem = prev.find((obj) => obj.id === v.id);
                if (foundItem) {
                  return prev.map((item) => {
                    if (item.id === v.id) return { ...item, amount: value };
                    return item;
                  });
                } else {
                  return [...prev, { id: v.id, address: v.wallet_address, amount: value }];
                }
              });
            }
          }}
          suffix={
            <>
              {isFocused && value.trim() !== '' && (
                <CloseIcon
                  onClick={() => {
                    setValue('');
                    if (ref.current) {
                      ref.current.focus();
                      setTimeout(() => setIsFocused(true), 200);
                    }
                  }}
                  className="absolute right-4 top-1/2 size-5 -translate-y-1/2 cursor-pointer text-gray-600"
                />
              )}
            </>
          }
        />
        <button
          data-tooltip-id={`user-delete-${v.wallet_address}`}
          data-tooltip-content={t('wallet.sendCoin.unselect.tooltip')}
          className="size-10 shrink-0 rounded-1.5 bg-gray-300 p-2 text-14"
          onClick={() => onClickRemove(v)}
        >
          <CloseIcon className="size-6 text-gray-600" />

          <Tooltip id={`user-delete-${v.wallet_address}`} place={'bottom'} />
        </button>
      </div>
    </div>
  );
};

export default SelectedUserInput;
