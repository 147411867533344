import React, { ButtonHTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';

import { ReactComponent as MinusIcon } from '../../../assets/icons/ic-minus-sm.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/ic-plus-sm.svg';
import mixin from '../../../components/styled/mixin';
import { StyledButton, StyledText } from '../../../components/styled/styled';

interface MoreOptionButtonProps extends ButtonHTMLAttributes<any> {
  isCollapsed: boolean;
  onClick: () => void;
  moreView?: boolean;
}

const MoreOptionButton: React.FC<MoreOptionButtonProps> = (props) => {
  const { isCollapsed, onClick, moreView = false, className } = props;
  const { t } = useTranslation();

  const buttonText = useMemo(() => {
    if (moreView) {
      if (isCollapsed) {
        return t('chat.miniProfile.button.fewerView');
      }
      return t('chat.miniProfile.button.moreView');
    }
    if (isCollapsed) {
      return t('chat.miniProfile.button.fewerOption');
    }
    return t('chat.miniProfile.button.moreOption');
  }, [isCollapsed, moreView, t]);

  return (
    <StyledMoreOptionButton onClick={onClick} className={className}>
      {isCollapsed ? <StyledMinusIcon /> : <StyledPlusIcon />}
      <StyledButtonLabel>{buttonText}</StyledButtonLabel>
    </StyledMoreOptionButton>
  );
};

export default MoreOptionButton;

const StyledButtonLabel = styled(StyledText)`
  ${mixin.transitionMixin}
  color: ${({ theme }) => theme.colors.gray500};
`;

const iconMixin = css`
  ${mixin.transitionMixin}
  width: 24px;
  height: 24px;
  ${({ theme }) => `fill: ${theme.colors.gray500}`};
`;
const StyledMinusIcon = styled(MinusIcon)`
  ${iconMixin}
`;
const StyledPlusIcon = styled(PlusIcon)`
  ${iconMixin}
`;

const StyledMoreOptionButton = styled(StyledButton)<ButtonHTMLAttributes<any>>`
  margin-top: 12px;
  height: 40px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.gray100};
  ${mixin.transitionMixin}

  :hover {
    background-color: ${({ theme }) => theme.colors.gray200};
    ${StyledButtonLabel} {
      color: ${({ theme }) => theme.colors.black200};
    }
    ${StyledPlusIcon}, ${StyledMinusIcon} {
      fill: ${({ theme }) => theme.colors.black200};
    }
  }
  :active {
    background-color: ${({ theme }) => theme.colors.gray300};
  }
`;
