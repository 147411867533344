import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ChatMessageAttachmentGallerySearch from './components/ChatMessageAttachmentGallerySearch';
import ChatMessageAttachmentUpload from './components/ChatMessageAttachmentUpload';
import ChatMessageAttachmentVideo from './components/ChatMessageAttachmentVideo';

import ModalContainer from 'components/layout/ModalContainer';
import classNames from 'components/styled/util';

enum Tab {
  UPLOAD,
  VIDEO,
  GALLERY,
  URL
}

const ChannelAttach = () => {
  const [tab, setTab] = useState<Tab>(Tab.UPLOAD);
  const [tabWidth, setTabWidth] = useState(0);
  const [tabLeft, setTabLeft] = useState(0);

  const { t } = useTranslation();

  const onChangeTab = useCallback(
    (selectedTab: Tab) => () => {
      setTab(selectedTab);
    },
    []
  );

  const tabRefs = useRef<HTMLButtonElement[]>([]);

  const tabs = [
    {
      key: Tab.UPLOAD,
      label: t('chat.attachPopup.tabs.upload')
    },
    {
      key: Tab.VIDEO,
      label: t('chat.attachPopup.tabs.video')
    },
    {
      key: Tab.GALLERY,
      label: t('chat.attachPopup.tabs.gallery')
    }
  ];

  useEffect(() => {
    const updateTabDimensions = () => {
      const currentTab = tabRefs.current[tab];
      if (currentTab) {
        setTabWidth(currentTab.offsetWidth);
        setTabLeft(currentTab.offsetLeft);
      }
    };

    updateTabDimensions();
  }, [tab]);

  return (
    <ModalContainer width="w-[322px] h-fit min-h-[480px]" height="auto">
      <div className="title">{t('chat.attachPopup.title')}</div>
      <div className="relative mx-6 mt-0 border-b border-gray-400">
        <div className="flex items-center gap-7">
          {tabs.map((item, index) => (
            <button
              key={item.key}
              ref={(el) => {
                if (el) tabRefs.current[index] = el;
              }}
              type="button"
              className={classNames('group px-0.5 pb-2 text-14 text-gray-600', {
                'font-semibold text-primary-100': tab === item.key
              })}
              onClick={onChangeTab(item.key)}
            >
              <div className="flex items-center gap-0.5">
                <span>{item.label}</span>
              </div>
            </button>
          ))}
          {/* tab bar */}
          <div
            className="absolute bottom-0 h-0.5 bg-primary-100 transition-all"
            style={{
              width: tabWidth,
              left: tabLeft
            }}
          />
        </div>
      </div>

      <div className="h-[378px] px-6 pb-5">
        {tab === Tab.UPLOAD && <ChatMessageAttachmentUpload />}
        {tab === Tab.VIDEO && <ChatMessageAttachmentVideo />}
        {tab === Tab.GALLERY && <ChatMessageAttachmentGallerySearch />}
      </div>
    </ModalContainer>
  );
};

export default ChannelAttach;
