import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilValue } from 'recoil';

import ModalBackdrop from './ModalBackdrop';
import useAlert from './useAlert';

import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';
import { alertState } from 'recoil/alert';

const Alert = () => {
  const a = useRecoilValue(alertState);
  const {
    isOpen,
    title,
    contents,
    submit,
    cancel,
    submitText,
    cancelText,
    submitOnly,
    disableBackdropClick,
    backdropBlur
  } = a;
  const { t } = useTranslation();

  const { close } = useAlert();

  const onSubmit = useCallback(() => {
    submit?.();
    close();
  }, [submit]);

  const onCancel = useCallback(() => {
    cancel?.();
    close();
  }, [cancel]);

  useEffect(() => {
    const onPopupKeyup = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    if (isOpen) {
      document.addEventListener('keyup', onPopupKeyup);
    }
    return () => {
      document.removeEventListener('keyup', onPopupKeyup);
    };
  }, [isOpen]);

  if (!isOpen) return <></>;

  return (
    <ModalBackdrop close={close} disableBackdropClick={disableBackdropClick} backdropBlur={backdropBlur}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className="relative flex w-[320px] flex-col gap-5 rounded-5 bg-white p-5 pt-[22px] shadow-[0_4px_10px_0_rgba(0,0,0,0.15)]">
          <AlertContent title={title} contents={contents} />
          <div
            className={classNames('w-full p-0 text-center', {
              'flex justify-between items-center [&>button]:w-[calc(50%-4px)]': !submitOnly
            })}
          >
            {!submitOnly && (
              <Button color="default" size="lg" className="flex w-full items-center justify-center" onClick={onCancel}>
                {cancelText || t('common.button.cancel')}
              </Button>
            )}

            <Button color="primary" size="lg" className="flex w-full items-center justify-center" onClick={onSubmit}>
              {submitText || t('common.button.confirm')}
            </Button>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};

const AlertContent = ({ title, contents }: { title?: string; contents: React.ReactNode }) => {
  if (title) {
    return (
      <>
        <div className="break-all px-1 py-0 text-center text-22 font-semibold text-black-title">{title}</div>
        <div className="min-h-[47px] px-1 pb-2 pt-0">
          {React.isValidElement(contents) && contents}
          {!React.isValidElement(contents) && (
            <div className="whitespace-pre-wrap break-all text-center text-16 font-semibold leading-normal text-black-body">
              {contents}
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="min-h-[47px] p-2 pt-3.5">
      {React.isValidElement(contents) && contents}
      {!React.isValidElement(contents) && (
        <div className="whitespace-pre-wrap break-all text-center text-16 font-semibold leading-normal text-black-body">
          {contents}
        </div>
      )}
    </div>
  );
};

export default Alert;
