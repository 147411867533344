import React from 'react';
import { useMutation } from 'react-query';

import { capitalize } from 'lodash';

import useAlert from '../../../../../hooks/useAlert';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useGroupChannel from '../../../../../hooks/useGroupChannel';
import api from '../../../../../libs/apis';
import { StatusCode } from '../../../../../libs/apis/response.type';
import { updateGroupChannelMessage } from '../../../../../libs/sendbird';
import { ChatMessage, CustomMessageType } from '../../../../../types/common';
import ChatCustomMessage from '../ChatCustomMessage';

export enum TransferRequestStatus {
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  REFUSED = 'REFUSED',
  ACCEPTED = 'ACCEPTED'
}

export interface PlatformTransferMessageDataInterface {
  agendaId?: number;
  clubId?: number;
  forumId?: number;
  imageUrl?: string;
  name?: string;
  type?: CustomMessageType;
  requestStatus?: TransferRequestStatus;
}

type Props = {
  transferData: PlatformTransferMessageDataInterface;
  message: ChatMessage;
};

const ChatClubTransferMessage: React.FC<Props> = ({ transferData, message }) => {
  const { updateMessage, sendMessageToCurrentChannel } = useGroupChannel();
  const { currentUser } = useCurrentUser();
  const { showConfirm } = useAlert();

  const isMine = currentUser?.userId === message.sender.userId;

  const updateMessageRequestStatus = (requestStatus: TransferRequestStatus) => {
    if (message.data != null) {
      const data = JSON.stringify({
        ...JSON.parse(message.data),
        requestStatus
      });

      // 플랫폼 api 호출해서 직접 메세지 수정
      updateGroupChannelMessage(message.channelUrl, message.messageId, {
        data
      }).then(() => {
        // 성공했을 때 메세지 수정
        updateMessage(message.messageId, { data });
      });
    }
  };

  const agendaApprovalMutation = useMutation({
    mutationFn: api.postClubAgendaApproval,
    onSuccess: (data) => {
      switch (data.code) {
        case StatusCode.FINISHED: {
          updateMessageRequestStatus(TransferRequestStatus.COMPLETE);
          showConfirm('Another user has accepted the master request.');
          return;
        }

        case StatusCode.CANCELED: {
          updateMessageRequestStatus(TransferRequestStatus.CANCELED);
          showConfirm('The offer has been cancelled.');
          return;
        }
      }

      sendMessageToCurrentChannel({
        message: `The master of club ${transferData.name}! has been changed from ${message.sender.nickname} to ${currentUser?.nickname}. \n
Thank you for your support so far. \n
I hope that you will also give a lot of support to ${currentUser?.nickname} who is the new master.`,
        customType: CustomMessageType.DEFAULT,
        data: JSON.stringify({
          name: transferData.name,
          imageUrl: transferData.imageUrl
        })
      });

      updateMessageRequestStatus(TransferRequestStatus.ACCEPTED);
      showConfirm(`You have become the master of club ${transferData.name}`);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const agendaDisapprovalMutation = useMutation({
    mutationFn: api.postClubAgendaDisapproval,
    onSuccess: (data) => {
      switch (data.code) {
        case StatusCode.FINISHED: {
          updateMessageRequestStatus(TransferRequestStatus.REFUSED);
          showConfirm('Another user has accepted the master request.');
          return;
        }

        case StatusCode.CANCELED: {
          updateMessageRequestStatus(TransferRequestStatus.REFUSED);
          showConfirm('The offer has been cancelled.');
          return;
        }
      }

      sendMessageToCurrentChannel({
        message: `The request to transfer master authority for ${transferData.name}! has been refused.`,
        customType: CustomMessageType.DEFAULT,
        data: JSON.stringify({
          name: transferData.name,
          imageUrl: transferData.imageUrl
        })
      });
      updateMessageRequestStatus(TransferRequestStatus.REFUSED);
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handleClickRefuse = () => {
    if (transferData.agendaId != null) {
      agendaDisapprovalMutation.mutate(transferData.agendaId);
    }
  };

  const handleClickAccept = () => {
    if (transferData.agendaId != null) {
      agendaApprovalMutation.mutate(transferData.agendaId);
    }
  };

  return (
    <ChatCustomMessage
      name={transferData.name ?? ''}
      imageUrl={transferData.imageUrl}
      content={message.message}
      buttonGroup={
        !isMine && (
          <div className="accept">
            {transferData.requestStatus == null && (
              <>
                <button
                  type="button"
                  className="btn default button_sm"
                  onClick={handleClickRefuse}
                  disabled={agendaDisapprovalMutation.isLoading}
                >
                  <span>Refuse</span>
                </button>
                <button
                  type="button"
                  className="btn primary button_sm"
                  onClick={handleClickAccept}
                  disabled={agendaApprovalMutation.isLoading}
                >
                  <span>Accept</span>
                </button>
              </>
            )}

            {transferData.requestStatus != null && (
              <button type="button" className="btn default button_sm" disabled>
                <span>{capitalize(transferData.requestStatus)}</span>
              </button>
            )}
          </div>
        )
      }
    />
  );
};
export default ChatClubTransferMessage;
