export const CRETA_META = {
  icon: 'https://polygonscan.com/token/images/cretaworld_32.png',
  contractAddress: '0x202655af326dE310491Cb54f120E02eE0da92b55',
  symbol: 'CRETA',
  name: 'CRETA TOKEN',
  decimals: 18
};

export const MATIC_META = {
  contractAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  symbol: 'MATIC',
  name: 'MATIC',
  icon: 'https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg',
  decimals: 18
};

export const ERC20_TOKENS = {
  '0x202655af326dE310491Cb54f120E02eE0da92b55': {
    contractAddress: '0x202655af326dE310491Cb54f120E02eE0da92b55',
    symbol: 'CRETA',
    name: 'CRETA TOKEN',
    icon: 'https://polygonscan.com/token/images/cretaworld_32.png',
    decimals: 18
  },
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': {
    symbol: 'USDT',
    name: 'Tether USD',
    decimals: 6,
    icon: 'https://polygonscan.com/token/images/tether_32.png'
  },
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': {
    contractAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    symbol: 'USDC',
    name: 'USD Coin',
    icon: 'https://polygonscan.com/token/images/centre-usdc_32.png',
    decimals: 6
  }
};
