import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { BaseChannel } from '@sendbird/chat';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { BaseMessage, ReactionEvent, UserMessage } from '@sendbird/chat/message';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import useElementSize from '../../../hooks/useElementSize';
import useGroupChannel from '../../../hooks/useGroupChannel';
import { getChatMessage } from '../../../libs/helper';
import getSendBird from '../../../libs/sendbird';
import {
  chatMessageIdsState,
  chatMessageStateByMessageId,
  lastUnreadMessageIdState
} from '../../../store/atoms/chatMessagesState';

import ChatMessageInput from './ChatMessageInput';
import ChatMessageListItem from './ChatMessageListItem';
import ChatRoomHeader from './ChatRoomHeader';
import UnreadMessageIndicator from './UnreadMessageIndicator';

import { GroupChannelDto } from 'apis/types/chat.type';
import classNames from 'components/styled/util';

type Props = {
  channel: GroupChannelDto;
};

const ChatRoom: React.FC<Props> = ({ channel }) => {
  const refPrevChannel = useRef<GroupChannelDto | null>(null);
  const {
    fetchPrevMessages,
    fetchNextMessages,
    markAsRead,
    updateNewMessage,
    updateMessage,
    updateRemovedMessage,
    initMessages
  } = useGroupChannel();

  const [chatRoomRef, { left }] = useElementSize();

  const refChattingArea = useRef<HTMLDivElement | null>(null);
  // 메세지 로딩
  const isLoadingRef = useRef<boolean>(false);
  // 위로 스크롤하는지, 아래로 스크롤하는지
  const prevScrollYRef = useRef<number>(refChattingArea.current?.scrollTop || 0);
  const unreadIndicatorRef = useRef<HTMLDivElement | null>(null);

  // const isChatRoomExpanded = useRecoilValue(chatRoomExpandedState);
  const lastUnreadMessageId = useRecoilValue(lastUnreadMessageIdState);
  const [unreadCount, setUnreadCount] = useState(0);

  const [chatMessageIdsStateValue, _setChatMessageIdsStateValue] = useRecoilState(chatMessageIdsState);

  const scrollToBottom = useCallback(() => {
    refChattingArea.current?.scrollTo?.({
      top: refChattingArea.current.scrollHeight
    });
  }, []);

  const onMessageReceived = useCallback(
    async (receivedChannel: BaseChannel, message: BaseMessage) => {
      let autoScrollToBottom = false;

      if (getScrollPosition() === 0) {
        autoScrollToBottom = true;
      }

      if (receivedChannel.url === channel.channelUrl) {
        await updateNewMessage(receivedChannel.url, message as UserMessage);
      }

      if (receivedChannel.url === channel.channelUrl) {
        await markAsRead(channel.channelUrl);
      }

      if (autoScrollToBottom) {
        if (receivedChannel.url === channel.channelUrl) {
          requestAnimationFrame(() => {
            scrollToBottom();
          });
        }
      }
    },
    [channel]
  );

  const onMessageDeleted = useCallback(async (receivedChannel: BaseChannel, messageId: number) => {
    await updateRemovedMessage(messageId);
  }, []);

  const onMessageUpdated = useCallback(async (receivedChannel: BaseChannel, message: BaseMessage) => {
    await updateMessage(message.messageId, getChatMessage(message as UserMessage));
  }, []);

  const onReactionUpdated = useRecoilCallback(
    ({ snapshot }) =>
      async (channel: BaseChannel, reactionEvent: ReactionEvent) => {
        const messageId = reactionEvent.messageId;
        const userId = reactionEvent.userId;
        const message = snapshot.getLoadable(chatMessageStateByMessageId(messageId)).getValue();

        // ...
        if (reactionEvent.operation === 'add') {
          const reactionUserIds = [...message.reactionUserIds, userId];
          const reactionCount = reactionUserIds.length;
          updateMessage(reactionEvent.messageId, {
            reactionCount,
            reactionUserIds
          });
        } else {
          const reactionUserIds = message.reactionUserIds.filter((reactionUserId) => reactionUserId !== userId);
          const reactionCount = reactionUserIds.length;
          updateMessage(reactionEvent.messageId, {
            reactionCount,
            reactionUserIds
          });
        }
      },
    [updateMessage]
  );

  const getScrollPosition = useCallback(() => {
    if (!refChattingArea.current) {
      return null;
    }

    const scroll =
      refChattingArea.current?.scrollHeight -
      refChattingArea.current?.scrollTop -
      refChattingArea.current?.clientHeight;

    return scroll;
  }, []);

  const onScroll = useRecoilCallback(
    ({ snapshot }) =>
      async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollTop, scrollHeight, offsetHeight } = event.currentTarget;

        if (isLoadingRef.current === true) {
          return;
        }

        if (!channel) return;

        // 스크롤이 위로 올라갈 때
        if (prevScrollYRef.current > scrollTop && scrollTop < 20) {
          const messages = snapshot.getLoadable(chatMessageIdsState).getValue();

          const lastMessageId = messages[0];

          isLoadingRef.current = true;
          const previousScrollHeight = scrollHeight;
          await fetchPrevMessages(channel.channelUrl, lastMessageId);

          const diffScroll = (refChattingArea.current?.scrollHeight || 0) - previousScrollHeight;

          if (diffScroll > 0) {
            requestAnimationFrame(() => {
              refChattingArea.current?.scrollTo({ top: diffScroll });
            });
          }
          isLoadingRef.current = false;
        } else if (prevScrollYRef.current < scrollTop && scrollTop + offsetHeight > scrollHeight - 20) {
          // 스크롤이 아래로 내려갈 때
          const messages = snapshot.getLoadable(chatMessageIdsState).getValue();

          const firstMessageId = messages[messages.length - 1];

          isLoadingRef.current = true;
          // let previousScrollHeight = scrollHeight;
          await fetchNextMessages(channel.channelUrl, firstMessageId);

          // const diffScroll =
          //   (refChattingArea.current?.scrollHeight || 0) - previousScrollHeight;

          isLoadingRef.current = false;
        }

        prevScrollYRef.current = scrollTop;
      },
    []
  );

  const onMessageSent = useCallback(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const handler = new GroupChannelHandler({
      onMessageReceived,
      onMessageUpdated,
      onMessageDeleted,
      onReactionUpdated
    });
    const handlerId = `groupChannelHandler`;

    getSendBird().groupChannel.addGroupChannelHandler(handlerId, handler);

    return () => {
      getSendBird().groupChannel.removeGroupChannelHandler(handlerId);
    };
  }, [onMessageReceived, onReactionUpdated]);

  useEffect(() => {
    async function handler() {
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    }
    window.addEventListener('sentNewMessage', handler);
    return () => {
      window.removeEventListener('sentNewMessage', handler);
    };
  }, []);

  const init = useCallback(async () => {
    if (refPrevChannel.current?.channelUrl === channel.channelUrl) {
      return;
    }

    refPrevChannel.current = channel;
    isLoadingRef.current = true;

    setUnreadCount(channel.unreadMessageCount || 0);

    await initMessages(channel.channelUrl);
    await markAsRead(channel.channelUrl);
    isLoadingRef.current = false;
    requestAnimationFrame(() => {
      scrollToBottom();
      if (unreadIndicatorRef.current) {
        refChattingArea.current?.scrollTo?.({
          top: unreadIndicatorRef.current.offsetTop
        });
      }
    });
  }, [channel]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    // chatting
    <div className={classNames('relative flex h-screen flex-col')} ref={chatRoomRef}>
      <ChatRoomHeader channel={channel} />

      {/* chatting_area */}
      <div ref={refChattingArea} className="relative flex-1 overflow-y-auto bg-gray-100 pt-1" onScroll={onScroll}>
        {/* S : Message Count */}
        {/* 안 읽은메세지 알림 문구,버튼 (Mark as read 클릭시 가장 최근 메세지로 이동) */}
        {/* E : Message Count */}
        {chatMessageIdsStateValue.map((messageId, idx) => {
          return (
            <Fragment key={messageId}>
              {lastUnreadMessageId === messageId && (
                <UnreadMessageIndicator
                  ref={unreadIndicatorRef}
                  unreadCount={unreadCount}
                  handleClickMarkAsRead={scrollToBottom}
                />
              )}
              <ChatMessageListItem
                channel={channel}
                prevMessageId={chatMessageIdsStateValue[idx - 1]}
                messageId={messageId}
                refChattingArea={refChattingArea}
              />
            </Fragment>
          );
        })}
      </div>
      <ChatMessageInput channel={channel} left={left} onSave={onMessageSent} />
    </div>
  );
};

export default ChatRoom;
