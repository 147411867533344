import React from 'react';
import { useTranslation } from 'react-i18next';

import EmojiPicker, { type EmojiClickData } from 'emoji-picker-react';

import useElementSize from '../../../hooks/useElementSize';
import useMessageInput from '../../../hooks/useMessageInput';
import { CustomMessageType } from '../../../types/common';

import { useCurrentChannel } from 'hooks/use-current-channel';

const ChatMessageEmoticonEmojiInput = () => {
  const { closeEmojiPicker, sendMessageToChannel, messageText, setMessageText } = useMessageInput();

  const [containerRef, { width: containerWidth, height: containerHeight }] = useElementSize();

  const { data: currentChannel } = useCurrentChannel();

  const { t } = useTranslation();

  const onSelectEmoji = async (emoji: EmojiClickData) => {
    if (!currentChannel) return;

    if (!messageText) {
      await sendMessageToChannel(currentChannel, {
        message: emoji.emoji,
        customType: CustomMessageType.TEXT
      });
    } else {
      setMessageText(`${messageText}${emoji.emoji}`);
    }

    closeEmojiPicker();
  };

  return (
    <>
      <div className="h-[calc(100%-83px)] [&_.epr-emoji-category-label]:max-h-2.5" ref={containerRef}>
        {containerWidth > 0 && containerHeight > 0 && (
          <EmojiPicker
            onEmojiClick={onSelectEmoji}
            searchDisabled={true}
            height={containerHeight}
            width={containerWidth}
            previewConfig={{
              showPreview: false
            }}
            skinTonesDisabled
            searchPlaceHolder={t('chat.stickerPopup.tabs.emoji')}
          />
        )}
      </div>
    </>
  );
};

export default ChatMessageEmoticonEmojiInput;
