import { atom } from 'recoil';

export type navigatePathState = string;

const navigatePathState = atom<navigatePathState>({
  key: 'navigatePathState',
  default: ''
});

export default navigatePathState;
