import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import { ChatMessage } from '../../../../types/common';

import chatApi from 'apis/chat-api';
import { UserDto } from 'apis/types/user.type';
import Button from 'components/jayden/Button';
import Ballon from 'components/jayden/ballon';

import useAlert from 'hooks/useAlert';
import useCurrentUser from 'hooks/useCurrentUser';

type Props = {
  message: ChatMessage;
};

const ChatTransferMasterMessage: React.FC<Props> = ({ message }) => {
  const { currentUser } = useCurrentUser();

  const parsedData: {
    user?: UserDto;
    toUser?: UserDto;
    channelId?: string;
  } = useMemo(() => {
    if (!message.data) return {};

    try {
      return JSON.parse(message.data);
    } catch (error) {
      return {};
    }
  }, [message]);
  const { t } = useTranslation();
  const { show, close } = useAlert();

  const { data: transferChannel } = useQuery({
    queryKey: ['channel', parsedData?.channelId],
    queryFn: () => chatApi.getGroupChannel({ channelId: parsedData.channelId! }),
    select: (response) => response.data,
    enabled: !!parsedData.channelId
  });

  const putTransferAcceptMutation = useMutation({
    mutationFn: chatApi.putGroupChannelsChannelIdMasterAccept,
    onSuccess: () => {
      show({
        content: t('chat.transferMessage.alert.becomeMaster', {
          channelName: `<span class="channel_name_puple">${transferChannel?.name}.</span>`
        }),
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });
    },
    onError: (error) => {
      show({
        content: error instanceof Error ? error.message : 'Failed to accept transfer',
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });
    }
  });

  // const onAccept = useRecoilCallback(
  //   () => async () => {
  //     if (!transferChannel) {
  //       return;
  //     }

  //     if (!transferMessage) {
  //       return;
  //     }

  //     if (!currentUser) {
  //       return;
  //     }
  //     if (transferChannel.data.transferingStaffIds.indexOf((currentUser as User).userId) === -1) {
  //       show({
  //         content: t('chat.transferMessage.alert.cancelled', {
  //           channelName: transferChannel.name
  //         }),
  //         buttons: [
  //           {
  //             title: t('common.button.confirm'),
  //             onClick: close
  //           }
  //         ]
  //       });
  //       return;
  //     }

  //     await updateChannel(transferChannel.url, {
  //       data: {
  //         masterIds: [(currentUser as User).userId],
  //         staffIds: channel.data.staffIds.filter((staffId) => staffId !== currentUser.userId),
  //         transferingStaffIds: channel.data.transferingStaffIds.filter((staffId) => staffId !== currentUser.userId)
  //       }
  //     });
  //     show({
  //       content: t('chat.transferMessage.alert.becomeMaster', {
  //         channelName: `<span class="channel_name_puple">${transferChannel.name}.</span>`
  //       }),
  //       buttons: [
  //         {
  //           title: t('common.button.confirm'),
  //           onClick: close
  //         }
  //       ]
  //     });
  //   },
  //   [transferMessage, currentUser]
  // );

  const isAccepted = useMemo(() => {
    if (!transferChannel || !currentUser?.userId) return false;

    // 이미 마스터로 등록되어 있는 경우 true
    return transferChannel.masterIds.indexOf(currentUser.userId) > -1;
  }, [transferChannel, currentUser]);

  const isCancelled = useMemo(() => {
    if (!transferChannel || isAccepted || !currentUser?.userId) return false;

    // transferringStaffIds 포함되어 있지 않은 경우 true
    return transferChannel.transferringStaffIds.indexOf(currentUser.userId) === -1;
  }, [transferChannel, isAccepted, currentUser]);

  const isMe = `${parsedData.user?.id}` === currentUser?.userId;

  const handleOnAccept = () => {
    if (!transferChannel || isAccepted || isCancelled || !parsedData.channelId) return;
    putTransferAcceptMutation.mutate({ channelId: parsedData.channelId });
  };

  if (!transferChannel) return null;

  return (
    <>
      {/* 초대하기 DM  */}
      <Ballon isMe={isMe}>
        <span className="block">
          <div className="mb-2 inline-flex h-10 items-center">
            <span>
              <img className="size-10 rounded-2" src={transferChannel.coverUrl} />
            </span>
            <span className="ml-3 text-16 font-semibold">{transferChannel.name || 'Unknown'}</span>
          </div>
          {/* 채널초대문구 : 채널 생성시 작성된 소개 문구가 들어갑니다. */}

          <div className="border-t border-gray-300 px-1 pt-3 text-13">
            {t(
              isAccepted
                ? 'chat.transferMessage.alert.becomeMaster'
                : isCancelled
                  ? 'chat.transferMessage.alert.cancelled'
                  : 'chat.transfer.channel.description',
              {
                channelName: transferChannel.name || 'Unknown'
              }
            )}

            {!isMe && (
              <div className="mt-3 border-t border-gray-300 pt-3 text-end">
                <Button
                  color="primary"
                  className="w-[100px] text-center"
                  size="sm"
                  type="button"
                  onClick={handleOnAccept}
                  disabled={isAccepted || isCancelled}
                >
                  {isAccepted
                    ? t('chat.inviteTransfer.button.accepted')
                    : isCancelled
                      ? t('chat.inviteTransfer.button.cancelled')
                      : t('chat.inviteTransfer.button.accept')}
                </Button>
              </div>
            )}
          </div>

          {/* {!isCancelled() && (
          
          )}
          {isCancelled() === true && (
            <div className="border-t border-gray-300 px-1 pt-3 text-13">
              <Trans i18nKey="chat.transferMessage.alert.cancelled">
                <span className="">{{ channelName: channelName } as any}</span>
              </Trans>
            </div>
          )} */}

          {/* {!isCancelled() && isAccepted() && (
            <div className="border-t border-gray-300 px-1 pt-3 text-13">
              <Trans i18nKey="chat.transferMessage.alert.becomeMaster">
                <span className="">{{ channelName: channelName } as any}</span>
              </Trans>
            </div>
          )} */}

          {/* {!!transferChannel && currentUser?.userId !== message.sender.userId && (
            <div className="mt-3 border-t border-gray-300 pt-3 text-end">
              <Button
                color="primary"
                className="w-[100px] text-center"
                size="sm"
                type="button"
                onClick={onAccept}
                disabled={isAccepted() || isCancelled()}
              >
                {isCancelled()
                  ? t('chat.inviteTransfer.button.cancelled')
                  : isAccepted()
                    ? t('chat.inviteTransfer.button.accepted')
                    : t('chat.inviteTransfer.button.accept')}
              </Button>
            </div>
          )} */}
        </span>
      </Ballon>
    </>
  );
};
export default ChatTransferMasterMessage;
