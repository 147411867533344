import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberDto } from 'apis/types/chat.type';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';

type Props = {
  memberList: MemberDto[];
};

const ChannelInfoMemberList: React.FC<Props> = ({ memberList }) => {
  return (
    <ul className="">
      {/* 방장은 master class */}
      {/* 스텝은 staff class */}
      {memberList.map((member) => {
        return <MemberItem key={member.userId} member={member} />;
      })}
    </ul>
  );
};
export default ChannelInfoMemberList;

type MemberItemProps = { member: MemberDto };

const MemberItem: React.FC<MemberItemProps> = (props) => {
  const { member } = props;

  const { data: channel } = useCurrentChannel();

  const navigate = useNavigate();

  const onSelectMember = useCallback(
    (member: MemberDto) => () => {
      navigate(`miniprofile/${member.userId}`, {
        replace: false
      });
    },
    [navigate]
  );

  const isMaster = member.role === 'operator' && channel?.masterIds?.[0] == member.userId;

  return (
    <li
      className={classNames('mb-3 flex h-9 cursor-pointer items-center justify-between text-14 font-medium', {
        'opacity-40': member.isBlocked
      })}
      onClick={onSelectMember(member)}
    >
      <div className="flex min-w-0 flex-1 items-center gap-2">
        <ProfileAvatar
          userInfo={{
            profile_image_url: member.profileUrl
          }}
          size={40}
          className="z-0 shrink-0"
        />

        <p
          className={classNames('truncate', {
            '!font-semibold': !!member.role
          })}
        >
          {member.nickname}
        </p>
      </div>

      <img
        src={`${process.env.PUBLIC_URL}/img/ico/ic_master_pp.png`}
        className={classNames('ml-2 hidden flex-shrink-0', {
          block: isMaster
        })}
      />
    </li>
  );
};
