import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { alertState } from 'recoil/alert';

const useAlert = () => {
  const setAlert = useSetRecoilState(alertState);
  const close = useResetRecoilState(alertState);

  const open = (options: any) => {
    setTimeout(() => {
      setAlert({
        isOpen: true,
        ...options
      });
    }, 0);
  };

  return { open, close };
};

export default useAlert;
