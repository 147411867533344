import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilCallback } from 'recoil';

import chatRoomExpandedState from '../../../store/atoms/chatRoomExpandedState';

import ChatRoomInfoNoticeList from './ChatRoomInfoNoticeList';
import ChatRoomSharedFileList from './ChatRoomSharedFileList';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import MiniProfileLeftButton, { FriendRequestStatus } from 'routers/profile/MiniProfile/MiniProfileLeftButton';
import MoreOptionButton from 'routers/profile/MiniProfile/MoreOptionButton';
import SendCoinButton from 'routers/profile/MiniProfile/SendCoinButton';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useUserInfo from 'hooks/useUserInfo';

type Props = {
  toUserId: string;
  channelUrl: string;
};

const DMChannelInfo: React.FC<Props> = ({ toUserId }) => {
  const { data: channel } = useCurrentChannel();
  const { data: userInfo } = useUserInfo(toUserId);

  const [isProfileCollapsed, setIsProfileCollapsed] = useState(false);

  const { t } = useTranslation();
  const counts = useMemo(
    () => [
      { title: t('common.profile.me.item.friends'), count: userInfo?.friend_count, path: 'friends' },
      { title: t('common.profile.me.item.heart'), count: userInfo?.heart_count, path: 'hearts' },
      { title: t('common.profile.me.item.respect'), count: userInfo?.respect_count, path: 'respects' }
    ],
    [userInfo]
  );

  const onExpand = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatRoomExpandedState, true);
      },
    []
  );

  if (!channel) return null;

  return (
    <div className="h-screen w-[300px] !overflow-x-hidden px-5 pt-5">
      <div
        className={classNames('relative -m-5 mb-0 h-[140px]', {
          'bg-[#c3c5cc]': !userInfo?.banner_image_url
        })}
      >
        <button type="button" className="absolute right-4 top-4" onClick={onExpand}>
          <CloseIcon className="size-6 hover:text-gray-700" />
        </button>
        {userInfo?.banner_image_url && (
          <img className="h-[140px] w-[300px] object-cover" src={userInfo?.banner_image_url} />
        )}
        <div className="absolute left-1/2 top-[90px] z-0 size-[100px] -translate-x-1/2">
          <ProfileAvatar userInfo={userInfo as SuperClubUserInterface} size="md" badge level trustRing />
        </div>
      </div>
      <div className="mt-[54px] overflow-y-auto">
        <div className="">
          <div className="flex items-center justify-center">
            <span className="ml-1 text-20 font-semibold leading-[18px] text-black-title">{userInfo?.username}</span>
          </div>
          <div className="mt-2 flex justify-center gap-0.5">
            {userInfo?.icons?.map((icon: any) => {
              if (icon.is_representative) return <></>;
              return <img src={icon?.image_url} key={icon?.id} className="size-5 object-cover" />;
            })}
          </div>
          {isProfileCollapsed && (
            <div>
              <div className="mt-4 flex items-center justify-center">
                {counts.map((item) => {
                  return (
                    <div className="flex w-1/3 flex-col items-center justify-center" key={item.title}>
                      <div className="pt-[7px] text-24 font-semibold text-black-title">{item.count}</div>
                      <div className="text-14 font-light text-gray-600">{item.title}</div>
                    </div>
                  );
                })}
              </div>

              <textarea
                className="mt-4 h-[88px] w-[260px] rounded-3 border border-gray-100 bg-gray-100 px-4 py-3.5 pr-1 text-14 text-secondary-100 focus:border-black-body focus:text-black-body"
                value={userInfo?.description}
                readOnly
                disabled
              />

              <div className="h-5" />

              <div className="mb-5 flex gap-2 border-t border-gray-300 pt-5">
                <MiniProfileLeftButton
                  isMyProfile={false}
                  friendRequestStatus={(userInfo?.friend_request_status as FriendRequestStatus) || null}
                  userId={userInfo?.id}
                  requestId={userInfo?.friend_request_id}
                />
                <SendCoinButton user={userInfo as any} />
              </div>
            </div>
          )}
          <MoreOptionButton
            onClick={() => setIsProfileCollapsed((prev) => !prev)}
            isCollapsed={isProfileCollapsed}
            className="!mt-5 w-full"
            moreView
          />
        </div>
        <div className="chat_info_div mt-4 flex flex-col gap-4">
          <ChatRoomInfoNoticeList />
          <ChatRoomSharedFileList />
        </div>
      </div>
    </div>
  );
};

export default DMChannelInfo;
