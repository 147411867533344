import { useQuery } from 'react-query';

import userApi from 'apis/user-api';

import { getAccessToken } from 'utils/common';

const useUserMe = () => {
  const hasAccessToken = getAccessToken();

  const { data = null } = useQuery({
    queryKey: ['user', 'me'],
    queryFn: () => userApi.userMe(),
    select: (response) => response.data,
    enabled: !!hasAccessToken
  });

  return data;
};

export default useUserMe;
