import { MetaMaskSDK } from '@metamask/sdk';
import Web3 from 'web3';

import { ERC20_TOKENS, MATIC_META } from './constants';
import ERC20_ABI from './jsons/ERC20.json';
import ERC721_ABI from './jsons/ERC721.json';

function getRPC() {
  return 'https://polygon-rpc.com'; // this is a fallback rpc. if the main one stuck, this one will be used
}

function getWeb3Instance() {
  const rpc = getRPC();
  return new Web3(rpc);
  // return getProvider();
}

export async function getHoldings(address: string) {
  const tokens = await getTokenBalances(address);
  // 에러나던곳
  // const nfts = await getERC721Balances(address);
  return { tokens, nfts: [] };
}

async function getERC20Balance(contractAddress: string, address: string) {
  try {
    const web3 = getWeb3Instance();

    // AbiItem, AbiItem[], AbiFragment 해봤는데 타입에러남
    const contract = new web3.eth.Contract(ERC20_ABI as any, contractAddress);
    const balance = await contract.methods.balanceOf(address).call();

    return balance / 10 ** ERC20_TOKENS[contractAddress as keyof typeof ERC20_TOKENS].decimals;
  } catch (error) {
    return 0;
  }
}

async function getERC20HardcodedBalances(address: string) {
  const result = [];

  for (const contractAddress in ERC20_TOKENS) {
    const balance = await getERC20Balance(contractAddress, address);
    result.push({
      contractAddress,
      ...ERC20_TOKENS[contractAddress as keyof typeof ERC20_TOKENS],
      balance: balance.toString()
    });
  }
  return result;
}

async function getTokenBalances(address: string) {
  const maticBalance = await getBalance(address);
  const matic = {
    balance: maticBalance,
    ...MATIC_META
  };
  const tokens = await getERC20HardcodedBalances(address);

  return [...tokens, matic];
}

async function getBalance(address: string) {
  try {
    const web3 = getWeb3Instance();
    const balance = await web3.eth.getBalance(address);
    return Number(balance) / 10 ** 18;
  } catch (error) {
    return 0;
  }
}

export async function generateTransactionConfig({ from, to, contractAddress, tokenId, amount }: any) {
  const web3 = getWeb3Instance();

  const isNative = contractAddress === MATIC_META.contractAddress;
  let transactionConfig;
  if (isNative) {
    const decimals = 18;
    const floatingAmountFix = Math.round(amount * 10 ** 5);

    transactionConfig = {
      from: from,
      to: to,
      value: `0x${web3.utils
        .toBN(floatingAmountFix)
        .mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals - 5)))
        .toString(16)}`
    };
  } else {
    let data;
    if (ERC20_TOKENS[contractAddress as keyof typeof ERC20_TOKENS]) {
      const contract = new web3.eth.Contract(ERC20_ABI as any, contractAddress);
      const decimals = ERC20_TOKENS[contractAddress as keyof typeof ERC20_TOKENS].decimals;

      let amountTmp;

      if (amount.toString().includes('.')) {
        const floatingAmountFix = Math.round(amount * 10 ** 5);
        amountTmp = web3.utils.toBN(floatingAmountFix).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals - 5)));
      } else {
        amountTmp = web3.utils.toBN(amount).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals)));
      }
      data = contract.methods.transfer(to, amountTmp).encodeABI();
    } else {
      const contract = new web3.eth.Contract(ERC721_ABI as any, contractAddress);
      data = contract.methods.transferFrom(from, to, tokenId).encodeABI();
    }

    transactionConfig = {
      from: from,
      to: contractAddress,
      data
    };
  }

  // transactionConfig.gas = await estimateGas(transactionConfig);
  // transactionConfig.gasPrice = await estimateGasPrice();

  console.log(`transactionConfig`, transactionConfig);
  return transactionConfig;
}

const MMSDK = new MetaMaskSDK();

function getProvider() {
  if (MMSDK) {
    return MMSDK.getProvider();
  }

  if (typeof window !== 'undefined') {
    return null;
  }
}

export async function submitTransaction(transactionConfig: any) {
  try {
    const ethereum = getProvider();
    if (!ethereum) {
      return;
    }

    const address = await connectMetamask();
    if (transactionConfig.from.toLowerCase() !== address.toLowerCase()) {
      throw new Error('The connected address is not the main');
    }

    const txHash = await ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionConfig]
    });

    return txHash;
  } catch (error: any) {
    throw Error(error.message);
  }
}

async function connectMetamask() {
  try {
    const ethereum = getProvider();

    if (!ethereum) {
      window.open('https://metamask.io/download.html');
      return;
    }

    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

    if (!accounts) {
      throw new Error('no accounts');
    }

    await switchChain();
    return (accounts as any)[0];
  } catch (error: any) {
    if (error.code == '-32002') {
      console.log('already processing');
    }
    console.log(error);
  }
}

const DEFAULT_CHAIN_ID = '0x89';
const DEFAULT_CHAIN_NAME = 'Polygon Mainnet';
const DEFAULT_CHAIN_RPC = 'https://polygon-rpc.com';
const DEFAULT_CHAIN_BLOCK_EXPLORER = 'https://polygonscan.com;';
const DEFAULT_CHAIN_CURRENCY = {
  name: 'MATIC',
  symbol: 'MATIC',
  decimals: 18
};

async function switchChain() {
  const ethereum = getProvider();

  if (!ethereum) {
    return;
  }

  try {
    if (ethereum.chainId === DEFAULT_CHAIN_ID) {
      return;
    }

    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: DEFAULT_CHAIN_ID }]
    });
  } catch (switchError: any) {
    // info: This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902 || switchError.code === -32603) {
      try {
        await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: DEFAULT_CHAIN_ID,
              chainName: DEFAULT_CHAIN_NAME,
              rpcUrls: [DEFAULT_CHAIN_RPC],
              blockExplorerUrls: [DEFAULT_CHAIN_BLOCK_EXPLORER],
              nativeCurrency: DEFAULT_CHAIN_CURRENCY
            }
          ]
        });
      } catch (addError: any) {
        console.log(addError);
        // throw Error(addError.message);
      }
    }
  }
}

const sleep = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export async function trackTransaction(txHash: any, recursionCount = 0): Promise<any> {
  try {
    if (recursionCount > 50) {
      // Throw error when 100sec passed
      return undefined;
    }

    const web3 = getWeb3Instance();

    await sleep(3000); // info - moved places because the polygon mumbai rpc had issues with confirmed transactions
    const response = await web3.eth.getTransactionReceipt(txHash);
    if (!response) {
      return await trackTransaction(txHash, recursionCount++);
    }

    if (!response.status) {
      return undefined;
    }

    return response;
  } catch (error: any) {
    console.error(`trackTransaction: ${error.message}`);
    return undefined;
  }
}
