import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';

import router from './routers/router';
import { PostMessagePath, RequestCommand, ResponseCommand } from './types/postMessageCommand';

import Loader from 'components/jayden/Loader';
import getSendBird from 'libs/sendbird';
import navigatePathState from 'store/atoms/navigatePathState';

import { setAccessToken } from 'utils/common';
import { clearStorage } from 'utils/storage';

import './i18n';
import 'tailwind.css';

function App() {
  const { i18n } = useTranslation();
  const isWebView = window.parent !== window;
  const [load, setLoad] = useState(false);

  const setNavigatePath = useSetRecoilState(navigatePathState);
  const messageHandler = useCallback(
    (event: MessageEvent) => {
      if (load) return;

      if (event.origin.indexOf('localhost') === -1 && event.origin.indexOf('superclub') === -1) {
        return;
      }

      if (typeof event.data !== 'object' || event.data.from !== PostMessagePath.Superclub) {
        return;
      }

      if (event.data.command === ResponseCommand.Initialize) {
        const accessToken = event.data.accessToken as string;
        const navigatePath = event.data?.navigatePath as string;
        const language = event.data?.language as string;

        if (navigatePath) {
          localStorage.setItem('navigatePath', navigatePath);
          setNavigatePath(navigatePath);
        }

        if (language) {
          i18n.changeLanguage(language);
        }

        setAccessToken(accessToken);
        setLoad(true);
      }
    },
    [i18n, load]
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);

    if (isWebView) {
      window.parent.postMessage(
        {
          from: PostMessagePath.Chat,
          command: RequestCommand.Ready
        },
        '*'
      );
    } else {
      window?.opener?.postMessage(
        {
          from: PostMessagePath.Chat,
          command: RequestCommand.Ready
        },
        '*'
      );
    }

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  useEffect(() => {
    if (!isWebView && !window.opener) {
      clearStorage();
      window.location.href = process.env.REACT_APP_MAIN_URL as string;
    }

    return () => {
      getSendBird().disconnect();
    };
  }, []);

  if (!load) return <Loader className="top-1/2 mt-[0px] -translate-y-1/2" />;
  return <RouterProvider router={router} />;
}

export default App;
