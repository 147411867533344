export interface DjangoInterface<T> {
  data: T;
  code: number;
  message: string;
  count?: number;
  next?: string;
  previous?: string;
}

// VOTE !!
export interface VoteUserInterface {
  id: number;
  profile_image_url: string;
  username: string;
}

export interface VoteInterface {
  id: number;
  title: string;
  is_secret: boolean;
  is_mine: true;
  is_voted: boolean;
  created: string;
  expiry_date?: string;
  ended_at?: string;
  forum_id?: number;
  post_id?: number;
  chat_id?: number;
  club_id?: number;
  profile_id?: number;
  max_options: number;
  options: VoteOptionInterface[];
  status: VoteStatus;
  view_result: VoteViewResult;
  vote_count: number;
  voters?: VoterInterface[];
  user?: VoteUserInterface;
  most_voted_options?: number[];
}

export interface VoteOptionInterface {
  id: number;
  content: string;
  created: string;
  image_url?: string | null;
  is_selected?: boolean;
  vote_ratio?: number;
  option_count?: number;
}

export interface VoterInterface {
  user: VoteUserInterface;
  created: string;
}

export type VoteStatus = 'VOTING' | 'ENDED';
export type VoteViewResult = 'INSTANT' | 'CLOSED' | 'ALWAYS';

export enum StatusCode {
  FINISHED = 200001,
  CANCELED = 200002
}

/**
 * :::::::::::::::::::::::::::::::::::
 * :::::::::::::: CLUB :::::::::::::::
 * :::::::::::::::::::::::::::::::::::
 */

// export type AgendaStatus = "PENDING" | "OBJECTION" | "VOTE" | "COMPLETE";
// export type AgendaResult = "CANCEL" | "DENY" | "ON_GOING" | "SUCCESS" | "FAIL";

// export interface ClubCombineAgendaInterface {}
// export interface ClubTransferAgendaInterface {
//   id: number;
// }
// export interface ClubIndependentAgendaInterface {}
// export interface ClubClosureAgendaInterface {}

// export interface ClubAgendaInterface {
//   id: number;
//   club: number;
//   status: AgendaStatus;
//   club_profile_count: number;
//   objection_count: number;
//   objection_expiry_date: string;
//   agree_count: number;
//   disagree_count: number;
//   vote_expiry_date?: string | null;
//   result: AgendaResult;
//   post_id?: number | null;
//   combine_agenda?: ClubCombineAgendaInterface | null;
//   transfer_agenda?: ClubTransferAgendaInterface | null;
//   independent_agenda?: ClubIndependentAgendaInterface | null;
//   closure_agenda?: ClubClosureAgendaInterface | null;
//   is_objection?: boolean | null;
//   is_agree?: boolean | null;
//   is_disagree?: boolean | null;
// }

export interface ReportChoiceInterface {
  id: number;
  title: string;
  content: string;
  is_active: boolean;
  created: string;
  modified: string;
}

enum LocusMeetingMemberRole {
  HOST = 'HOST',
  STAFF = 'STAFF',
  MEMBER = 'MEMBER'
}

interface LocusMeetingMember {
  id: string;
  role: LocusMeetingMemberRole;
  user: {
    id: number; // superclubs user_id
    username: string;
    profile_image_url: string;
  };
}

enum LocusMeetingPublicType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  CLUB_MEMBER = 'CLUB_MEMBER'
}

export interface LocusMeeting {
  id: string; // locus-meeting의 방 이름(dev, stg는 prefix있음)
  title: string;
  description?: string;
  thumbnail_image_url?: string;
  url: string;
  members: Array<LocusMeetingMember>;
  last_joined_at: string; // Date
  is_bookmarked: boolean;
  public_type: LocusMeetingPublicType;
  club_id?: number;
}
