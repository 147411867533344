import React, { useCallback, useMemo } from 'react';

import useMessage from '../../../hooks/useMessage';
import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import { ChatMessage, CustomMessageType } from '../../../types/common';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/ic-reply-sm-bk.svg';

type Props = {
  parentMessage: ChatMessage;
};
const ChatMessageReplyInput: React.FC<Props> = ({ parentMessage }) => {
  const message = useMessage({
    message: parentMessage
  });

  const { closeReply } = useMessageInput();

  const onClose = useCallback(() => {
    closeReply();
  }, [closeReply]);

  const fileList = useMemo<UploadToS3Response[]>(() => {
    try {
      const parsedList = JSON.parse(parentMessage.message).slice(0, 5);
      return parsedList;
    } catch (e) {
      return [];
    }
  }, [parentMessage]);

  return (
    <div className="absolute top-[-46px] z-10 mx-5 h-10 w-[calc(100%-40px)] rounded-5 bg-[rgba(36,42,60,0.8)]">
      <div className="relative flex h-10 w-full items-center justify-between px-4">
        <div className="flex items-center">
          <span className="relative mr-3 flex gap-2 text-14 font-medium text-gray-400">
            <ReplyIcon className="size-5" />
            <span>{message.user?.nickname}</span>
          </span>
          {/* 첨부파일 답글 시에는 댓글처럼 파일명만 텍스트로 보여주시면 됩니다. */}
          {message.customType === CustomMessageType.TEXT && (
            <p className="truncate text-13 text-white">{parentMessage.message}</p>
          )}
          {/* 이미지 답글 시 이미지 미리보기 (최대 5개 / 링크없음) */}
          {message.customType === CustomMessageType.IMAGE && (
            <ul className="inline-flex items-center gap-1">
              {fileList.map((image) => (
                <li key={image.cloudFrontUrl} className="size-7 overflow-hidden rounded">
                  <img className="size-full object-cover" src={image.cloudFrontUrl} />
                </li>
              ))}
            </ul>
          )}
          {/* 동영상 답글 시 동영상 미리보기 (링크없음) */}
          {message.customType === CustomMessageType.VIDEO && (
            <ul>
              {fileList.map((video) => (
                <li key={video.cloudFrontUrl} className="size-7 overflow-hidden rounded">
                  <video width="100%" height="100%">
                    <source src={video.cloudFrontUrl} type={video.contentType} />
                  </video>
                </li>
              ))}
            </ul>
          )}
        </div>

        <button type="button" className="" onClick={onClose}>
          <CloseIcon className="size-5 text-white" />
          <span className="sr-only" />
        </button>
      </div>
    </div>
  );
};

export default ChatMessageReplyInput;
