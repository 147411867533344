import styled from 'styled-components';

export const StyledRow = styled.div`
  display: flex;
`;

export const StyledColumn = styled(StyledRow)`
  flex-direction: column;
`;

export const StyledCenter = styled(StyledRow)`
  justify-content: center;
  align-items: center;
`;

export const StyledCoverFitImage = styled.img`
  object-fit: cover;
`;

export const StyledText = styled.span`
  font-family: Poppins;
  font-size: 16px;
`;

export const StyledButton = styled.button.attrs({
  type: 'button'
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const StyledInput = styled.input`
  display: flex;
  border: none;
  outline: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
