import { useCallback, useEffect, useState } from 'react';

const useTextInputChange = (
  initialValue: string,
  limitLength?: number
): [
  string,
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  (name?: string | any) => void,
  () => void,
  (value: string) => void
] => {
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (limitLength) {
        if (event.target.value.length > limitLength) {
          return;
        }
      }

      setValue(event.target.value);
    },
    [limitLength]
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const reset = useCallback((name?: string) => {
    setValue(name || initialValue);
  }, []);

  const clear = useCallback(() => {
    setValue('');
  }, []);

  const set = useCallback((value: string) => {
    setValue(value);
  }, []);

  return [value, onChange, reset, clear, set];
};

export default useTextInputChange;
