import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import chatApi from 'apis/chat-api';

import { getChannelIdByChannelUrl } from 'utils/common';

export interface CurrentChannelOptions {
  noRedirect?: boolean;
}

export const useCurrentChannel = () => {
  const { channelUrl } = useParams();

  const query = useQuery({
    queryKey: ['channels', channelUrl],
    queryFn: () => chatApi.getGroupChannel({ channelId: getChannelIdByChannelUrl(channelUrl!) }),
    select: (response) => response.data,
    enabled: !!channelUrl && channelUrl !== 'undefined'
  });

  return {
    ...query,
    url: channelUrl || ''
  };
};
