import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useDebounce } from 'usehooks-ts';

import MaskingAddressBadge from '../components/masking-address-badge';
import { useGetWalletUserQuery } from '../hooks/use-get-query';

import { InfoIcon, SearchIcon } from 'assets/iicon';
import ModalLayout from 'components/alert/modal-layout';
import Button from 'components/jayden/Button';
import Input from 'components/jayden/Input';
import classNames from 'components/styled/util';

interface AddUserModalProps {
  initialData: any[];
  onSubmit: (list: any[]) => void;
  close?: () => void;
  channelId?: string;

  singleSelect: boolean;
}

const AddUserModal = (props: AddUserModalProps) => {
  const { initialData, onSubmit, close = () => {}, channelId, singleSelect } = props;
  const { t } = useTranslation();

  const [searchWord, setSearchWord] = useState('');
  const search = useDebounce(searchWord, 500);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { data, totalCount, observerRef } = useGetWalletUserQuery(
    {
      page_size: 10,
      is_not_me: true,
      search,
      channel: channelId,
      ordering: '-is_wallet'
    },
    scrollRef
  );
  const [tempList, setTempList] = useState<any>(initialData);

  // 메타마스크 상태일땐 다중전송이 안돼서 하나의 유저를 갈아끼우는 방식
  const handleMetamaskClick = (item: any) => {
    if (tempList.length === 0) {
      setTempList([item]);
    } else if (tempList[0].wallet_address === item.wallet_address) {
      setTempList([]);
    } else {
      setTempList([item]);
    }
  };

  const handleDefaultClick = (item: any) => {
    const isSelected = tempList.some((el: any) => el.id === item.id);
    if (isSelected) {
      setTempList(tempList.filter((el: any) => el.id !== item.id));
    } else {
      setTempList([...tempList, item]);
    }
  };

  const onClick = (item: any) => {
    if (!item.wallet_address) return;

    singleSelect ? handleMetamaskClick(item) : handleDefaultClick(item);
  };

  return (
    <ModalLayout title={t('sendCoin.addUserModal.title')} close={close}>
      <div className="px-[14px]">
        <div className="px-2.5">
          <Input
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            className="peer h-12"
            placeholder={t('common.label.search')}
            suffix={
              <SearchIcon className="absolute right-4 top-6 size-6 -translate-y-1/2 text-gray-500 peer-focus:text-black-title" />
            }
          />
        </div>

        <div className="mt-6">
          <div className="flex items-center justify-between px-2.5">
            <h4 className="text-16 font-semibold text-black-title">
              <span className="text-20 text-primary-100">{tempList.length}</span>
              &nbsp;{t('sendCoin.userSelect.selected')}
            </h4>

            <div className="flex shrink-0 items-center justify-center gap-1">
              <p className="text-17 font-medium text-gray-500">
                {t('common.total.label')} {totalCount || 0}
              </p>
              <div data-tooltip-id="group-send-coin-add-user" className="text-14">
                <InfoIcon className="size-6 text-gray-500" />
                <Tooltip
                  id="group-send-coin-add-user"
                  content={t('chat.sendCoin.addUser.tooltip.content')}
                  place="left"
                />
              </div>
            </div>
          </div>

          <div className="mt-2.5 max-h-[300px] overflow-auto" ref={scrollRef}>
            {data.map((v: any, i) => {
              const isSelected = tempList.some((el: any) => el.id === v.id);

              return (
                <div
                  key={`${v.id}_${i}`}
                  className={classNames('flex items-center justify-between p-2.5', {
                    'bg-[#faf5ff]': isSelected,
                    'cursor-not-allowed': !v.wallet_address,
                    'cursor-pointer': !!v.wallet_address
                  })}
                  onClick={() => onClick(v)}
                >
                  <div className="flex items-center gap-2">
                    <img src={v.profile_image_url} className="size-10 rounded-full" />
                    <div>
                      <p className="text-16 font-medium text-black-title">
                        {v.username}
                        <span className="font-normal text-gray-600">#{v.hash}</span>
                      </p>
                    </div>
                  </div>

                  <MaskingAddressBadge address={v.wallet_address} />
                </div>
              );
            })}
            <div ref={observerRef} className="h-4" />
          </div>
        </div>
      </div>

      <div className="p-5">
        <Button
          size="lg"
          color="primary"
          disabled={tempList.length === 0}
          className="w-full"
          center
          onClick={() => {
            onSubmit(tempList);
            close();
          }}
        >
          {t('common.button.confirm')}
        </Button>
      </div>
    </ModalLayout>
  );
};

export default AddUserModal;
