import React from 'react';

import { PostMessagePath, RequestCommand } from '../types/postMessageCommand';

const usePostMessage = (targetElement: React.RefObject<HTMLIFrameElement> | Window | null) => {
  const postMessage = (command: RequestCommand, data?: any) => {
    if (targetElement === null) {
      console.log('targetElement is null');
      return;
    }

    const message = {
      from: PostMessagePath.Chat,
      command,
      ...data
    };

    if (targetElement instanceof Window) {
      targetElement.postMessage(message, '*');
    } else if (targetElement.current instanceof HTMLIFrameElement) {
      targetElement.current.contentWindow?.postMessage(message, '*');
    } else {
      throw new Error('Invalid targetElement: Must be Window or HTMLIFrameElement');
    }
  };

  return postMessage;
};

export default usePostMessage;
