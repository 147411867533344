import { commonInstance } from 'apis';

export const sendCoinQueryKey = {
  all: () => ['chat-send-coin'],
  wallet: () => [...sendCoinQueryKey.all(), 'wallet'],
  user: ({ ...params }) => [...sendCoinQueryKey.wallet(), 'user', params],
  group: ({ ...params }) => [...sendCoinQueryKey.wallet(), 'group', params]
};

export interface postCoinTransferGroupsPayload {}

const sendCoinApi = {
  /**
   * 채팅방에 지갑 정보가 있는 유저들을 가져오는 쿼리
   */
  getUserList: async (params: any) => {
    const { data } = await commonInstance.get('users', { params });
    return data;
  },

  /**
   * 채팅방에 지갑정보 가져오기 (그룹용)
   */
  getUsersWalletAddresses: async (params: any) => {
    const { data } = await commonInstance.get(`users/wallet-addresses`, { params });
    return data;
  },

  /**
   * 전송 그룹 리스트 조회
   */
  getCoinTransferGroups: async () => {
    const { data } = await commonInstance.get(`coin-transfer-groups`);
    return data;
  },

  /**
   * 전송 그룹 상세 조회
   */
  deleteCoinTransferGroup: async (id: number) => {
    const { data } = await commonInstance.delete(`coin-transfer-groups/${id}`);
    return data;
  },
  /**
   * 전송 그룹 생성
   */
  postCoinTransferGroups: async (payload: any) => {
    const { data } = await commonInstance.post(`coin-transfer-groups`, payload);
    return data;
  },

  /**
   * 전송 그룹 수정
   */
  patchCoinTransferGroups: async ({ id, payload }: any) => {
    const { data } = await commonInstance.patch(`coin-transfer-groups/${id}`, payload);
    return data;
  }
};

export default sendCoinApi;
