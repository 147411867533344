import React from 'react';
import reactDom from 'react-dom';

import Alert from './Alert';
import ModalStack from './ModalStack';

const Portal = ({ children }: { children: React.ReactNode }) => {
  const el = document.getElementById('portal');
  if (!el) return null;

  el.style.zIndex = '999999';
  el.style.position = 'fixed';
  return reactDom.createPortal(children, el);
};

const ModalProvider = () => {
  return (
    <Portal>
      <ModalStack />
      <Alert />
    </Portal>
  );
};

export default ModalProvider;
