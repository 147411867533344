// const mock = {
//   code: 201,
//   message: 'ok',
//   data: {
//     id: 19475,
//     file: 'https://cf.dev.superclubs.io/media/common/image/4137d33b-6ec7-4cc1-8c66-258200d8ef3c/20240717/20240717T065055.png',
//     url: 'https://cf.dev.superclubs.io/media/common/image/4137d33b-6ec7-4cc1-8c66-258200d8ef3c/20240717/20240717T065055.png',
//     type: 'image'
//   }
// };
import { v4 as uuidv4 } from 'uuid';

import { FileType, UploadToS3Response } from 'libs/s3';

interface ResponseType {
  code: number;
  message: 'ok';
  data: {
    id: number;
    file: string;
    url: string;
    type: string;
  };
}

/**
 * 미디어 업로드 양식 최대한 기존과 비슷하게 주기
 * @param media_API_response
 * @param type - file 객체의 type
 */
export const _changeS3Form = (response: ResponseType, type: string): UploadToS3Response => {
  const { file, url, type: responseType } = response.data;

  const newFileName = uuidv4();

  // Determine the file type based on the 'type' field in the response
  let fileType: FileType;
  switch (responseType) {
    case 'image':
      console.log('image');
      fileType = FileType.Photo;
      break;
    case 'gif':
      console.log('gif');
      fileType = FileType.Gif;
      break;
    case 'video':
      console.log('video');
      fileType = FileType.Video;
      break;
    default:
      console.log('default');
      fileType = FileType.File;
      break;
  }

  return {
    localUrl: file,
    cloudFrontUrl: url,
    fileKey: file.split('/').pop() || '',
    fileSize: 0,
    fileType,
    contentType: type,
    orgFileName: `${newFileName}`
  };
};
