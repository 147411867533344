import { useMutation } from 'react-query';

import sendCoinApi from 'apis/send-coin';

import { MutationOptionsType } from 'hooks/use-friend-mutation';

export const usePostCoinTransferGroupsMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: sendCoinApi.postCoinTransferGroups,
    ...options
  });
};

export const usePatchCoinTransferGroupMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: sendCoinApi.patchCoinTransferGroups,
    ...options
  });
};
