import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { saveCropImage } from '../../libs/crop';

type Props = {
  file: File;
  onCancel: () => void;
  onSave: (response: string) => void;
};
// const fileTypes = ['JPG', 'PNG'];

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const SNS_PROFILE_IMAGE_WIDTH = 300;
export const SNS_PROFILE_IMAGE_HEIGHT = 300;
export const SNS_BANNER_IMAGE_WIDTH = 1280;
export const SNS_BANNER_IMAGE_HEIGHT = 304;

const CropImageModal: React.FC<Props> = ({ file, onCancel, onSave }) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: SNS_PROFILE_IMAGE_WIDTH,
    height: SNS_PROFILE_IMAGE_HEIGHT
  });
  const refImg = useRef<HTMLImageElement | null>(null);

  const cropWidth = SNS_PROFILE_IMAGE_WIDTH;
  const cropHeight = SNS_PROFILE_IMAGE_HEIGHT;
  const aspect = cropWidth / cropHeight;
  const [rotation] = useState(0);

  const [base64, setBase64] = useState('');

  const onImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (aspect) {
        const { width, height } = event.currentTarget;
        setCrop(
          centerCrop(
            makeAspectCrop(
              {
                unit: 'px',
                width: cropWidth,
                height: cropHeight
              },
              aspect,
              width,
              height
            ),
            width,
            height
          )
        );
      }
    },
    [aspect, cropWidth, cropHeight]
  );

  useEffect(() => {
    async function init() {
      const result = await toBase64(file);
      setBase64(result);
    }
    init();
  }, [file]);

  const onUpload = useCallback(async () => {
    if (!crop) {
      return;
    }

    if (!refImg.current) {
      return;
    }

    const result = await saveCropImage(crop, refImg.current, rotation);
    if (!result?.blob) {
      onSave(base64);
      return;
    }

    onSave(result.url);
  }, [crop, base64, rotation]);

  return (
    <>
      <div className="channel_modal" style={{ display: 'block' }} />
      <div className="channel_modal_div" style={{ display: 'block' }}>
        <div className="channel_modal_menu">
          <div className="channel_modal_menu_title">Change Thumbnail</div>
          {/* <button type="button" className="btn_rotate" onClick={() => setRotation(rotation + 90)}>
            <span>Rotate</span>
          </button> */}
        </div>
        {/* 이미지 가로/세로 최대 사이즈는 432 / 340입니다. */}
        <div className="edit_thum">
          <ReactCrop
            crop={crop}
            locked={true}
            circularCrop={false}
            aspect={aspect}
            onChange={(c) => setCrop(c)}
            onComplete={(croped, percentageCrop) => {
              console.log(croped, percentageCrop);
            }}
          >
            <img
              ref={refImg}
              src={base64}
              style={{
                height: 340,
                transform: `rotate(${rotation}deg)`,
                opacity: 0.7,
                objectFit: 'cover'
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
        <div className="channel_modal_btn">
          {/* 취소 시 Alert(Are you sure you want to cancel the edit and leave?<br/>Changes may not be saved.) / Button : Cancle, Confirm */}
          <button
            type="button"
            className="cancel"
            onClick={() => {
              onCancel();
            }}
          >
            <span>Cancel</span>
          </button>
          <button type="button" className="done" onClick={onUpload}>
            <span>Done</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CropImageModal;
