import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { downloadURI } from '../../libs/helper';
import { UploadToS3Response } from '../../libs/s3';
import { fetchMessage } from '../../libs/sendbird';

type Params = {
  messageId: string;
};

const Video: React.FC = () => {
  const navigate = useNavigate();
  const { messageId } = useParams<Params>();
  const [imageList, setImageList] = useState<UploadToS3Response[]>([]);
  console.log('imageList :', imageList);
  const [index, setIndex] = useState<number>(0);
  const { channelUrl } = useParams<{ channelUrl: string }>();

  const onLeft = useCallback(() => {
    setIndex((state) => {
      let newIndex = state - 1;
      if (newIndex < 0) {
        newIndex = imageList.length - 1;
      }
      return newIndex;
    });
  }, [imageList]);

  const onRight = useCallback(() => {
    setIndex((state) => {
      let newIndex = state + 1;
      if (newIndex > imageList.length - 1) {
        newIndex = 0;
      }
      return newIndex;
    });
  }, [imageList]);

  const onDownload = useCallback(async () => {
    for (const image of imageList) {
      await downloadURI(image.cloudFrontUrl, image.orgFileName);
    }
  }, [imageList]);

  const init = async () => {
    if (!messageId) {
      return;
    }

    if (!channelUrl) return;

    const message = await fetchMessage(channelUrl, parseInt(messageId, 10));

    const parsedMessage = JSON.parse(message.data!) as UploadToS3Response[];
    setImageList(parsedMessage);
  };

  useEffect(() => {
    init();
  }, []);

  if (imageList.length === 0) {
    return null;
  }

  return (
    <>
      <div id="modal" />
      <div className="chat_img_preview">
        {/* 미리비로기에 보여지는 이미지는 가로 1120px, 세로 600px을 넘지 않습니다.*/}
        <div className="img_preview_area">
          <button type="button" className="img_pre_btn left" onClick={onLeft}>
            <span className="a11y">좌측으로</span>
          </button>
          <button type="button" className="img_pre_btn right" onClick={onRight}>
            <span className="a11y">우측으로</span>
          </button>
          <button type="button" className="img_btn down" onClick={onDownload}>
            <span className="a11y">다운로드</span>
          </button>
          <button
            type="button"
            className="img_btn close"
            onClick={() => {
              navigate(-1);
            }}
          >
            <span className="a11y">닫기</span>
          </button>
          <video
            key={imageList[index].cloudFrontUrl}
            style={{ maxWidth: '1000px', maxHeight: '500px' }}
            controls
            autoPlay
          >
            <source src={`${imageList[index].cloudFrontUrl}#t=0.1`} type={imageList[index].contentType} />
          </video>
          <div className="img_number">
            <span>
              <em>{index + 1}</em> / {imageList.length}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
