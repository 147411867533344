import { atom, selectorFamily } from 'recoil';

import { ChatChannel } from '../../types/common';

export type GroupChannelsState = {
  [key in string]: ChatChannel;
};

const groupChannelsState = atom<GroupChannelsState>({
  key: 'groupChannelsState',
  default: {}
});

export const groupChannelStateByUrl = selectorFamily<ChatChannel, string>({
  key: 'groupChannelState',
  get:
    (channelUrl) =>
    ({ get }) => {
      return get(groupChannelsState)[channelUrl];
    }
});

export const groupChannelIdsState = atom<string[]>({
  key: 'groupChannelIdsState',
  default: []
});

export const groupChannelListForInvite = selectorFamily<ChatChannel[], null>({
  key: 'groupChannelListForInviteState',
  get:
    () =>
    ({ get }) => {
      const groupChannels = get(groupChannelsState);
      const ret: ChatChannel[] = [];
      Object.keys(groupChannels).forEach((key) => {
        const groupChannel = groupChannels[key];
        if (groupChannel.customType !== 'DM') {
          ret.push(groupChannel);
        }
      });

      return ret;
    }
});

export default groupChannelsState;
