import React, { useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import ModalBackdrop from './ModalBackdrop';
import useModalStack from './useModalStack';

import { modalStackState } from 'recoil/modalStack';

const ModalStack = () => {
  const modalStack = useRecoilValue(modalStackState);

  useEffect(() => {
    if (modalStack.length > 0) {
      document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [modalStack]);

  return (
    <>
      {modalStack.map((modal) => (
        <ModalComponent key={modal.key} modal={modal} />
      ))}
    </>
  );
};

const ModalComponent = ({ modal }: { modal: any }) => {
  const { key, Component, componentProps, disableBackdropClick, backdropTransparent } = modal;
  const { remove } = useModalStack();
  const close = () => remove(key);
  return (
    <ModalBackdrop close={close} disableBackdropClick={disableBackdropClick} backdropTransparent={backdropTransparent}>
      <Component close={close} {...componentProps} />
    </ModalBackdrop>
  );
};

export default ModalStack;
