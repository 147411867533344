import { atom } from 'recoil';

export type MessageTranslateState = number[];

const messageTranslateKeysState = atom<MessageTranslateState>({
  key: 'messageTranslateKeysState',
  default: []
});

export default messageTranslateKeysState;
