import axios from 'axios';

const API_KEY = 'AIzaSyC87fmQys-ZKeBq5-GhOzQnwFgPHeikW_E';
const API_URL = 'https://translation.googleapis.com/language/translate/v2';

const api = axios.create({
  baseURL: `${API_URL}?key=${API_KEY}`
});
const translateApi = {
  getMessages: async ({ msg, to }: any) => {
    const { data } = await api.post('', {
      q: msg,
      target: to,
      format: 'html'
    });

    return data;
  }
};

export default translateApi;
