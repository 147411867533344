import { mediaInstance } from 'apis';

export const mediaApi = {
  postImageUpload: async (formData: FormData) => {
    const { data } = await mediaInstance('/upload/image', {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  },
  postFileUpload: async (formData: FormData) => {
    const { data } = await mediaInstance('/upload/file', {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  },

  postVideoUpload: async (formData: FormData) => {
    const { data } = await mediaInstance('/upload/video', {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }
};
