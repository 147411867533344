import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';

import { AlertState } from '../../store/atoms/alertsState';

import classNames from 'components/styled/util';

type Props = AlertState;

const Alert: React.FC<Props> = ({
  title,
  content,
  buttons = [
    {
      title: '확인',
      onClick: () => {},
      color: 'primary'
    }
  ]
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const triggerShortcut = (e: KeyboardEvent) => {
      buttons.forEach((button) => {
        if (!button.shortcut) return;
        const isShortcut = [...button.shortcut].some((key) => e.key === key);
        if (isShortcut) {
          button.onClick();
        }
      });
    };

    document.addEventListener('keydown', triggerShortcut);

    return () => {
      document.removeEventListener('keydown', triggerShortcut);
    };
  }, [buttons]);

  return (
    <>
      <div id="modal" style={{ zIndex: 1000 }} />
      {/* Alert */}
      <div className="modal_popup alert" style={{ zIndex: 1001 }}>
        {/* S : Alert Message */}
        {/* 보라색 문구는 <span class="purple"></span> */}
        {/* 분홍색 문구는 <span class="pink"></span> */}
        {/* 굵은 문구는 bold class를 추가해주세요 */}
        <div className="modal_con !break-all">
          {/* 필요에 따라 타이틀이 추가될 수 있습니다. */}
          {!!title && <div className="alert_title !break-all">{title}</div>}

          {typeof content === 'string' && parse(content || '')}
          {typeof content !== 'string' && content}
        </div>
        {/* E : Alert Message */}
        {/* S : Alert Button */}
        {/* Alert에 버튼이 2개일 경우 full 대신 half class를 넣어주시면 됩니다. */}
        <div
          className={classNames({
            modal_btn_wrap: true,
            full: buttons.length === 1,
            half: buttons.length === 2
          })}
        >
          {buttons &&
            buttons.map((button, buttonIndex) => {
              return (
                <button
                  key={buttonIndex}
                  className={classNames({
                    button_lg: true,
                    btn: true,
                    primary:
                      buttonIndex === 1 || button.title === t('common.button.confirm') || button.color === 'primary',
                    default: buttonIndex === 0 || button.color === 'white'
                  })}
                  onClick={button.onClick}
                >
                  <span>{button.title}</span>
                </button>
              );
            })}
          {/* Submit, Confirm 용 / 보라색 바탕에 흰 글씨 */}
        </div>
        {/* E : Alert Button */}
      </div>
    </>
  );
};

export default Alert;
