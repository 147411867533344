import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { throttle } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import CropImageModal from '../../components/image/CropImageModal';
import ModalContainer from '../../components/layout/ModalContainer';
import { DEFAULT_PARTICIPANTS_LIMIT, MAX_PARTICIPANTS_LIMIT } from '../../constants/common';
import useCurrentUser from '../../hooks/useCurrentUser';
import useTextInputChange from '../../hooks/useTextInputChange';
import { ChannelPrivacyType, ChatChannelType } from '../../types/common';

import { mediaApi } from 'apis/media';
import { ReactComponent as MinusIcon } from 'assets/icons/ic-minus-sm.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/ic-plus-sm.svg';
import classNames from 'components/styled/util';

import useNewGroupChannel from 'hooks/use-group-channel';

const ChannelCreate = () => {
  const qc = useQueryClient();
  const navigate = useNavigate();

  const { createChannel } = useNewGroupChannel();
  const { currentUser } = useCurrentUser();
  const [channelName, onChangeChannelName] = useTextInputChange('', 20);
  const [isLoading, setIsLoading] = useState(false);
  const refIsLoading = useRef(false);
  const refImg = useRef<HTMLImageElement | null>(null);
  const { t } = useTranslation();

  const [introduction, onChangeIntroduction] = useTextInputChange('', 200);
  const [channelType, setChannelType] = useState(ChatChannelType.TEXT);

  const [channelPrivacyType, SetChannelPrivacyType] = useState(ChannelPrivacyType.OPEN);
  const [file, setFile] = useState<FileList | null>(null);
  const hiddenFileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [participantsLimit, setParticipantsLimit] = useState<string>(`${DEFAULT_PARTICIPANTS_LIMIT}`);
  const [thumbnail, setThumbnail] = useState<string | null>();

  const [coverUrl, setCoverUrl] = useState(currentUser?.profileUrl || currentUser?.plainProfileUrl);

  const onChangeChannelPrivacyType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    SetChannelPrivacyType(event.target.value as unknown as ChannelPrivacyType);
  }, []);

  const onChangeChannelType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setChannelType(event.target.value as ChatChannelType);
  }, []);

  // 채널 생성
  const onCreateChannel = throttle(
    useCallback(async () => {
      if (refIsLoading.current === true) {
        return;
      }

      refIsLoading.current = true;

      if (!currentUser?.userId) {
        return;
      }

      setIsLoading(true);

      let newCoverUrl = currentUser?.profileUrl || currentUser?.plainProfileUrl;

      if (thumbnail) {
        const fileName = `${uuidv4()}.png`;

        const res = await fetch(thumbnail);
        const blob = await res.blob();

        const formData = new FormData();

        const file = new File([blob], fileName, { type: 'image/png' });
        formData.append('file', file, fileName);

        const { data: response } = await mediaApi.postImageUpload(formData);

        newCoverUrl = response.url;
      }

      // public, onlyClubMembers는 공개설정, private는 비공개 설정
      const isPublic = channelPrivacyType !== ChannelPrivacyType.PRIVATE;

      const { data: channel } = await createChannel({
        name: channelName,
        coverUrl: newCoverUrl ?? '',
        isPublic,
        introduction,
        participantsLimit: Number(participantsLimit)
      });

      qc.invalidateQueries('channels');

      if (channel) {
        navigate(`/channel/${channel.channelUrl}`);
      }

      setIsLoading(false);
      refIsLoading.current = false;
    }, [channelName, introduction, channelPrivacyType, currentUser?.userId, coverUrl, participantsLimit, thumbnail]),
    2000
  );

  const onChangeThumbnail = useCallback(() => {
    hiddenFileInputRef?.current?.click();
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files);
  }, []);

  const onChangeParticipantLimit = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const toNum = Number(value);
    if (toNum > MAX_PARTICIPANTS_LIMIT) {
      event.target.value = `${MAX_PARTICIPANTS_LIMIT}`;
    }

    if (value && toNum < 2) {
      event.target.value = '2';
    }

    setParticipantsLimit(event.target.value);
  }, []);

  const onChangeChannelThumbnail = useCallback((url: string) => {
    setCoverUrl(url);
    setThumbnail(url);
  }, []);

  const onCropCancel = useCallback(() => {
    hiddenFileInputRef?.current?.setAttribute('value', '');
    setFile(null);
  }, []);

  const onCropSave = useCallback((base64: string) => {
    console.log('base64 :', base64);
    hiddenFileInputRef?.current?.setAttribute('value', '');
    setFile(null);

    onChangeChannelThumbnail(base64);
  }, []);

  const selectedFile = useMemo(() => {
    return file?.item(0);
  }, [file]);

  const validateChannelName = () => channelName.length > 0 && !(channelName.length >= 2 && channelName.length <= 20);

  return (
    <ModalContainer
      Buttons={
        <button
          className="btn primary button_lg"
          disabled={channelName.length < 2 || !participantsLimit || !introduction || isLoading === true}
          onClick={onCreateChannel}
        >
          <span>
            {isLoading === true
              ? t('chat.createChannelPopup.button.creating')
              : t('chat.createChannelPopup.button.create')}
          </span>
        </button>
      }
    >
      <div className="title">{t('chat.createChannelPopup.title')}</div>
      <div className="white_con">
        <div className="channel_scroll">
          <div className="create_title">{t('chat.createChannelPopup.channelType.label')}</div>
          {/* radio 버튼은 한개만 선택 / 중복선택 불가 */}
          <div className="radio_div">
            <div className="form_wrap">
              <span className="form_cell form_radio">
                <input
                  type="radio"
                  id="radio-channel-type-text"
                  value={ChatChannelType.TEXT}
                  onChange={onChangeChannelType}
                  checked={channelType === ChatChannelType.TEXT}
                />
                <label htmlFor="radio-channel-type-text">
                  <span className="!mt-0.5">{t('chat.createChannelPopup.channelType.radio.text')}</span>
                </label>
              </span>
              {/* 2024.08.06 CBT QA 건으로 인한 미노출 처리 */}
              {/* <span className="form_cell form_radio">
                <input
                  type="radio"
                  id="radio-channel-type-video"
                  value={ChatChannelType.VIDEO}
                  onChange={onChangeChannelType}
                  checked={channelType === ChatChannelType.VIDEO}
                />
                <label htmlFor="radio-channel-type-video">
                  <span className="!mt-0.5">{t('chat.createChannelPopup.channelType.radio.video')}</span>
                </label>
              </span> */}
            </div>
          </div>

          <div className="create_title">{t('chat.createChannelPopup.channelName.label')}</div>
          {/* 채널이름은 필수입력값입니다. */}
          {/* 입력값을 충족하지 못했을 때 error class가 붙으며 하단 error_txt가 나옵니다. */}
          <div className={`form_wrap ${validateChannelName() ? 'error' : ''}`}>
            <span className="form_cell form_input input_md">
              <input
                type="text"
                placeholder={t('chat.createChannelPopup.channelName.placeholder')}
                value={channelName}
                onChange={onChangeChannelName}
                aria-describedby="channelNameError"
              />
              {/* 글자 수 체크 */}
              <span className="guide_text num">
                <span>{channelName.length}</span>/20
              </span>
            </span>
            {validateChannelName() && (
              <span id="channelNameError" className="error_txt msg">
                {t('chat.createChannelPopup.channelName.validation')}
              </span>
            )}
          </div>
          <div className="create_title">
            {t('chat.createChannelPopup.introduction.label')}{' '}
            <span>{t('chat.createChannelPopup.introduction.labelGuide')}</span>
          </div>
          <div className="textarea_div">
            {/* 'Welcome to this channel' 채널생성시 디폴트로 입력되는 값 / 유저가 따로 입력을 안할시 이 텍스트로 채널생성됩니다. */}
            <textarea
              placeholder={t('chat.createChannelPopup.introduction.placeholder')}
              value={introduction}
              onChange={onChangeIntroduction}
            />
            {/* 글자 수 체크 */}
            <span className="num">
              <span className="black">{introduction.length}</span>/200
            </span>
            {/* error시 나오는 error message */}
            {introduction.length > 0 && !(introduction.length >= 2 && introduction.length <= 200) && (
              <span className="error_txt msg">{t('chat.createChannelPopup.introduction.validation')}</span>
            )}
          </div>
          <div className="create_title">{t('chat.createChannelPopup.channelThumbnail.label')}</div>
          <div className="create_thum">
            {/* 썸네일 디폴트는 채널생성하는 유저썸네일입니다. */}
            <div className="create_thum_img">
              <img src={coverUrl} ref={refImg} />
            </div>
            <input
              type="file"
              style={{ display: 'none' }}
              ref={hiddenFileInputRef}
              onChange={onChangeFile}
              accept="image/*"
            />
            <div className="create_thum_btn">
              <button type="button" className="btn primary button_md" onClick={onChangeThumbnail}>
                <span>{t('chat.createChannelPopup.channelThumbnail.changeButton')}</span>
              </button>
              <ul className="guide popup">
                <li>{t('chat.createChannelPopup.channelThumbnail.imageSizeGuide')}</li>
              </ul>
            </div>
          </div>
          {channelType === ChatChannelType.TEXT && (
            <>
              <div className="create_title">{t('chat.createChannelPopup.userLimit.label')}</div>

              <div className="relative flex h-12 w-fit items-center rounded-2 border border-gray-300 px-1.5 py-3">
                <MinusIcon
                  className={classNames('cursor-pointer text-gray-500', {
                    'cursor-not-allowed text-gray-400': Number(participantsLimit) <= 2,
                    'hover:text-gray-800': Number(participantsLimit) > 2
                  })}
                  onClick={() => {
                    if (Number(participantsLimit) > 2) {
                      setParticipantsLimit((prev) => `${Number(prev) - 1}`);
                    }
                  }}
                />
                <input
                  value={participantsLimit}
                  className="w-[80px] text-center text-18 font-medium"
                  onChange={onChangeParticipantLimit}
                />
                <PlusIcon
                  className={classNames('cursor-pointer text-gray-500', {
                    'cursor-not-allowed text-gray-400': Number(participantsLimit) >= MAX_PARTICIPANTS_LIMIT,
                    'hover:text-gray-800': Number(participantsLimit) < MAX_PARTICIPANTS_LIMIT
                  })}
                  onClick={() => {
                    if (Number(participantsLimit) < MAX_PARTICIPANTS_LIMIT) {
                      setParticipantsLimit((prev) => `${Number(prev || 1) + 1}`);
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className="create_title">{t('chat.createChannelPopup.participationRestriction.label')}</div>
          {/* radio 버튼은 한개만 선택 / 중복선택 불가 */}
          <div className="radio_div">
            <div className="form_wrap">
              <span className="form_cell form_radio">
                <input
                  type="radio"
                  id="radio30"
                  value={ChannelPrivacyType.OPEN}
                  onChange={onChangeChannelPrivacyType}
                  checked={channelPrivacyType === ChannelPrivacyType.OPEN}
                />
                <label htmlFor="radio30">
                  <span className="!mt-0.5">
                    {t('chat.createChannelPopup.participationRestriction.radioButton.open')}
                  </span>
                </label>
              </span>
              <span className="form_cell form_radio">
                <input
                  type="radio"
                  id="radio31"
                  value={ChannelPrivacyType.PRIVATE}
                  onChange={onChangeChannelPrivacyType}
                  checked={channelPrivacyType === ChannelPrivacyType.PRIVATE}
                />
                <label htmlFor="radio31">
                  <span className="!mt-0.5">
                    {t('chat.createChannelPopup.participationRestriction.radioButton.private')}
                  </span>
                </label>
              </span>
            </div>
          </div>
          {/* 라디오버튼 눌렀을때 아래 정보텍스트가 노출됩니다. */}
          <ul className="guide popup">
            {channelPrivacyType === ChannelPrivacyType.OPEN && (
              <li>{t('chat.createChannelPopup.participationRestriction.guide.open')}</li>
            )}
            {channelPrivacyType === ChannelPrivacyType.PRIVATE && (
              <li>{t('chat.createChannelPopup.participationRestriction.guide.private')}</li>
            )}
            {channelPrivacyType === ChannelPrivacyType.ONLY_CLUB_MEMBERS && (
              <li>{t('chat.createChannelPopup.participationRestriction.guide.onlyClubMembers')}</li>
            )}
          </ul>
        </div>
        {selectedFile && <CropImageModal file={selectedFile} onCancel={onCropCancel} onSave={onCropSave} />}
      </div>
    </ModalContainer>
  );
};

export default ChannelCreate;
