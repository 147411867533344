import React, { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useVoteIframe, { voteUrl } from '../../../../hooks/useVoteIframe';
import { VoteInterface } from '../../../../libs/apis/response.type';
import { ResponseCommand, ResponseCommandMap } from '../../../../types/postMessageCommand';

import Ballon from 'components/jayden/ballon';

interface ChatPollMessageProps {
  isMe: boolean;
  vote: VoteInterface;
}

const ChatPollMessage: React.FC<ChatPollMessageProps> = (props) => {
  const { isMe, vote } = props;

  const navigate = useNavigate();

  const voteIframeRef = useRef<HTMLIFrameElement>(null);

  const onMeasureHeightSuccess = (event: MessageEvent) => {
    try {
      const height: number = event.data.data?.height;
      if (voteIframeRef.current != null) {
        voteIframeRef.current.style.height = `${height}px`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const commandMap = useMemo<ResponseCommandMap>(
    () => new Map([[ResponseCommand.MeasureHeightSuccess, onMeasureHeightSuccess]]),
    []
  );

  useVoteIframe({
    ref: voteIframeRef,
    commandMap,
    context: { voteMessageStatus: vote.status }
  });

  const handleClickVoteButton = () => {
    navigate(`poll/${vote.id}`);
  };

  return (
    <Ballon isMe={isMe} className="min-w-[300px] border border-gray-300 bg-white py-3">
      <div className="mb-2 text-20 font-semibold">Poll</div>
      <div className="mx-auto border-t border-gray-300 px-1 pt-3">
        <iframe className="w-full" ref={voteIframeRef} src={`${voteUrl}/chat-message/${vote.id}`} />
      </div>
      <div className="flex justify-end border-t border-t-gray-300 pt-3">
        <button
          className="flex h-10 w-[100px] cursor-pointer items-center justify-center rounded-1.5 border border-primary-200 bg-primary-100 outline-none hover:bg-primary-200 active:bg-primary-300"
          onClick={handleClickVoteButton}
        >
          <span className="text-14 font-medium text-white">Vote</span>
        </button>
      </div>
    </Ballon>
  );
};

export default ChatPollMessage;
