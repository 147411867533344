import { useMutation } from 'react-query';

import chatApi from 'apis/chat-api';

const useMessages = () => {
  const postMessageSendMutation = useMutation({
    mutationFn: chatApi.postMessagesGroupChannelIdSend
  });

  const postMessageReplyMutation = useMutation({
    mutationFn: chatApi.postMessagesGroupChannelIdParentMessageIdReply
  });

  const deleteMessageMutation = useMutation({
    mutationFn: chatApi.deleteMessagesGroupChannelIdMessageId
  });

  return {
    postMessageSendMutation,
    postMessageReplyMutation,
    deleteMessageMutation
  };
};

export default useMessages;
