import React from 'react';

import Ballon from 'components/jayden/ballon';
import { ChatMessage } from 'types/common';

type Props = {
  url: string;
  parentMessage?: ChatMessage;
  isMe: boolean;
};

const ChatGifMessage: React.FC<Props> = ({ url, parentMessage, isMe }) => {
  return (
    <>
      <Ballon isMe={isMe}>
        {!!parentMessage && (
          <>
            {/* 답글 원글 내용이 상단에 나옵니다. (글쓴이 / 내용 두 줄)  */}
            {/* 답글 원글 클릭 시 원글로 이동합니다.  */}
            <div className="text-12 font-medium text-secondary-100">{parentMessage.sender.nickname}</div>
            <span className="mb-2 line-clamp-2 text-ellipsis text-12 text-gray-500">{parentMessage.message}</span>
          </>
        )}
        <span className="relative block max-w-[300px] border-t border-gray-200 pt-2">
          <img src={url} />
        </span>
      </Ballon>
    </>
  );
};
export default ChatGifMessage;
