import { useEffect } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import { cretaWalletState, metamaskWalletState, multiCoinSendMapState } from 'components/wallet/recoil';
import { getHoldings } from 'components/wallet/web3';

/**
 * 코인전송에 사용되는 전역값 초기화
 */
export const useResetRecoil = () => {
  const resetMap = useResetRecoilState(multiCoinSendMapState);

  useEffect(() => {
    return () => {
      resetMap();
    };
  }, []);
};

/**
 * 크레타 자산 가져오기
 */
export const useGetBalanceCreta = (cretaAddress?: string) => {
  const [_, setCreta] = useRecoilState(cretaWalletState);

  useEffect(() => {
    if (!cretaAddress) return;

    getHoldings(cretaAddress)
      .then((response: { tokens: any[]; nfts: any[] }) => {
        setCreta(response);
      })
      .catch((e) => {
        console.log(`e`, e);
      });
  }, [cretaAddress]);
};

/**
 * 메타마스크 자산 가져오기
 */
export const useGetBalanceMetamask = (metaMaskAddress?: string) => {
  const [_, setMetamask] = useRecoilState(metamaskWalletState);

  useEffect(() => {
    if (!metaMaskAddress) return;

    getHoldings(metaMaskAddress)
      .then((response: { tokens: any[]; nfts: any[] }) => {
        setMetamask(response);
      })
      .catch((e) => {
        console.log(`e`, e);
      });
  }, [metaMaskAddress]);
};
