import { Crop } from 'react-image-crop';

const getBase64FromUrl = async (url: string): Promise<string | ArrayBuffer | null> => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const createImage = async (url: string) => {
  const base64EncodedData = await getBase64FromUrl(url);

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = base64EncodedData?.toString() || '';
  });
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc: HTMLImageElement,
  crop: Crop,
  rotation = 0
): Promise<{ blob: Blob; url: string } | null> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }
  const scaleX = imageSrc.naturalWidth / imageSrc.width;
  const scaleY = imageSrc.naturalHeight / imageSrc.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  // ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;
  const TO_RADIANS = Math.PI / 180;

  const rotateRads = rotation * TO_RADIANS;
  const centerX = imageSrc.naturalWidth / 2;
  const centerY = imageSrc.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(1, 1);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.translate(cropX, cropY);
  // ctx.drawImage(imageSrc, 0, 0, canvas.width, canvas.height);
  ctx.drawImage(imageSrc, cropX, cropY, crop.width * scaleX, crop.height * scaleY, 0, 0, canvas.width, canvas.height);

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }

      //   file.name = fileName;
      resolve({ blob, url: URL.createObjectURL(blob) });
    }, 'image/jpeg');
  });
}

export async function saveCropImage(
  crop: Crop,
  imgSrc: HTMLImageElement,
  rotation: number
): Promise<{ blob: Blob; url: string } | null> {
  try {
    const cropped = await getCroppedImg(imgSrc, crop, rotation);
    return cropped;
    // Blob 파일을 api parameter에 넣..
  } catch (error) {
    console.error(error);
    throw error;
  }
}
