import { type AxiosResponse } from 'axios';

import { Channel } from '../types/api.type';

import ApiRepository, { SuperClubUserInterface } from './ApiRepository';

export const getUserMe = async () => {
  const response = await ApiRepository.getUserMe();

  return response?.data?.data;
};

export async function getUsers(params?: any) {
  const response = await ApiRepository.getUsers(params);

  return response?.data?.data;
}

interface ResponseData {
  message: string;
  data: SuperClubUserInterface;
}

export async function getUser(id: string): Promise<SuperClubUserInterface> {
  const response: AxiosResponse<ResponseData> = await ApiRepository.getUser(id);

  return response?.data?.data;
}

// OpenChannel
export async function getOpenChannel(id: string) {
  const response = await ApiRepository.getOpenChannel(id);

  return response?.data?.data;
}

export async function getOpenChannels() {
  const response = await ApiRepository.getOpenChannels({});
  return response?.data?.data;
}

export async function getJoinOpenChannels() {
  const data = { is_joined: true };
  const response = await ApiRepository.getOpenChannels(data);
  return response?.data?.data;
}
export async function getOpenChannelUrl(url: string | undefined) {
  const data = { url: url };
  const response = await ApiRepository.getOpenChannels(data);

  return response?.data?.data[0];
}
export async function postOpenChannel(data: object) {
  const response = await ApiRepository.postOpenChannel(data);

  return response?.data?.data;
}

export async function postOpenChannelInvite(id: string) {
  const response = await ApiRepository.postOpenChannelInvite(id);
  return response?.data?.data;
}

export async function patchOpenChannel(id: string, data: object) {
  const response = await ApiRepository.patchOpenChannel(id, data);

  return response?.data?.data;
}

export async function deleteOpenChannelApi(data: Channel) {
  const response = await ApiRepository.deleteOpenChannel(data.id);

  return response;
}

// DirectChannel
export async function getDirectChannel(id: string) {
  const response = await ApiRepository.getDirectChannel(id);

  return response?.data?.data;
}

export async function getDirectChannels() {
  const response = await ApiRepository.getDirectChannels();

  return response?.data?.data;
}

export async function postDirectChannel(data: object) {
  const response = await ApiRepository.postDirectChannel(data);

  return response?.data?.data;
}

export async function patchDirectChannel(id: string, data: object) {
  const response = await ApiRepository.patchDirectChannel(id, data);

  return response?.data?.data;
}

export async function deleteDirectChannelApi(data: Channel) {
  const response = await ApiRepository.deleteDirectChannel(data.id);

  return response;
}
export async function getInvite(id: string) {
  const response = await ApiRepository.getInvite(id);

  return response?.data?.data;
}

export async function postInviteApproval(id: string) {
  const response = await ApiRepository.postInviteApproval(id);

  return response?.data?.data;
}

export async function postInviteRefusal(id: string) {
  const response = await ApiRepository.postInviteRefusal(id);

  return response?.data?.data;
}

export async function certificateServerUser(id: string) {
  const response = await ApiRepository.certificateServerUser(id);

  return response.data.data;
}
