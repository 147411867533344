import React from 'react';

import { ReactComponent as Bronze } from 'assets/images/level-caps/ic_level_bronze.svg';
import { ReactComponent as Diamond } from 'assets/images/level-caps/ic_level_diamond.svg';
import { ReactComponent as Gold } from 'assets/images/level-caps/ic_level_gold.svg';
import { ReactComponent as GrandMaster } from 'assets/images/level-caps/ic_level_grandmaster.svg';
import { ReactComponent as Legend } from 'assets/images/level-caps/ic_level_legend.svg';
import { ReactComponent as Master } from 'assets/images/level-caps/ic_level_master.svg';
import { ReactComponent as Normal } from 'assets/images/level-caps/ic_level_normal.svg';
import { ReactComponent as Novice } from 'assets/images/level-caps/ic_level_novice.svg';
import { ReactComponent as Platinum } from 'assets/images/level-caps/ic_level_platinum.svg';
import { ReactComponent as Silver } from 'assets/images/level-caps/ic_level_silver.svg';

export enum Grade {
  NOVICE = 'NOVICE',
  NORMAL = 'NORMAL',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  DIAMOND = 'DIAMOND',
  MASTER = 'MASTER',
  GRAND_MASTER = 'GRAND_MASTER',
  LEGEND = 'LEGEND'
}

const LevelCap: React.FC<{ grade: Grade }> = ({ grade }) => {
  switch (grade) {
    case 'NOVICE':
      return <Novice className="size-[32%]" />;
    case 'NORMAL':
      return <Normal className="size-[32%]" />;
    case 'BRONZE':
      return <Bronze className="size-[32%]" />;
    case 'SILVER':
      return <Silver className="size-[32%]" />;
    case 'GOLD':
      return <Gold className="size-[32%]" />;
    case 'PLATINUM':
      return <Platinum className="size-[32%]" />;
    case 'DIAMOND':
      return <Diamond className="size-[32%]" />;
    case 'MASTER':
      return <Master className="size-[32%]" />;
    case 'GRAND_MASTER':
      return <GrandMaster className="size-[32%]" />;
    case 'LEGEND':
      return <Legend className="size-[32%]" />;
    default:
      return <Novice className="size-[32%]" />;
  }
};

export default LevelCap;
