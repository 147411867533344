/**
 * 로그인 관련 스토리지
 */
export enum AuthStorage {
  // oauth에서 크레타 회원가입 후 슈퍼클럽 회원가입 전에 임시로 들고있는 세션토큰 키
  CRETA_TOKEN = 'cretaToken',
  ACCESS_TOKEN = 'accessToken_v2',
  REFRESH_TOKEN = 'refreshToken',

  // last access token
  LAST_TOKEN = 'lastAccessToken',

  // expiresAt
  EXPIRES_AT = 'expiresAt'
}
