import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import sendCoinApi from 'apis/send-coin';
import { CRETA_META } from 'components/wallet/constants';
import { numberUI } from 'components/wallet/util';
import { sleep } from 'libs/helper';
import useAlert from 'routers/modal/useAlert';
import useModalStack from 'routers/modal/useModalStack';

import { createApproveData, getGasPrice, getTransactionByHash, isWebView } from 'utils/wallet';

type ActionResult = { action: 'OK' | 'Cancel'; txhash: string; status: string };

type ToUser = { id: number; amount: string; address: string; walletType?: string };

type TransferState = { note: string } & ToUser;

const action = <T>(value: T) => {
  return value;
};

const METHODS = {
  WalletConnect: 'WalletConnect',
  WalletApproval: 'WalletApproval',
  WalletTransfer: 'WalletTransfer',
  WalletConfirmation: 'WalletConfirmation'
};

// polygon mainNet
const chainId = '0x89';

export const useWebViewWallet = () => {
  const webView = isWebView();
  const _queryClient = useQueryClient();
  const modalStack = useModalStack();
  const alert = useAlert();
  const { t } = useTranslation();

  /** ------------------------------------------------------------------------------
   * 
   * state
   * 
   ------------------------------------------------------------------------------ */
  // 런처 실행중일때 버튼 disabled 관리하는 상태
  const [isBusy, setIsBusy] = useState(false);

  /**
   * eventListener 수신 후 postMessage 당시의 상태를 보관하고 있는 상태
   */
  const stateRef = useRef<TransferState | Record<string, never>>({});
  const [state, setState] = useState<TransferState | Record<string, never>>({});

  // 상태를 업데이트하면서 `useRef`도 함께 갱신
  const updateState = (newState: TransferState | Record<string, never>) => {
    stateRef.current = newState; // 최신 상태를 ref에 저장
    setState(newState); // 상태 업데이트
  };

  useEffect(() => {
    console.log('================useEffect================');
    console.log(state);
    console.log(stateRef.current);
    console.log('================useEffect================');
  }, [state]);

  /**
   * 지갑 연결
   */
  const connectCreta = async (txhash: string) => {
    try {
      // await patchUserMeWallet({
      //   payload: {
      //     wallet_address_creta: txhash
      //   }
      // });

      console.log(txhash);

      // queryClient.invalidateQueries(userQueryKey.myWalletAddress());
      // queryClient.invalidateQueries(userQueryKey.userMe);

      // TODO: error
    } catch (e) {
      console.log(e);
    }
  };

  /** ------------------------------------------------------------------------------
   * 
   * addEventListener
   * 
   ------------------------------------------------------------------------------ */
  useEffect(() => {
    if (!webView) return;

    const handler = async (message: MessageEvent) => {
      const { data } = message;

      switch (data.method) {
        case METHODS.WalletConnect: {
          const { result } = data;
          const res = action<ActionResult>(result);
          await connectCreta(res.txhash);
          break;
        }

        // 안쓸듯
        case METHODS.WalletApproval:
          break;

        case METHODS.WalletTransfer: {
          const { result } = data;

          const res = action<ActionResult>(result);
          if (res.action === 'OK') {
            const receipt = await getTransactionByHash(res.txhash);

            try {
              // postMessage 당시 state
              const postState = action<Partial<TransferState>>(stateRef.current);

              const payload = {
                status: receipt.status ? 'COMPLETED' : 'FAILED',
                token_address: CRETA_META.contractAddress,
                token_type: 'CRETA',
                wallet_address: receipt.from,
                gas_price: String(numberUI(receipt.gasUsed)),
                transaction_fee: String(
                  Number(numberUI(receipt.gasUsed)) * Number(numberUI(receipt.effectiveGasPrice))
                ),
                token_icon_url: CRETA_META.icon,
                note: postState.note,
                transaction_hash: res.txhash,
                wallet_type: 'CRETA' as const,
                chain_id: chainId,
                transfers: [
                  {
                    receiver: postState.id,
                    amount: postState.amount,
                    wallet_address: postState.address,
                    wallet_type: postState.walletType
                  }
                ]
              };

              const resultSC = await sendCoinApi.postCoinTransferGroups(payload);
              console.log(`resultSC`, resultSC);

              alert.open({
                contents: t('setting.wallet.send.success.transfer'),
                submitOnly: true,
                disableBackdropClick: true,
                submit: () => modalStack.clear()
              });

              // state, ref 초기화
              updateState({});
            } catch (e) {
              console.log('superclub server error', e);
              alert.open({
                contents: t('setting.wallet.send.error.transfer'),
                submitOnly: true
              });
              updateState({});
            } finally {
              setIsBusy(false);
            }
          }
          break;
        }

        // 안쓸듯
        case METHODS.WalletConfirmation:
          break;
      }
    };

    // message 이벤트 리스너 등록
    window?.addEventListener('message', handler);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window?.removeEventListener('message', handler);
    };
  }, []);

  /** ------------------------------------------------------------------------------
   * 
   * postMessage
   * 
   ------------------------------------------------------------------------------ */
  /**
   * connect wallet
   */
  const onConnectWallet = () => {
    if (!webView) return;

    const message = {
      method: METHODS.WalletConnect,
      id: 'e8b6f18c-0892-409c-ac0e-65ab1d3feaa6',
      jsonrpc: '2.0',
      params: {}
    };

    window.parent?.postMessage(message, '*');
  };

  // send coin
  const onSendCoin = async (args: { from: string; toUser: ToUser; amount: string; note: string }) => {
    setIsBusy(true);

    const { amount, from, toUser, note } = args;
    updateState({ ...toUser, note });

    const data = createApproveData(from, toUser.address, amount);

    const message = {
      method: METHODS.WalletTransfer,
      id: 'e8b6f18c-0892-409c-ac0e-65ab1d3feaa6',
      jsonrpc: '2.0',
      params: {
        from,
        to: CRETA_META.contractAddress, // wallet address 가 아니라 contract address
        data,
        gas: 500000,
        gasPrice: await getGasPrice(),
        type: METHODS.WalletTransfer,
        chainId
      }
    };

    await sleep(1);

    window.parent?.postMessage(message, '*');
  };

  return {
    onConnectWallet,
    onSendCoin,
    isBusy
  };
};
