import { atom } from 'recoil';

import { ChatMessage } from '../../types/common';

export type MessateInputState = {
  isOpenedEmojiPopup: boolean;
  isOpenedMemberSearchPopup: boolean;
  isReplyMode: boolean;
  parentMessage?: ChatMessage | null;
  messageText: string;
  parentMessageId?: number | null;
};

const messageInputState = atom<MessateInputState>({
  key: 'messageInputState',
  default: {
    parentMessage: null,
    isOpenedEmojiPopup: false,
    isOpenedMemberSearchPopup: false,
    isReplyMode: false,
    messageText: '',
    parentMessageId: null
  }
});

export default messageInputState;
