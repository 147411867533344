import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import classNames from 'components/styled/util';

const ModalBackdrop = ({
  children = <></>,
  close = () => {},
  disableBackdropClick = false,
  backdropTransparent = false,
  backdropBlur = false
}) => {
  const { pathname } = useLocation();
  const pathRef = useRef(pathname);

  const handleClickBackdrop = () => {
    if (disableBackdropClick) return;
    close();
  };

  useEffect(() => {
    if (pathname !== pathRef.current) {
      close();
    }
  }, [pathname]);

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center">
      <div
        className={classNames('absolute inset-0 z-[-1]', {
          'bg-black/40': !backdropTransparent,
          'backdrop-filter backdrop-blur-[30px]': backdropBlur
        })}
        onClick={handleClickBackdrop}
      />
      {children}
    </div>
  );
};

export default ModalBackdrop;
