import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { useOnClickOutside } from 'usehooks-ts';

import { StyledColumn } from '../../../components/styled/styled';
import useCurrentUser, { UserStatus } from '../../../hooks/useCurrentUser';
import api from '../../../libs/apis';
import { getIsMaster, getIsStaff } from '../../../libs/helper';
import { fetchUser } from '../../../libs/sendbird';

import BlockButton from './BlockButton';
import InviteButton from './InviteButton';
import MiniProfileLeftButton, { FriendRequestStatus } from './MiniProfileLeftButton';
import MiniprofileMemo from './MiniprofileMemo';
import MoreOptionButton from './MoreOptionButton';
import PermitButton from './PermitButton';
import SendCoinButton from './SendCoinButton';

import chatApi from 'apis/chat-api';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useUserInfo from 'hooks/useUserInfo';

const MiniProfile = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { currentUser, updateUserState } = useCurrentUser();
  const { data: channel } = useCurrentChannel();
  const [isOpenedStatusMenu, setIsOpenedStatusMenu] = useState(false);
  const [miniProfileUser, setMiniProfileUser] = useState<User | null>(null);

  const [isMoreOptionCollapsed, setIsMoreOptionCollapsed] = useState(false);

  const { t } = useTranslation();

  const { data: userInfo, isLoading } = useUserInfo(userId);

  const isMaster = useMemo(() => getIsMaster({ channel, user: currentUser as User }), [currentUser, channel]);
  const isStaff = useMemo(() => getIsStaff({ channel, user: currentUser as User }), [currentUser, channel]);

  const isMyProfile = useMemo(
    () => miniProfileUser?.userId === currentUser?.userId,
    [miniProfileUser?.userId, currentUser?.userId]
  );

  const userStatusRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(userStatusRef, () => setIsOpenedStatusMenu(false));

  const onChangeStatus = useCallback(
    (status: UserStatus) => () => {
      updateUserState(status);
      setMiniProfileUser((prev) => ({ ...prev, metaData: { ...prev?.metaData, status } }) as any);
      setIsOpenedStatusMenu(false);
    },
    []
  );

  const onBackDrop = useCallback(() => {
    navigate(-1);
  }, []);

  const onDM = useCallback(async () => {
    if (!miniProfileUser) {
      return;
    }

    const { data: dmChannel } = await chatApi.postDmChannels({ toUserId: Number(miniProfileUser.userId) });

    if (dmChannel) {
      navigate(`/channel/${dmChannel?.channelUrl}`);
      return;
    }
  }, [miniProfileUser]);

  // const onAddFriend = useCallback(() => {}, []);

  useEffect(() => {
    async function init() {
      if (!userId) {
        return;
      }
      const user = await fetchUser(userId);
      setMiniProfileUser(user);
    }

    init();
  }, [userId]);

  const updateDescriptionMutation = useMutation({
    mutationFn: api.patchUserById,
    onSuccess: (data) => {
      console.log(data);
    }
  });

  if (!currentUser) return null;
  if (!miniProfileUser) return null;
  if (isLoading) return null;
  if (!userInfo) return null;

  const sliceWordWhenMoreThan12Letters = (str: string) => {
    if (str.length > 12) {
      return str.slice(0, 12) + '...';
    }
    return str;
  };

  return (
    <>
      <div id="modal" onClick={onBackDrop}></div>
      <div id="mini_profile" className="modal popup">
        <div className="mini_profile_top">
          <img src={userInfo?.banner_image_url} />
        </div>
        <div className="mini_profile_con">
          {/* PROFILE_IMAGE & BADGE_IMAGE */}
          <div className="mini_profile_photo">
            <div id="profile_info">
              <div className={`profile_level ${userInfo?.grade_title?.toLowerCase?.()}`} />
              <div className={`profile_div ${userInfo?.ring_color?.toLowerCase?.()}`}>
                <div className="profile_bg">
                  <img src={userInfo?.profile_image_url} />
                </div>
              </div>
              {userInfo?.badge_image_url && (
                <div className="profile_badge ">
                  <img src={userInfo?.badge_image_url} />
                </div>
              )}
            </div>
          </div>

          {/* NAME & PING (STATUS) */}
          <div className="mini_profile_name">
            <span className="nick">{sliceWordWhenMoreThan12Letters(userInfo?.username)}</span>
            <span className="num">{`#${userInfo?.hash}`}</span>

            {!isMyProfile && (
              <div
                onClick={onDM}
                className="ml-1 flex size-6 shrink-0 cursor-pointer items-center justify-center rounded-full bg-primary-100"
              >
                <SendIcon className="text-white" />
              </div>
            )}
            <ul ref={userStatusRef} className="status_list" style={{ display: isOpenedStatusMenu ? 'block' : 'none' }}>
              <li className="select">
                <button type="button" className="status_select on" onClick={onChangeStatus(UserStatus.ONLINE)}>
                  <span>{t('chat.miniProfile.ping.online')}</span>
                </button>
              </li>
              <li>
                <button type="button" className="status_select dis" onClick={onChangeStatus(UserStatus.DO_NOT_DISTURB)}>
                  <span>{t('chat.miniProfile.ping.disturb')}</span>
                </button>
              </li>
              <li>
                <button type="button" className="status_select away" onClick={onChangeStatus(UserStatus.AWAY)}>
                  <span>{t('chat.miniProfile.ping.away')}</span>
                </button>
              </li>
              <li>
                <button type="button" className="status_select game" onClick={onChangeStatus(UserStatus.GAMING)}>
                  <span>{t('chat.miniProfile.ping.gaming')}</span>
                </button>
              </li>
              <li>
                <button type="button" className="status_select auto" onClick={onChangeStatus(UserStatus.ONLINE)}>
                  <span>{t('chat.miniProfile.ping.auto')}</span>
                </button>
              </li>
            </ul>
          </div>

          {/* LABELS */}
          <div className="mt-2 flex justify-center gap-1">
            {userInfo?.labels?.map((label) => (
              <img className="size-5 rounded-full object-cover" key={label?.id} src={label?.image_url} alt="label" />
            ))}
          </div>

          {/* COUNT INFO */}
          <ul className="mini_profile_total">
            <li>
              <dl>
                <dt>{userInfo?.friend_count}</dt>
                <dd>{t('chat.miniProfile.friends')}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{userInfo?.heart_count}</dt>
                <dd>{t('chat.miniProfile.hearts')}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>{userInfo?.respect_count}</dt>
                <dd>{t('chat.miniProfile.respects')}</dd>
              </dl>
            </li>
          </ul>

          <textarea
            className="mini_profile_textarea !w-0 break-all"
            defaultValue={userInfo?.description}
            disabled={!isMyProfile}
            onBlur={(e) => {
              updateDescriptionMutation.mutate({
                id: Number(userId),
                data: { description: e.target.value }
              });
            }}
          />
          {/* 나와 공통된 친구가 1명 이상일 때 */}
          {/* {isMyProfile === false && (
            <div className="mini_profile_friends">
              <div className="img1">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img2">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img3">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img4">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img5">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img6">
                <img src="/img/com/user thumb.png" />
              </div>
              <div className="img7">
                <img src="/img/com/user thumb.png" />
              </div>

              <div
                className="img8 over" // 8명 이상일 경우 div에 over class가 추가됩니다.
              >
                <img src="/img/com/user thumb.png" />
              </div>
            </div>
          )} */}

          {!isMyProfile && (
            <div style={{ marginTop: 8 }}>
              <MiniprofileMemo userId={userInfo.id} memo={userInfo.nickname ?? ''} />
            </div>
          )}

          {/* 내가 타인 프로필에 남기는 나만 볼 수 있는 메모입니다.  */}
          {/* 클릭 시 input 상태로 바뀌며 입력가능합니다. */}
          {/* 메모 삭제 또는 입력값이 없을 시 done class 대신 default로 바뀝니다. */}
          <div className="mini_profile_btn">
            {isMyProfile === false && (
              <div className="flex w-full items-center gap-2">
                <MiniProfileLeftButton
                  userId={userInfo?.id}
                  isMyProfile={isMyProfile}
                  friendRequestStatus={(userInfo?.friend_request_status as FriendRequestStatus) || null}
                  requestId={userInfo?.friend_request_id}
                />
                {channel && <SendCoinButton user={userInfo} />}
              </div>
            )}
            {isMyProfile === true && (
              <>
                <button
                  type="button"
                  className="home"
                  onClick={() => {
                    if (!currentUser || !currentUser?.userId) return;

                    const url = `${process.env.REACT_APP_MAIN_URL}/sns/${currentUser.userId}`;
                    window.open(url, '_blank');
                  }}
                >
                  <span>{t('chat.miniProfile.button.myHome')}</span>
                </button>
                <button
                  type="button"
                  className="msg acc"
                  onClick={() => {
                    if (!currentUser || !currentUser?.userId) return;

                    const url = `${process.env.REACT_APP_MAIN_URL}/user-profile/settings/privacy-checkup`;
                    window.open(url, '_blank');
                  }}
                >
                  <span>{t('chat.miniProfile.button.account')}</span>
                </button>
              </>
            )}
          </div>
          {!isMyProfile && (
            <StyledColumn>
              {isMoreOptionCollapsed && (
                <div className="mt-4 flex justify-between px-8">
                  <BlockButton user={userInfo} />
                  <InviteButton user={miniProfileUser} />
                  <PermitButton user={miniProfileUser} disabled={!(isMaster || isStaff)} />
                </div>
              )}
              <MoreOptionButton
                isCollapsed={isMoreOptionCollapsed}
                onClick={() => setIsMoreOptionCollapsed(!isMoreOptionCollapsed)}
              />
            </StyledColumn>
          )}
        </div>
      </div>
    </>
  );
};

export default MiniProfile;
