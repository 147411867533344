import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { isAxiosError } from 'axios';
import { debounce } from 'lodash';
import { useOnClickOutside } from 'usehooks-ts';

import chatApi from 'apis/chat-api';
import { ReactComponent as MemberPlusIcon } from 'assets/icons/ic-chat-member-plus.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic-check.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/ic-copy.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import Button from 'components/jayden/Button';
import Input from 'components/jayden/Input';
import Loader from 'components/jayden/Loader';
import Tooltip from 'components/jayden/Tooltip';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';

import useAlert from 'hooks/useAlert';
import useGroupChannel from 'hooks/useGroupChannel';

import { getChannelIdByChannelUrl } from 'utils/common';

const ChatInviteButton = () => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const alert = useAlert();

  const { channelUrl } = useParams<{ channelUrl: string }>();

  const { fetchChannels } = useGroupChannel();

  const [keyword, setKeyword] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const inviteRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useInfiniteQuery({
    queryKey: ['channels', channelUrl, 'inviteableUserList', keyword],
    queryFn: ({ pageParam = 1 }) =>
      chatApi.getGroupChannelsInviteInviteableUsers({
        channelId: getChannelIdByChannelUrl(channelUrl || ''),
        page: pageParam,
        page_size: 9999,
        search: keyword.trim()
      }),

    getNextPageParam: (lastPage) => {
      return Number(lastPage.data.currentPage || '1') + 1;
    }
  });

  const { mutateAsync: inviteUser, isLoading: isInviting } = useMutation({
    mutationFn: chatApi.postGroupChannelsInviteUserId
  });

  const inviteableUserList = useMemo(() => {
    return data?.pages.flatMap((page) => page.data.users) || [];
  }, [data]);

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const handleKeywordChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value.trim());
  }, 300);

  const handleClickInvite = async (item: any) => {
    try {
      if (!channelUrl) throw new Error('Cannot find channel Info');
      if (isInviting) return;

      await inviteUser({
        channelId: getChannelIdByChannelUrl(channelUrl),
        userId: String(item.id)
      });

      await qc.invalidateQueries({
        queryKey: ['channel', channelUrl]
      });

      await fetchChannels();
    } catch (error) {
      const content = isAxiosError(error) ? error.response?.data.message : (error as Error).message;
      alert.show({
        content,
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: alert.close
          }
        ]
      });
    }
  };

  useOnClickOutside(inviteRef, () => setShowPopup(false));

  return (
    <div ref={inviteRef}>
      <button
        type="button"
        className="group relative flex size-9 items-center justify-center rounded-2 data-[open=true]:bg-slate-default"
        onClick={handleButtonClick}
        data-open={showPopup}
      >
        <MemberPlusIcon className="text-white opacity-50 group-hover:opacity-100" />
        {!showPopup && (
          <Tooltip position="bottom">
            <span>{t('chat.channelHeader.tooltip.invite')}</span>
          </Tooltip>
        )}
      </button>

      {showPopup && (
        <div className="absolute right-2 top-[76px] z-10 flex w-[262px] flex-col rounded-3 border border-gray-300 bg-white shadow-md">
          <div className="p-3">
            <Input
              className="peer transition-all"
              type="text"
              placeholder={t('chat.menu.search.placeholder')}
              // value={keyword}
              onChange={handleKeywordChange}
              suffix={
                <SearchIcon className="absolute right-3 top-1/2 inline-block size-6 -translate-y-1/2 cursor-pointer text-gray-500 transition-all peer-focus:text-black-body" />
              }
            />
          </div>

          {!!keyword && isLoading && (
            <div className="relative flex h-[125px] items-center justify-center">
              <Loader />
            </div>
          )}

          {!isLoading && (
            <div className="max-h-[418px] overflow-y-auto">
              {/* 검색 결과 없을 때 */}
              {!inviteableUserList && (
                <div className="flex h-[125px] flex-col items-center justify-center gap-1 text-gray-600">
                  <img src="/img/com/img_unsmile3_gr.png" alt="no result" className="size-[60px]" />
                  <span className="text-18 font-bold text-gray-300">{t('chat.header.invite.noResult')}</span>
                </div>
              )}

              {/* 검색 결과 있을 때 */}
              {inviteableUserList?.length > 0 && (
                <ul className="mb-1.5 max-h-[200px] overflow-y-auto">
                  {inviteableUserList.map((item: any) => {
                    return (
                      <li key={item.id} className="flex h-10 justify-between py-1.5 pl-4 pr-3">
                        <div className="flex items-center gap-1.5">
                          <ProfileAvatar
                            userInfo={{
                              profile_image_url: item.profile_image_url,
                              ring_color: item.ring_color,
                              grade_title: item.grade_title,
                              badge_image_url: item.badge_image_url
                            }}
                            size={28}
                          />
                          <div className="flex max-w-[124px] text-13">
                            <div className="truncate font-medium text-black-title">
                              {/* // 키워드 하이라이팅 */}
                              {item.username?.split(keyword).map((text: string, index: number) => (
                                <span key={index}>
                                  {text}
                                  {index !== item.username?.split(keyword).length - 1 && (
                                    <span className="text-point-100">{keyword}</span>
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>
                        </div>

                        <Button
                          size="xs"
                          color="default"
                          className="flex h-7 items-center justify-center"
                          onClick={() => handleClickInvite(item)}
                        >
                          <CheckIcon className="size-4" />
                          {t('chat.header.invite.button')}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}

          <div className="mx-4 border-t border-t-gray-200">
            <div className="mt-3 text-13 text-gray-700">{t('chat.inviteLink.title')}</div>

            <div className="mt-1" />
            <Input
              className="select-all rounded-2 border-gray-300 text-13 text-gray-700"
              disabled
              value={`${process.env.REACT_APP_MAIN_URL}/invite/${channelUrl}`}
              suffix={
                <CopyIcon
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_MAIN_URL}/invite/${getChannelIdByChannelUrl(channelUrl)}`
                    );
                    alert.show({
                      content: t('chat.alert.linkCopy.contents'),
                      buttons: [
                        {
                          title: t('common.button.confirm'),
                          onClick: alert.close
                        }
                      ]
                    });
                  }}
                  className="absolute right-3 top-1/2 inline-block size-5 -translate-y-1/2 cursor-pointer text-gray-500 transition-all peer-focus:text-black-body"
                />
              }
            />
            <div className="h-4" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatInviteButton;
