import { useQuery } from 'react-query';

import userApi from 'apis/user-api';
import queryKeys from 'libs/apis/queryKeys';

const useUserInfo = (userId?: string) =>
  useQuery({
    queryKey: queryKeys.user.getItem(userId as string),
    queryFn: () => userApi.getUserById({ userId: Number(userId) }),
    select: (res) => res.data,
    enabled: !!userId
  });

export default useUserInfo;
