import { useQuery } from 'react-query';

import chatApi from 'apis/chat-api';

export type GetChannelsParams = {
  search?: string;
};

export const useGetClubChannelsJoinable = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'club', 'joinable', { ...params }],
    queryFn: () => chatApi.getClubChannelsJoinable({ ...params }),
    select: (response) => response.data,
    keepPreviousData: true
  });
};

/**
 * 객체형식이라 select 한번더
 */
export const useGetClubChannelsJoined = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'club', 'joined', { ...params }],
    queryFn: () => chatApi.getClubChannelsJoined({ ...params }),
    select: (response) => response.data.channels,
    keepPreviousData: true
  });
};

/**
 * 객체형식이라 select 한번더
 */
export const useGetDmChannelsJoined = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'dm', 'joined', { ...params }],
    queryFn: () => chatApi.getDmChannelsJoined({ ...params }),
    select: (response) => response.data.channels,
    keepPreviousData: true
  });
};

/**
 * 객체형식이라 select 한번더
 */
export const useGetGroupChannelsJoinable = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'group', 'joinable', { ...params }],
    queryFn: () => chatApi.getGroupChannelsJoinable({ ...params }),
    select: (response) => response.data.channels,
    keepPreviousData: true
  });
};

/**
 * 객체형식이라 select 한번더
 */
export const useGetGroupChannelsJoined = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'group', 'joined', { ...params }],
    queryFn: () => chatApi.getGroupChannelsJoined({ ...params }),
    select: (response) => response.data.channels,
    keepPreviousData: true
  });
};

/**
 * 객체형식이라 select 한번더
 */
export const useGetGroupChannelsAllJoined = (params: GetChannelsParams) => {
  return useQuery({
    queryKey: ['channels', 'all', 'joined', { ...params }],
    queryFn: () => chatApi.getGroupChannelsAllJoined({ ...params }),
    select: (response) => response.data.channels,
    keepPreviousData: true
  });
};
