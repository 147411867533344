import React, { useCallback, useMemo } from 'react';

import { downloadURI } from '../../../../libs/helper';
import { UploadToS3Response } from '../../../../libs/s3';
import { ChatMessage } from '../../../../types/common';

import Ballon from 'components/jayden/ballon';
import classNames from 'components/styled/util';

import useCurrentUser from 'hooks/useCurrentUser';

type Props = {
  message: ChatMessage;
  parentMessage?: ChatMessage;
};

const ChatFileMessage: React.FC<Props> = ({ message }) => {
  const parsedMessage = useMemo<UploadToS3Response>(() => {
    try {
      if (message.data) return JSON.parse(message.data)[0];
      const parsed = JSON.parse(message.message);
      return parsed[0];
    } catch {
      return {};
    }
  }, [message.message]);

  const onDownload = useCallback(() => {
    downloadURI(parsedMessage.cloudFrontUrl, parsedMessage.orgFileName);
  }, [parsedMessage]);

  const { currentUser } = useCurrentUser();
  const isMe = currentUser?.userId === message.sender.userId;

  if (!parsedMessage) {
    return null;
  }

  return (
    <>
      <Ballon isMe={isMe}>
        <div className="flex items-center">
          <div
            className={classNames(
              'relative inline-flex min-h-10 max-w-[358px] items-center break-all text-13 text-black-title'
            )}
          >
            {parsedMessage.fileName}
          </div>
          <button type="button" className="relative ml-5 size-8 rounded-4 bg-gray-200" onClick={onDownload}>
            <img
              src="/img/ico/ic_download_bk.png"
              className="absolute left-1/2 top-1/2 size-6 -translate-x-1/2 -translate-y-1/2"
            />
            <span className="a11y">파일다운</span>
          </button>
        </div>
      </Ballon>
    </>
  );
};
export default ChatFileMessage;
