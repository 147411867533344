import React, { PropsWithChildren } from 'react';

import classNames from 'components/styled/util';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * 버튼 크기
   * @default "md"
   *
   * xs: 패딩에 맞춘 최소단위 (height 지정 없음)
   * sm: 40px
   * md: 48px
   * lg: 52px
   * xl: 56px
   *
   */
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'google'
    | 'apple'
    | 'facebook'
    | 'twitter'
    | 'point'
    | 'success'
    | 'warning'
    | 'error';
  outline?: boolean;
  icons?: React.ReactNode;

  center?: boolean;
}

const BUTTON_CLASS = {
  SIZE: {
    xs: 'w-fit rounded-1.5 px-2.5 py-2 text-13',
    sm: 'h-10 w-fit rounded-1.5 text-14',
    md: 'h-12 w-fit rounded-2 px-3.5 text-15',
    lg: 'h-[52px] w-fit rounded-2.5 px-4 text-17',
    xl: 'text-20 h-14 w-fit rounded-3 px-5'
  },
  COLOR: {
    default: 'border-gray-500 bg-gray-100 text-black-title hover:bg-gray-200 active:bg-gray-300',
    primary: 'border-primary-200 bg-primary-100 text-white hover:bg-primary-200 active:border-primary-300',
    secondary: 'border-secondary-200 bg-secondary-100 text-white hover:bg-secondary-200 active:border-secondary-300',
    point: 'border-point-200 bg-point-100 text-white hover:bg-point-200 active:border-point-300',
    success: 'border-success-200 bg-success-100 text-white hover:bg-success-200 active:border-success-300',
    warning: 'border-warning-200 bg-warning-100 text-black-body hover:bg-warning-200 active:border-warning-300',
    error: 'border-error-200 bg-error-100 text-white hover:bg-error-200 active:border-error-300',
    google: 'border-gray-400 bg-white relative text-gray-700 hover:bg-gray-200',
    apple: 'border-black bg-black relative text-white hover:bg-[#393A3C]',
    facebook: 'border-[#304d8a] bg-[#304d8a] relative text-white hover:bg-[#1F386C]',
    twitter: 'border-[#55acee] bg-[#55acee] relative text-white hover:bg-[#2F96E5]'
  },
  OUTLINE: {
    default: 'border-gray-500 bg-white text-black-title hover:bg-gray-100 active:bg-gray-200',
    primary: 'border-primary-100 bg-white text-primary-100 hover:bg-gray-100 active:bg-gray-200',
    secondary: 'border-secondary-100 bg-white text-secondary-100 hover:bg-gray-100 active:bg-gray-200',
    point: 'border-point-100 bg-white text-point-100 hover:bg-gray-100 active:bg-gray-200',
    success: 'border-success-100 bg-white text-success-100 hover:bg-gray-100 active:bg-gray-200',
    warning: 'border-warning-100 bg-white text-warning-100 hover:bg-gray-100 active:bg-gray-200',
    error: 'border-error-100 bg-white text-error-100 hover:bg-gray-100 active:bg-gray-200',
    google: 'border-gray-400 bg-white relative text-gray-700 hover:bg-gray-200',
    apple: 'border-black bg-black relative text-white hover:bg-[#393A3C]',
    facebook: 'border-[#304d8a] bg-[#304d8a] relative text-white hover:bg-[#1F386C]',
    twitter: 'border-[#55acee] bg-[#55acee] relative text-white hover:bg-[#2F96E5]'
  }
};

const Button = (props: PropsWithChildren<ButtonProps>) => {
  const {
    type = 'button',
    size = 'md',
    color = 'default',
    outline = false,
    className,
    children,
    icons,
    onClick,
    disabled = false,
    center,
    ...restProps
  } = props;

  return (
    <button
      {...restProps}
      type={type}
      onClick={onClick}
      className={classNames(
        'border transition-all duration-300 disabled:cursor-not-allowed',
        [BUTTON_CLASS.SIZE[size]],
        {
          'disabled:border-gray-400 disabled:bg-gray-400 disabled:text-white': !outline,
          'disabled:border-gray-500 disabled:bg-white disabled:text-gray-400': outline
        },
        {
          [BUTTON_CLASS.COLOR[color]]: !outline,
          [BUTTON_CLASS.OUTLINE[color]]: !!outline
        },
        {
          'flex justify-center items-center': center
        },
        className
      )}
      disabled={disabled}
    >
      {icons && <div className="absolute left-4 top-1/2 size-6 -translate-y-1/2">{icons}</div>}
      {children}
    </button>
  );
};

export default Button;
