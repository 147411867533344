import { User } from '@sendbird/chat';
import { atom } from 'recoil';

import { Optional } from 'types/utils.type';

export type UserState = Optional<User>;

const userState = atom<UserState | null>({
  key: 'userState',
  default: null
});

export default userState;
