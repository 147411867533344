import { Member } from '@sendbird/chat/groupChannel';
import { atom } from 'recoil';

export type MembersState = {
  [key: string]: Member;
};

const membersState = atom<MembersState>({
  key: 'membersState',
  default: {}
});

export default membersState;
