import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserMessage } from '@sendbird/chat/message';
import { useRecoilValueLoadable } from 'recoil';

import { downloadURI, getIsMaster, getIsStaff } from '../../../libs/helper';
import { UploadToS3Response } from '../../../libs/s3';
import noticesSelector from '../../../store/selectors/noticesSelector';
import { CustomMessageType } from '../../../types/common';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ic-chevron-down.svg';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useDateFormatter from 'hooks/useDateFormatter';

const FileNotice = ({ message }: { message: string }) => {
  const parsedMessage = JSON.parse(message)[0] as UploadToS3Response;

  const onDownload = useCallback(() => {
    downloadURI(parsedMessage.cloudFrontUrl, parsedMessage.orgFileName);
  }, [parsedMessage]);

  return <button onClick={onDownload}>{parsedMessage.orgFileName}</button>;
};

const ChatRoomInfoNoticeList = () => {
  const { dateFormatLong } = useDateFormatter();

  const { data: channel, url } = useCurrentChannel();

  const [isExpanded, setIsExpanded] = useState(false);
  const { state, contents } = useRecoilValueLoadable(noticesSelector(url));
  const [messages, setMessages] = useState<UserMessage[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (state === 'hasError') {
      return;
    }

    if (state === 'loading') {
      return;
    }

    setMessages(contents);
  }, [state, contents]);

  useEffect(() => {
    setIsExpanded(false);
  }, [url]);

  return (
    <>
      <div className="notice flex flex-col gap-2">
        <div className="flex items-center gap-1.5">
          <span className="text-13 font-medium text-gray-600">
            {t('chat.channelInfo.notice.label')} - {messages.length}
          </span>
          <button
            type="button"
            className="size-6 p-1"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <ChevronDownIcon
              className={classNames('size-4 text-gray-600 transition-all hover:text-black-title', {
                'rotate-180': isExpanded
              })}
            />
          </button>
        </div>

        <div className="flex flex-col gap-2">
          {/* 채팅방 공지 펼쳤을 때, 시스템공지가 생겼을 때 최상단에 노출 */}
          {/* 시스템공지에는 system class */}
          {(!isExpanded ? messages.slice(0, 1) : messages).map((message) => {
            const isMaster = getIsMaster({ channel, user: message.sender });
            const isStaff = getIsStaff({ channel, user: message.sender });
            const isSystem = message.customType === CustomMessageType.SYSTEM;

            return (
              <div
                className={classNames('rounded-3 bg-gray-100 px-4 py-3.5', { system: isSystem })}
                key={message.messageId}
              >
                <div className="flex items-center justify-between">
                  {/* 방장이 쓴 공지일 경우 master class */}
                  <div
                    className={classNames('inline-flex items-center', {
                      'text-primary-100': isMaster,
                      'text-blue-like': isStaff
                    })}
                  >
                    {isSystem !== true && (
                      <>
                        <img className="size-7 shrink-0 rounded-full" src={message.sender.profileUrl} />
                        <span className="ml-2 text-13">{message.sender.nickname}</span>
                      </>
                    )}
                    {isSystem === true && (
                      <>
                        <div className="size-7 rounded-full bg-[#242a3c]">
                          <img
                            className="inline-block size-5 bg-[length:20px_20px] bg-center bg-no-repeat"
                            src={`${process.env.PUBLIC_URL}/img/ico/ic_info_sm_wh.png`}
                          />
                        </div>
                        <span className="ml-2 text-13">Notification</span>
                      </>
                    )}
                  </div>
                  <div className="inline-flex items-center">
                    <div className="text-12 text-gray-500">{dateFormatLong(message.createdAt)}</div>
                  </div>
                </div>
                <div className="mt-2 break-all text-14 text-black-body">
                  {message.customType === CustomMessageType.TEXT && (
                    <p className="text-14 text-black-body">{message.message}</p>
                  )}
                  {message.customType === CustomMessageType.GIF && 'GIF'}
                  {message.customType === CustomMessageType.URL && 'URL'}
                  {message.customType === CustomMessageType.VIDEO && (
                    <Link to={`video/${message.messageId}`}>VIDEO</Link>
                  )}
                  {message.customType === CustomMessageType.IMAGE && (
                    <Link to={`gallery/${message.messageId}`}>IMAGE</Link>
                  )}
                  {message.customType === CustomMessageType.FILE && <FileNotice message={message.message} />}
                  {message.customType === CustomMessageType.POLL && (
                    <Link to={`poll/${JSON.parse(message.data || '{}').id}`}>
                      {JSON.parse(message.data || '{}').title}
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatRoomInfoNoticeList;
