import { useCallback, useEffect } from 'react';

import { ResponseCommand, ResponseCommandMap } from '../types/postMessageCommand';

type Props = {
  commandMap: ResponseCommandMap;
  ref: React.RefObject<HTMLIFrameElement>;
};

const useIframePostMessageListener = ({ ref, commandMap }: Props) => {
  const handler = useCallback(
    (event: MessageEvent) => {
      const processCommand = () => {
        try {
          const command = event.data.command as ResponseCommand;
          commandMap.forEach((func, commandKey) => {
            if (commandKey === command) {
              func(event);
            }
          });
        } catch (error) {
          console.error(error);
        }
      };

      if (ref.current != null && ref.current.contentWindow === event.source) {
        processCommand();
      }
    },
    [commandMap]
  );

  useEffect(() => {
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [handler]);
};

export default useIframePostMessageListener;
