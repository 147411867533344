import { ChannelType, PushTriggerOption, Role, User } from '@sendbird/chat';
import { CountPreference, Member, MemberState, MutedState } from '@sendbird/chat/groupChannel';
import { MentionType, MessageType, Sender, UserMessage } from '@sendbird/chat/message';

export enum ChatChannelType {
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export enum ChannelPrivacyType {
  OPEN = 'OPEN',
  PRIVATE = 'PRIVATE',
  ONLY_CLUB_MEMBERS = 'ONLY_CLUB_MEMBERS'
}

export enum VoiceChat {
  ALL = 'ALL',
  MASTER = 'MASTER',
  STAFF = 'STAFF'
}

export type ViewChannelHistory = {
  userId: string;
  createdAt: number;
  duration: number;
  reason: string;
  disabled: boolean;
};

export type ChannelCustomData = {
  introduction: string;
  noticeMessageIds: number[];
  participantsLimit: number;
  staffIds: string[];
  masterIds: string[];
  transferingStaffIds: string[];
  forbiddenWords: string[];
  bookmarkUserIds: string[];
  sendMessageDisabledUserIds: string[];
  viewChannelDisabledUserIds: string[];
  viewChannelHistories: ViewChannelHistory[];
  onlyClubMembers?: boolean;
  clubId?: string;
};

export type InvitationMessageProps = {
  fromUserId: string;
  toUserId: string;
  channelUrl: string;
  message: string;
  channelName: string;
  introduction: string;
};

export type TransferMasterMessageProps = {
  fromUserId: string;
  toUserId: string;
  channelUrl: string;
  channelName: string;
};

export enum CustomMessageType {
  GIF = 'GIF',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
  URL = 'URL',
  SYSTEM = 'SYSTEM',
  CREATED_CHANNEL = 'CREATED_CHANNEL',
  JOIN = 'JOIN',
  LEAVE = 'LEAVE',
  KICK = 'KICK',
  CHANNEL_CHANGE = 'CHANNEL_CHANGE',
  INVITATION = 'INVITATION',
  UNKNOWN = 'UNKNOWN',
  MASTER_TRANSFER = 'MASTER_TRANSFER',
  SENDBIRD_AUTO_EVENT_MESSAGE = 'SENDBIRD:AUTO_EVENT_MESSAGE',
  POLL = 'POLL',
  FORUM_TRANSFER = 'FORUM_TRANSFER',
  CLUB_TRANSFER = 'CLUB_TRANSFER',
  DEFAULT = 'DEFAULT',
  COIN_TRANSACTION = 'COIN_TRANSACTION'
}

/**
 * @deprecated
 * GroupChannelDto 로 변경
 */
export type ChatChannel = {
  name: string;
  unreadMessageCount: number;
  unreadMentionCount: number;
  url: string;
  joinedAt: number;
  isPublic: boolean;
  isDistinct: boolean;
  customType: CustomChannelType;
  members: Member[];
  coverUrl: string;
  lastMessage?: UserMessage;
  channelType: ChannelType;
  creator: User;
  data: ChannelCustomData;
  isDiscoverable: boolean;
  createdAt: number;
  memberCount: number;
  myCountPreference: CountPreference;
  myLastRead: number;
  myMemberState: MemberState;
  myMutedState: MutedState;
  myPushTriggerOption: PushTriggerOption;
  myRole: Role;
  inviter: User;
  invitedAt: number;
};

/**
 * @deprecated
 * ChannelType 으로 변경
 */
export enum CustomChannelType {
  DM = 'DM',
  GROUP = 'GROUP',
  CLUB = 'CLUB'
}

export type ChatMessage = {
  channelUrl: string;
  channelType: ChannelType;
  messageId: number;
  message: string;
  parentMessageId?: number;
  parentMessage?: ChatMessage;
  silent?: boolean;
  isOperatorMessage?: boolean;
  messageType?: MessageType;
  data?: string;
  customType?: CustomMessageType;
  mentionType?: MentionType;
  mentionedUsers?: User[];
  mentionedUserIds?: string[];
  mentionedMessageTemplate?: string;
  reactionCount: number;
  reactionUserIds: string[];
  createdAt: number;
  updatedAt?: number;
  sender: Sender;
};
