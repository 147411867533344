import { ReactElement } from 'react';

import { atom } from 'recoil';

export enum AlertType {
  EmptyMessage,
  UserBlock
}

export type AlertState = {
  type?: AlertType;
  title?: string;
  content?: string | ReactElement;
  buttons?: {
    title: string;
    onClick: () => void;
    color?: 'primary' | 'white';
    shortcut?: string | string[];
  }[];
};

export type AlertsState = AlertState[];

const alertsState = atom<AlertsState>({
  key: 'alertsState',
  default: []
});

export default alertsState;
