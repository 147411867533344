import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { isAxiosError } from 'axios';
import classNames from 'classnames';
import { useSetRecoilState } from 'recoil';

import useAlert from '../../../hooks/useAlert';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useMessageInput from '../../../hooks/useMessageInput';
import mentionedUsersState from '../../../store/atoms/mentionedUsersState';
import { CustomMessageType } from '../../../types/common';

import ChatMessageEmoticonInput from './ChatMessageEmoticonInput';
import ChatMessageInputMore from './ChatMessageInputMore';
import ChatMessageMentionInput from './ChatMessageMentionInput';
import ChatMessageReplyInput from './ChatMessageReplyInput';

import { GroupChannelDto, MemberDto } from 'apis/types/chat.type';
import { ReactComponent as EmojiIcon } from 'assets/icons/ic-emotion.svg';
import { ReactComponent as SendIcon } from 'assets/icons/ic-send.svg';
import { getUserPermission } from 'libs/ApiRepository';
import { AlertType } from 'store/atoms/alertsState';

import useUserInfo from 'hooks/useUserInfo';

type Props = {
  channel: GroupChannelDto;
  left: number;
  onSave: () => void;
};

const ChatMessageInput: React.FC<Props> = ({ channel, onSave }) => {
  // const navigate = useNavigate();
  const { show, close } = useAlert();
  const setMentionedUsersState = useSetRecoilState(mentionedUsersState);

  const [isHiddenPlaceHolder, setIsHiddenPlaceHolder] = useState(false);
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const [isInputFocus, setIsInputFocus] = useState(false);

  const refInput = useRef<HTMLInputElement | null>(null);

  const [cursorPosition, setCursorPosition] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [mentionSearch, setMentionSearch] = useState<string>('');
  const [mentionStartIndex, setMentionStartIndex] = useState<number>(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    openEmojiPicker,
    openMemberSearch,
    closeMemberSearch,
    isOpenedEmojiPopup,
    isOpenedMemberSearchPopup,
    isReplyMode,
    parentMessage,
    sendMessageToChannel,
    messageText,
    setMessageText
  } = useMessageInput();

  const [toUserId, setToUserId] = useState('');

  const { data: dmUserPermission } = useQuery({
    queryKey: ['user', 'permission', toUserId],
    queryFn: () => getUserPermission(toUserId),
    select: (data) => data?.data.data,
    enabled: !!toUserId
  });

  const { data: userData } = useUserInfo(toUserId);

  const onSend = async (text: string) => {
    if (!channel || !isEditable) return;

    const trimmed = text.trim();

    if (!trimmed) {
      show({
        type: AlertType.EmptyMessage,
        content: t('chat.alert.emptyMessage.contents'),
        buttons: [
          {
            title: t('common.button.confirm'),
            shortcut: ['Enter', 'Escape'],
            onClick: () => {
              close();
              refInput.current?.focus();
            }
          }
        ]
      });
      refInput.current?.blur();
      return;
    }

    try {
      await sendMessageToChannel(channel, {
        message: trimmed,
        customType: CustomMessageType.TEXT,

        /**
         * 샌드버드는 2중답글을 지원하지않음
         *
         * @see https://community.sendbird.com/t/feature-reply-a-reply/5418
         */
        parentMessageId: parentMessage?.parentMessageId || parentMessage?.messageId,
        mentionedUserIds: parentMessage?.sender.userId ? [parentMessage?.sender.userId] : []
      });
    } catch (error) {
      refInput.current?.blur();

      let content = error instanceof Error ? error.message : 'Failed to send message';

      if (isAxiosError(error)) {
        content = error.response?.data?.message;
      }

      show({
        content,
        buttons: [
          {
            title: t('common.button.confirm'),
            shortcut: ['Enter', 'Escape'],
            onClick: () => {
              close();
              refInput.current?.focus();
            }
          }
        ]
      });

      return;
    }

    setMessageText('');
    onSave();
  };

  const mentionList = channel.members.filter(
    (member) =>
      member.userId !== currentUser?.userId &&
      member.nickname.toLocaleLowerCase().includes(mentionSearch.toLocaleLowerCase())
  );

  const insertMention = (member: MemberDto) => {
    const prevChars = messageText.substring(0, mentionStartIndex);
    const nextChars = messageText.substring(cursorPosition);

    const newText = `${prevChars}@${member.nickname} ${nextChars}`;
    const newCursorPosition = prevChars.length + member.nickname.length + 2;

    setMentionedUsersState((prev) => [...prev, member]);
    setMessageText(newText);
    closeMemberSearch();

    requestAnimationFrame(() => {
      if (refInput.current) {
        refInput.current.focus();
        refInput.current.setSelectionRange(newCursorPosition, newCursorPosition);
        setCursorPosition(newCursorPosition);
      }
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // isComposing 이 true 이면 조합 중이므로 동작을 막는다.
    if (event.nativeEvent.isComposing) return;

    // 채팅 전송 관련 로직

    if (event.key === 'Enter' && !isOpenedMemberSearchPopup && !isSubmitting) {
      event.preventDefault();
      event.stopPropagation();
      setIsSubmitting(true);

      onSend(messageText);

      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);

      return;
    }

    // 멘션 관련 로직
    if (!isOpenedMemberSearchPopup) return;

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : mentionList.length - 1));
        break;
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex((prev) => (prev < mentionList.length - 1 ? prev + 1 : 0));
        break;
      case 'Enter':
        event.preventDefault();
        insertMention(mentionList[selectedIndex]);
        break;
      case 'Escape':
        closeMemberSearch();
        break;
    }
  };

  const getMentionStart = (text: string, cursorPos: number) => {
    const prevText = text.slice(0, cursorPos);
    const lastIndex = prevText.lastIndexOf('@');

    const afterText = prevText.slice(lastIndex + 1);
    const hasSpace = afterText.includes(' ');

    if (lastIndex !== -1 && !hasSpace) {
      return {
        startIndex: lastIndex,
        searchText: text.slice(lastIndex + 1, cursorPos)
      };
    }

    return null;
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value.replace(/<[^>]*>?/gm, '');
    const cursorPos = event.target.selectionStart || 0;

    setMessageText(text.slice(0, 2000));
    setCursorPosition(cursorPos);

    const mention = getMentionStart(text, cursorPos);
    if (mention) {
      setMentionStartIndex(mention.startIndex);
      setMentionSearch(mention.searchText);
      setSelectedIndex(0);
      openMemberSearch();
    } else {
      closeMemberSearch();
    }
  };

  const onFocus = useCallback(() => {
    setIsInputFocus(true);
    setIsHiddenPlaceHolder(true);
    closeMemberSearch();
  }, []);

  const onBlur = useCallback(() => {
    setIsInputFocus(false);
    setIsHiddenPlaceHolder(false);
  }, []);

  const isSendable = useMemo(() => {
    if (!messageText) return false;

    if (messageText.length > 2000) return false;

    return true;
  }, [channel, messageText]);

  const isEditable = useMemo(() => {
    const isMember = channel.members.find((member) => member.userId === currentUser?.userId);

    if (!isMember) return false;

    if (userData?.is_banned) return false;

    if (channel.customType === 'DM' && !!dmUserPermission) {
      if (!userData) return true;

      const permission = dmUserPermission.direct_message_permission;

      switch (permission) {
        case 'PUBLIC':
          return true;
        case 'FRIEND':
          return !!userData.is_friend;

        case 'NO_ONE':
          return false;

        default:
          return true;
      }
    }

    return true;
  }, [channel, messageText, dmUserPermission, userData]);

  const placeholder = useMemo(() => {
    if (isHiddenPlaceHolder) {
      return '';
    }

    if (channel.customType === 'DM' && isEditable === false) {
      switch (dmUserPermission.direct_message_permission) {
        case 'FRIEND':
          return t('chat.messageInput.placeholder.friends');
        case 'NO_ONE':
          return t('chat.messageInput.placeholder.noOne');
      }
    }

    if (isReplyMode) {
      return t('chat.messageInput.placeholder.replay', {
        username: parentMessage?.sender.nickname
      });
    }

    if (userData?.is_banned) {
      return t('chat.messageInput.placeholder.disabled');
    }

    if (channel.sendMessageDisabledUserIds.indexOf(currentUser?.userId || '') > -1) {
      return t('chat.messageInput.placeholder.disabled');
    }

    return t('chat.messageInput.placeholder.default');
  }, [channel, isHiddenPlaceHolder, isReplyMode, parentMessage, t, isEditable, userData]);

  useEffect(() => {
    if (!channel || channel.customType !== 'DM') return;

    const toUserId = channel.members.find((member) => member.userId !== currentUser?.userId)?.userId;

    if (toUserId) {
      setToUserId(toUserId);
    }
  }, [channel]);

  return (
    // global_chat_form
    <div className="bg-gray-100 py-4">
      <div
        // global_chat_input
        className={classNames(
          'relative mx-auto flex min-h-12 w-[calc(100%-8px)] items-center rounded-6 border bg-white pl-1.5 pr-2',
          {
            'border-primary-100': isInputFocus,
            'border-gray-300': !isInputFocus
          }
        )}
      >
        {isOpenedEmojiPopup && <ChatMessageEmoticonInput />}
        {/* 답글하기를 눌렀을 경우 답글하기 아이콘, 유저이름, 텍스트내용과 같이 뜨며 텍스트 내용은 한줄로만 노출됩니다. */}
        {isReplyMode && !!parentMessage && <ChatMessageReplyInput parentMessage={parentMessage} />}
        {/* input란에 @로 닉네임을 지정할 시 나오는 팝업입니다. 현재 채팅창에 참여한 멤버 리스트가 나옵니다. */}
        {isOpenedMemberSearchPopup && (
          <ChatMessageMentionInput
            mentionList={mentionList}
            keyword={mentionSearch}
            selectIndex={selectedIndex}
            onMention={insertMention}
          />
        )}

        <ChatMessageInputMore isEditable={isEditable} />

        {/* 이모티콘 아이콘에 hover시 검은색 아이콘이 되며, 클릭 시 active class를 추가하여 이모티콘 팝업이 보여지는 동안은 보라색 아이콘으로 보여져야 함 */}
        <button
          type="button"
          className="absolute bottom-2 right-[50px] size-8"
          disabled={!isEditable}
          onClick={() => {
            openEmojiPicker();
            closeMemberSearch();
          }}
        >
          <EmojiIcon
            className={classNames('size-8 text-gray-500', {
              'text-primary-100': isOpenedEmojiPopup,
              'hover:text-gray-900': !isOpenedEmojiPopup
            })}
          />
          <span className="a11y">이모티콘</span>
        </button>
        <div className="relative ml-[38px] mr-10 w-full py-3">
          <input
            ref={refInput}
            className="w-full text-16 font-semibold leading-[20px] text-black-body"
            placeholder={placeholder}
            value={messageText}
            disabled={!isEditable}
            onChange={onChangeText}
            onKeyDown={onKeyDown}
            maxLength={2000}
            onFocus={onFocus}
            onBlur={onBlur}
            style={{
              fontFamily: 'Arial, sans-serif'
            }}
          />
        </div>

        <button
          type="button"
          className={classNames(
            'flex size-9 shrink-0 items-center justify-center rounded-full bg-primary-100 disabled:bg-gray-400',
            {}
          )}
          onClick={() => onSend(messageText)}
          disabled={!isSendable}
        >
          <SendIcon className="size-6 text-white" />
          <span className="sr-only">입력</span>
        </button>
      </div>
    </div>
  );
};

export default ChatMessageInput;
