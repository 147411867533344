import React, { FormHTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import styled from 'styled-components';

import api from '../../../libs/apis';
import queryKeys from '../../../libs/apis/queryKeys';

import { ReactComponent as TagIcon } from 'assets/icons/ic-tag.svg';

interface MiniprofileMemoProps {
  userId: number;
  memo?: string;
}

const MiniprofileMemo: React.FC<MiniprofileMemoProps> = (props) => {
  const { userId, memo = '' } = props;

  const [nickname, setNickname] = useState(memo);
  const nicknameInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const postNicknameMutation = useMutation({
    mutationFn: api.postUserNickname,
    onMutate: (payload) => {
      const queryKey = queryKeys.user.getItem(userId);
      queryClient.setQueryData(queryKey, (prev: any) => ({
        ...prev,
        data: { ...prev.data, ...payload.data }
      }));
    }
  });

  const handleSubmitNickname: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    nicknameInputRef.current?.blur();
  };

  const handleChangeNickname: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setNickname(e.target.value);
  };

  const handleBlurNickname = () => {
    if (nickname !== memo) {
      postNicknameMutation.mutate({ id: userId, data: { nickname } });
    }
  };

  return (
    <StyledNickname onSubmit={handleSubmitNickname}>
      <TagIcon />
      <input
        ref={nicknameInputRef}
        placeholder={t('chat.miniProfile.memo.placeholder')}
        value={nickname}
        onChange={handleChangeNickname}
        onBlur={handleBlurNickname}
        maxLength={30}
      />
      <StyledNicknameSuffix>
        <span className="current">{nickname?.length ?? 0}</span>
        <span>/30</span>
      </StyledNicknameSuffix>
    </StyledNickname>
  );
};

export default MiniprofileMemo;

const StyledNicknameSuffix = styled.div`
  display: none;
  width: 40px;
  height: 20px;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.28px;
  text-align: right;
  color: #a7a8ab;
  .current {
    font-weight: 500;
    color: #9013fe;
  }
`;

const StyledNickname = styled.form<FormHTMLAttributes<any>>`
  display: grid;
  grid-template-columns: 20px 158px 40px;
  gap: 8px;
  width: 258px;
  height: 32px;
  padding: 6px 12px;
  border-radius: 16px;
  background: #f8f8f8;
  border: none;
  transition: outline 0.1s ease-in-out;
  &:has(input:focus) {
    outline: 1px solid #121212;
    ${StyledNicknameSuffix} {
      display: block;
    }
  }
  input {
    height: 20px;
    background: none;
    border: none;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.28px;
    color: #ff45a1;
    :not(:focus) {
      grid-column: span 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:focus {
      grid-column: span 1;
      font-weight: 500;
      color: #121212;
      outline: none;
    }
    &::placeholder {
      color: #bdbdbd;
      font-weight: 400;
    }
  }
  svg {
    color: #a7a8ab;
  }
`;
