import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { masking } from '../util';

import useToast from 'hooks/useToast';

interface MaskingAddressBadgeProps {
  address?: string;
}

const MaskingAddressBadge = (props: MaskingAddressBadgeProps) => {
  const { address } = props;

  const { t } = useTranslation();
  const toast = useToast();

  const onClickCopy = () => {
    if (!address) return;
    navigator.clipboard.writeText(address);
    toast.open(t('common.alert.copied'));
  };

  if (!address) return <></>;

  return (
    <button
      className="rounded-1 bg-gray-200 px-2 py-0.5 text-14 leading-[20px] text-gray-700"
      onClick={(e) => {
        e.stopPropagation();
        onClickCopy();
      }}
    >
      <span data-tooltip-id={`creta-address-tooltip_${address}`}>
        {masking(address)}

        <Tooltip id={`creta-address-tooltip_${address}`} content={address} place="left" />
      </span>
    </button>
  );
};

export default MaskingAddressBadge;
