import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { ReactComponent as FollowIcon } from 'assets/icons/ic-follow.svg';
import { ReactComponent as FollowingIcon } from 'assets/icons/ic-following.svg';
import Button from 'components/jayden/Button';
import queryKeys from 'libs/apis/queryKeys';

import {
  useDeleteFriendMutation,
  usePostApproveFriendMutation,
  usePostFriendMutation,
  usePostUnfriendMutation
} from 'hooks/use-friend-mutation';
import useAlert from 'hooks/useAlert';

export type FriendRequestStatus = null | 'SEND' | 'APPROVED' | 'REJECTED' | 'BANNED' | 'UNAVAILABLE';
interface MiniProfileLeftButtonProps {
  isMyProfile: boolean;
  userId?: number;
  friendRequestStatus?: FriendRequestStatus;
  requestId?: number;
}

const MiniProfileLeftButton = (props: MiniProfileLeftButtonProps) => {
  const { isMyProfile, friendRequestStatus, userId, requestId } = props;
  if (!isMyProfile) {
    return <FriendStatusButton userId={userId} friendRequestStatus={friendRequestStatus} requestId={requestId} />;
  }

  return <></>;
};

export default MiniProfileLeftButton;

function FriendStatusButton({
  friendRequestStatus,
  userId = 0,
  requestId = 0
}: {
  friendRequestStatus?: FriendRequestStatus;
  userId?: number;
  requestId?: number;
}) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const alert = useAlert();

  const invalidate = () => {
    queryClient.invalidateQueries(queryKeys.user.getItem(`${userId}` as string));
    queryClient.invalidateQueries(queryKeys.user.getItem(userId));
  };

  const useFriendMutation = usePostFriendMutation({
    onSuccess: () => {
      invalidate();
    },
    onError: () => {
      alert.showConfirm(t('common.friendPopup.notAllow'));
    }
  });

  const useFriendCancelMutation = useDeleteFriendMutation({
    onSuccess: () => {
      invalidate();
    },
    onError: () => {
      alert.showConfirm(t('common.friendPopup.notAllow'));
    }
  });

  const unfriendMutation = usePostUnfriendMutation({
    onSuccess: () => {
      invalidate();
    },
    onError: () => {
      alert.showConfirm(t('common.friendPopup.notAllow'));
    }
  });

  const approveMutation = usePostApproveFriendMutation({
    onSuccess: () => {
      invalidate();
    },
    onError: () => {
      alert.showConfirm(t('common.friendPopup.notAllow'));
    }
  });

  const onClickSend = () => {
    useFriendMutation.mutate({ id: Number(userId) });
  };

  const onClickCancel = () => {
    useFriendCancelMutation.mutate({ id: Number(userId) });
  };

  const onClickUnfriend = () => {
    alert.show({
      content: t('common.friendPopup.unfriend'),
      buttons: [
        {
          title: t('common.button.cancel'),
          onClick: alert.close
        },
        {
          title: t('common.button.confirm'),
          onClick: () => unfriendMutation.mutate({ id: Number(userId) })
        }
      ]
    });
  };

  const onClickApprove = () => {
    if (!requestId) return;
    approveMutation.mutate({ requestId: requestId });
  };

  switch (friendRequestStatus) {
    case 'SEND':
      return (
        <Button
          className="flex w-[126px] items-center justify-center gap-1 whitespace-nowrap text-15 font-medium"
          type="button"
          size="md"
          color="secondary"
          onClick={onClickCancel}
          disabled={useFriendCancelMutation.isLoading}
        >
          {t('common.friend.button.cancelRequest')}
        </Button>
      );
    case 'APPROVED':
      return (
        <Button
          className="flex w-[126px] items-center justify-center gap-1 whitespace-nowrap text-15 font-medium"
          type="button"
          size="md"
          color="secondary"
          outline
          onClick={onClickUnfriend}
          disabled={useFriendMutation.isLoading}
        >
          <FollowingIcon className="size-6 shrink-0" />
          {t('common.friend.button.friend')}
        </Button>
      );

    default:
      return (
        <Button
          className="flex w-[126px] items-center justify-center gap-1 whitespace-nowrap text-15 font-medium"
          type="button"
          size="md"
          color="secondary"
          disabled={useFriendMutation.isLoading || approveMutation.isLoading}
          onClick={() => {
            switch (friendRequestStatus) {
              case 'BANNED':
                alert.showConfirm(t('common.friendPopup.notAllow'));
                return;
              case null:
                onClickSend();
                return;
              default:
                onClickApprove();
            }
          }}
        >
          <FollowIcon className="size-6 shrink-0" />
          {t('common.friend.button.addFriend')}
        </Button>
      );
  }
}
