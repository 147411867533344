import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from 'components/jayden/Loader';
import ProfileAvatar from 'components/profileAvatar/ProfileAvatar';
import classNames from 'components/styled/util';

interface ChannelInfoMemberSearchListProps {
  searchedMemberList: any[];
  keyword?: string;
  onClose: () => void;
  isLoading?: boolean;
}

const ChannelInfoMemberSearchList = ({
  searchedMemberList,
  keyword = '',
  onClose,
  isLoading = false
}: ChannelInfoMemberSearchListProps) => {
  const navigate = useNavigate();

  const onProfile = useCallback(
    (userId: string) => () => {
      navigate(`miniprofile/${userId}`, {
        replace: false
      });
      onClose();
    },
    []
  );

  return (
    <div
      className={classNames({
        hidden: !keyword,
        'absolute z-[99] rounded-2 mt-1.5 max-h-[350px] overflow-y-auto border bg-white w-full border-gray-500 top-[52px]':
          keyword
      })}
    >
      {searchedMemberList.length > 0 && (
        <ul className="">
          {/* 각 멤버 클릭 시 미니 프로필 호출 */}
          {searchedMemberList.map((member) => {
            const userInfo = {
              ...member,
              profile_image_url: member.profileUrl
            } as any;

            return (
              <li
                key={member.userId}
                onClick={onProfile(member.userId)}
                className="flex h-12 cursor-pointer items-center px-4 first:mt-2.5 last:mb-2.5 hover:bg-gray-100"
              >
                <ProfileAvatar userInfo={userInfo} trustRing size={32} className="z-0" />
                <div className="ml-2 w-fit truncate text-13 font-medium text-black-body">{member.nickname}</div>
              </li>
            );
          })}
        </ul>
      )}

      {isLoading && (
        <div className="py-10">
          <Loader />
        </div>
      )}

      {!isLoading && searchedMemberList.length === 0 && (
        <div className="flex flex-col items-center justify-center p-7">
          <img className="size-[60px]" src={`${process.env.PUBLIC_URL}/img/com/img_unsmile3_gr.png`} />
          <p className="text-18 font-bold leading-[26px] text-gray-300">No Matching..</p>
        </div>
      )}
    </div>
  );
};
export default ChannelInfoMemberSearchList;
