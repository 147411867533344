import React from 'react';
import { toast, ToastOptions, DefaultToastOptions } from 'react-hot-toast';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import classNames from 'components/styled/util';

const defaultToastOptions: DefaultToastOptions = {
  position: 'bottom-center',
  duration: 3000
};

interface ToastMessage {
  title?: string;
  content: string;
  hasCloseButton?: boolean;
  options?: ToastOptions;
}

enum TOAST_TYPES {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

const TOAST_COLORS: Record<TOAST_TYPES, string> = {
  [TOAST_TYPES.SUCCESS]: 'bg-success-50 border-success-100 text-success-400',
  [TOAST_TYPES.WARNING]: 'bg-warning-50 border-warning-200 text-warning-500',
  [TOAST_TYPES.ERROR]: 'bg-error-50 border-error-100 text-error-400'
};

const TOAST_CLOSE_BUTTON_COLORS: Record<TOAST_TYPES, string> = {
  [TOAST_TYPES.SUCCESS]: 'stroke-[#bae3d9] text-[#bae3d9]',
  [TOAST_TYPES.WARNING]: 'stroke-[#f2d85d] text-[#f2d85d]',
  [TOAST_TYPES.ERROR]: 'stroke-[#fcc0cc] text-[#fcc0cc]'
};

const useToast = () => {
  const open = (_message: ToastMessage | string, type?: TOAST_TYPES) => {
    const message = typeof _message === 'string' ? { content: _message } : _message;
    toast.custom(
      (t) => (
        <div
          className={classNames(
            'z-20 flex h-12 items-start justify-between gap-4 rounded-2 border border-black-body bg-black-body bg-opacity-80 px-4 py-3 text-white',
            {
              'h-[69px]': !!message.title,
              'bg-opacity-95': !!type,
              'animate-toast-up': t.visible,
              'animate-toast-down': !t.visible
            },
            type && TOAST_COLORS[type]
          )}
        >
          <div className="flex flex-col gap-0.5">
            {message.title && <span className="text-16 font-semibold">{message.title}</span>}
            <span className="text-14 font-light first:font-normal">{message.content}</span>
          </div>

          {message.hasCloseButton && (
            <button className="flex size-6" onClick={() => toast.dismiss(t.id)}>
              <CloseIcon
                className={classNames('size-6 stroke-gray-700 text-gray-700', type && TOAST_CLOSE_BUTTON_COLORS[type])}
              />
            </button>
          )}
        </div>
      ),
      {
        ...defaultToastOptions,
        ...message.options
      }
    );
  };

  const success = (message: ToastMessage | string) => {
    open(message, TOAST_TYPES.SUCCESS);
  };

  const warning = (message: ToastMessage | string) => {
    open(message, TOAST_TYPES.WARNING);
  };

  const error = (message: ToastMessage | string) => {
    open(message, TOAST_TYPES.ERROR);
  };

  return { open, success, warning, error };
};

export default useToast;
