import { useEffect } from 'react';

import { ConnectionHandler } from '@sendbird/chat';

import getSendBird from '../libs/sendbird';

const useConnectionHandler = () => {
  useEffect(() => {
    const connectionHandler: ConnectionHandler = new ConnectionHandler({
      onConnected: () => {
        // console.log('onConnected');
      },
      onDisconnected: () => {
        // console.log('onDisconnected');
        getSendBird().reconnect();
      },
      onReconnectStarted: () => {
        // console.log('onReconnectStarted');
      },
      onReconnectSucceeded: () => {
        // console.log('onReconnectSucceeded');
      },
      onReconnectFailed: () => {
        // console.log('onReconnectFailed');
        getSendBird().reconnect();
      }
    });

    const handlerId = `global_connection_handler`;

    getSendBird().addConnectionHandler(handlerId, connectionHandler);

    return () => {
      getSendBird().removeConnectionHandler(handlerId);
    };
  }, []);
};

export default useConnectionHandler;
