import { commonInstance } from 'apis';

const commonApi = {
  postFriendRequest: async ({ id }: { id: number }) => {
    const { data } = await commonInstance.post(`user/${id}/friend-request`);
    return data;
  },

  deleteFriendRequest: async ({ id }: { id: number }) => {
    const { data } = await commonInstance.delete(`user/${id}/friend-request`);
    return data;
  },

  postApproveFriendRequest: async ({ requestId }: { requestId: number }) => {
    const { data } = await commonInstance.post(`friend-requests/approval`, { ids: [requestId] });
    return data;
  },

  postUnfriend: async ({ id }: { id: number }) => {
    const { data } = await commonInstance.post(`user/${id}/unfriend`);
    return data;
  },

  postUserBan: async ({ id, ...payload }: { id: number; content: string }) => {
    const { data } = await commonInstance.post(`user/${id}/ban`, payload);
    return data;
  },
  postUserUnban: async ({ id }: { id: number }) => {
    const { data } = await commonInstance.post(`user/${id}/unban`);
    return data;
  }
};

export default commonApi;
