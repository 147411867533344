import React from 'react';

import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { lastUnreadMessageIdState } from '../../../store/atoms/chatMessagesState';

interface UnreadMessageIndicatorProps {
  unreadCount: number;
  handleClickMarkAsRead: () => void;
}

const UnreadMessageIndicator = React.forwardRef(
  (props: UnreadMessageIndicatorProps, ref: React.Ref<HTMLDivElement>) => {
    const { unreadCount, handleClickMarkAsRead } = props;

    const setChatMessageIdsStateValue = useSetRecoilState(lastUnreadMessageIdState);

    if (!unreadCount) return null;

    return (
      <div
        className="relative flex flex-col items-center justify-center bg-inherit px-5 py-4 text-14 first:hidden"
        ref={ref}
      >
        <StyledLine />
        <StyledContent>
          <span>
            {t('chat.unread.noticeMessage', {
              messageCount: unreadCount > 99 ? '99+' : unreadCount
            })}
          </span>
          <button
            className="text-secondary-100"
            type="button"
            onClick={() => {
              setChatMessageIdsStateValue(0);
              handleClickMarkAsRead();
            }}
          >
            <span>{t('chat.unread.markAsRead')}</span>
          </button>
        </StyledContent>
      </div>
    );
  }
);
UnreadMessageIndicator.displayName = 'UnreadMessageIndicator';
export default UnreadMessageIndicator;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: inherit;
  padding: 7px 20px 5px 20px;
  z-index: 0;

  span {
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.28px;
    text-align: center;
    color: #777;
  }

  .mark_as_read-btn {
    span {
      color: #6563ff;
    }
  }
`;

const StyledLine = styled.div`
  position: absolute;
  height: 1px;
  width: calc(100% - 40px);
  background-color: #cdcdd1;
`;
