import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoIcon } from 'assets/iicon';

interface OnePerInvalidateProps {
  show?: boolean;
}

const OnePerInvalidate = (props: OnePerInvalidateProps) => {
  const { show } = props;

  const { t } = useTranslation();

  console.log(`t('sendCoin.enterVolume.onePerCheck.message')`, t('sendCoin.enterVolume.onePerCheck.message'));
  if (!show) return <></>;

  return (
    <div className="mt-1.5 text-end text-15 text-#ff2a55">
      <div className="flex justify-end gap-0.5">
        <InfoIcon className="size-5 shrink-0" />
        <p className="whitespace-pre">{t('sendCoin.enterVolume.onePerCheck.message')}</p>
      </div>
    </div>
  );
};

export default OnePerInvalidate;
