import React, { PropsWithChildren } from 'react';

import classNames from 'components/styled/util';

interface TooltipProps extends PropsWithChildren {
  position: 'right' | 'bottom';
  className?: string;
  textClassName?: string;
}

const Tooltip = (props: TooltipProps) => {
  const { children, position = 'right', className, textClassName } = props;

  return (
    <div
      className={classNames(
        'invisible absolute z-10 w-auto rounded bg-black-body px-2 py-1 text-center opacity-80 shadow-md group-hover:visible',
        {
          'left-[calc(100%+5px)] top-1/2 -translate-y-1/2': position === 'right',
          'bottom-[-5px] left-1/2 -translate-x-1/2 translate-y-full': position === 'bottom'
        },
        className
      )}
    >
      <span className={classNames('text-13 text-white', textClassName)}>{children}</span>
    </div>
  );
};

export default Tooltip;
