import { useMemo } from 'react';

import { PostMessagePath, RequestCommand, ResponseCommand, ResponseCommandMap } from '../types/postMessageCommand';

import useIframePostMessageListener from './useIframePostMessageListener';
import usePostMessage from './usePostMessage';

import { AuthStorage } from 'constants/storage';

import { getStorage } from 'utils/storage';

export const voteUrl = process.env.REACT_APP_VOTE_URL;

type Props = {
  ref: React.RefObject<HTMLIFrameElement>;
  commandMap?: ResponseCommandMap;
  context?: any;
};

const useVoteIframe = ({ ref, commandMap: _commandMap, context }: Props) => {
  const postMessage = usePostMessage(ref);

  const init = (event: MessageEvent) => {
    if (event.data?.from !== PostMessagePath.Vote) return;
    const accessToken = getStorage(AuthStorage.ACCESS_TOKEN);
    postMessage(RequestCommand.Initialize, { accessToken, data: context });
  };

  const commandMap = useMemo<ResponseCommandMap>(
    () => new Map([[ResponseCommand.Ready, init], ...(_commandMap ?? [])]),
    [_commandMap]
  );

  useIframePostMessageListener({ commandMap, ref });
};

export default useVoteIframe;
