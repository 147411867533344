import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  to: string;
  className: string;
  children?: ReactNode;
  replace?: boolean;
};

const LinkButton: React.FC<Props> = ({ to, className, children, replace = false }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(to, {
      replace
    });
  }, [to]);
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  );
};
export default LinkButton;
