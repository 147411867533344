import { useMutation, UseMutationOptions } from 'react-query';

import commonApi from 'apis/common-api';

export type MutationOptionsType = Omit<UseMutationOptions<any, any, any, unknown>, 'mutationFn'>;

export const usePostFriendMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: commonApi.postFriendRequest,
    ...options
  });
};

export const useDeleteFriendMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: commonApi.deleteFriendRequest,
    ...options
  });
};

export const usePostApproveFriendMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: commonApi.postApproveFriendRequest,
    ...options
  });
};

export const usePostUnfriendMutation = (options: MutationOptionsType) => {
  return useMutation({
    mutationFn: commonApi.postUnfriend,
    ...options
  });
};

// export const usePostFriendRequestsApproval = (options: MutationOptionsType) => {
//   return useMutation({
//     mutationFn: commonApi.postFriendRequestsApproval,
//     ...options
//   });
// }
