// 반올림 방식을 결정하는 enum 객체
const RoundingMode = {
  ROUND: Math.round,
  FLOOR: Math.floor,
  CEIL: Math.ceil
};

// numberUI 함수를 고차 함수로 변경
const createNumberFormatter =
  (roundingFn = Math.round) =>
  (num: number | string, decimal = 8) => {
    if (!num) return '0';

    // 입력값에 10^decimal을 곱해서 정수로 만듦
    const scaledInput = Math.round(Number(num) * Math.pow(10, decimal));

    // 반올림 방식 적용
    const scaledResult = roundingFn(scaledInput);

    // 다시 소수점 형태로 변환
    const result = scaledResult / Math.pow(10, decimal);

    return result.toFixed(decimal).replace(/\.?0+$/, '');
  };

export const numberUI = createNumberFormatter(RoundingMode.ROUND);
export const numberUIFloor = createNumberFormatter(RoundingMode.FLOOR);
export const numberUICeil = createNumberFormatter(RoundingMode.CEIL);

export const masking = (address: string) => {
  const start = address.slice(0, 5);
  const end = address.slice(-5);
  return `${start}...${end}`;
};

export const splitPerUser = (value: string, chunk: number, decimal = 8) => {
  const scaledInput = Math.round(Number(value) * Math.pow(10, decimal));
  const scaledResult = Math.floor(scaledInput / chunk);
  return numberUIFloor(scaledResult / Math.pow(10, decimal));
};

export const totalVolume = (value: string, chunk: number) => {
  const num = Number(value);
  const result = num * chunk;
  return numberUI(result);
};

export const decimalInput = (value: string, setInput: (value: string) => void, point = 4) => {
  const regex = new RegExp(`^\\d*\\.?\\d{0,${point}}$`); // 동적 소수점 자릿수
  if (regex.test(value)) {
    setInput(value); // 유효한 값일 때만 setInput 호출
  }
};

/**
 * 지갑 타입 가져오기
 */
export const getWalletType = (
  user: unknown & { wallet_address_creta?: string; wallet_address?: string; wallet_type?: 'CRETA' | 'METAMASK' }
) => {
  if (!user) return 'CRETA';
  if (typeof user !== 'object') return 'CRETA';

  if (Object.prototype.hasOwnProperty.call(user, 'wallet_type')) {
    return user.wallet_type as 'CRETA' | 'METAMASK';
  }

  console.log(`user안에 type없음`, user);

  // 만약 유저정보에 wallet_address_creta, wallet_address_metamask 등의 정보가 없으면 CRETA로 early return
  if (!Object.prototype.hasOwnProperty.call(user, 'wallet_address_creta')) {
    console.log('유저정보에 지갑주소 정보 없음!');
    return 'CRETA';
  }

  if (user?.wallet_address === user?.wallet_address_creta) return 'CRETA';
  else return 'METAMASK';
};
