export enum PostMessagePath {
  Editor = 'superclub/editor',
  Vote = 'superclub/vote',
  Superclub = 'superclub',
  Chat = 'superclub/chat'
}

export enum ResponseCommand {
  Initialize = 'initialize',
  CreateSuccess = 'create/vote/success',
  CreateFailure = 'create/vote/failure',
  EditSuccess = 'edit/vote/success',
  EditFailure = 'edit/vote/failure',
  VoteSuccess = 'vote/success',
  Ready = 'ready',
  StopVote = 'stop/vote',
  ClickVoters = 'click/voters',
  ClickVoteOptionImage = 'click/vote_option_image',
  NavigateVoteList = 'navigate/vote_list',
  MeasureHeightSuccess = 'measure/height/success'
}

export enum RequestCommand {
  Ready = 'ready',
  CreateVote = 'create/vote',
  ResetVote = 'reset/vote',
  Initialize = 'initialize',
  SendCoins = 'send/coins'
}

type ResponseCommandFunction =
  | ((event: MessageEvent) => void)
  | ((event: MessageEvent) => Promise<void>)
  | (() => void);

export type ResponseCommandMap = Map<ResponseCommand, ResponseCommandFunction>;
