import { type MetaData } from '@sendbird/chat';
import { useRecoilState } from 'recoil';

import getSendBird, { fetchUser, updateUserMetadata } from '../libs/sendbird';
import userState from '../store/atoms/userState';

export enum UserStatus {
  ONLINE = 'online',
  AWAY = 'away',
  DO_NOT_DISTURB = 'do_not_disturb',
  GAMING = 'gaming'
}

const useCurrentUser = () => {
  const [userStateValue, setUserStateValue] = useRecoilState(userState);

  const updateUserState = async (status: UserStatus) => {
    if (userStateValue && userStateValue.userId) {
      const newMetaData: MetaData = {
        ...userStateValue.metaData,
        status
      };

      await updateUserMetadata(userStateValue.userId, newMetaData);

      setUserStateValue({
        ...userStateValue,
        metaData: newMetaData
      });
    }
  };

  const refresh = async () => {
    if (userStateValue && userStateValue.userId) {
      getSendBird().reconnect();
      const refreshedUser = await fetchUser(userStateValue.userId);
      setUserStateValue(refreshedUser);
    }
  };

  return {
    currentUser: userStateValue,
    updateUserState,
    refresh,
    setCurrentUser: setUserStateValue
  };
};

export default useCurrentUser;
