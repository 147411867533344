import type { UserMessageCreateParams } from '@sendbird/chat/message';

import { GroupChannelDto } from './types/chat.type';

import { chatInstance } from 'apis';
import { DjangoInterface } from 'libs/apis/response.type';

export type PostGroupChannelPayload = {
  name: string;
  coverUrl: string;
  isPublic: boolean;
  participantsLimit: number;
  introduction: string;
};

export type PostClubChannelPayload = PostGroupChannelPayload & {
  onlyClubMembers: boolean;
  clubId: number;
};

export type GroupChannelParams = {
  channelId: string;
};

export type GroupChannelInviteableUserListParams = GroupChannelParams & {
  search?: string;
  page?: number;
  page_size?: number;
};

export type PutGroupChannelInfoPayload = GroupChannelParams &
  Partial<PostGroupChannelPayload> & {
    forbiddenWords: string[];
  };

export type GroupChannelListParams = {
  type?: 'CLUB' | 'DM' | 'GROUP';
};

const chatApi = {
  getGroupChannelList: async (params?: GroupChannelListParams) => {
    const { data } = await chatInstance.get('/group-channels', { params });
    return data;
  },

  postGroupChannel: async (payload: PostGroupChannelPayload) => {
    const { data } = await chatInstance.post('/group-channels', payload);
    return data;
  },
  getGroupChannel: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.get<DjangoInterface<GroupChannelDto>>(`/group-channels/${channelId}`);
    return data;
  },
  putGroupChannelInfo: async ({ channelId, ...payload }: PutGroupChannelInfoPayload) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/info`, payload);
    return data;
  },
  deleteGroupChannel: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}`);
    return data;
  },

  /**
   * Get All Joined Group Channels
   */
  getGroupChannelsAllJoined: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/group-channels/all/joined`, { params: { search } });
    return data;
  },

  /**
   * media
   */
  postFileUploadImageChannelId: async ({ channelId, formData }: GroupChannelParams & { formData: FormData }) => {
    const { data } = await chatInstance.post(`/file/upload/image/${channelId}`, {
      data: {
        formData
      }
    });
    return data;
  },

  /**
   * 03. GroupChannel (user)
   */
  putGroupChannelChannelIdJoin: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/join`);
    return data;
  },
  putGroupChannelChannelIdLeave: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/leave`);
    return data;
  },

  /**
   * 04. GroupChannelInvites
   */
  postGroupChannelsInviteUserId: async ({ channelId, userId }: { channelId: string; userId: string }) => {
    const { data } = await chatInstance.post(`/group-channels/invite/${channelId}/invite/${userId}`);
    return data;
  },
  /**
   * @see https://chat.server.dev.superclubs.io/docs/#/04.%20GroupChannelInvites/GroupChannelInviteController_getInviteableUsers
   */
  getGroupChannelsInviteInviteableUsers: async ({ channelId, ...params }: GroupChannelInviteableUserListParams) => {
    const { data } = await chatInstance.get(`/group-channels/invite/${channelId}/inviteable-users`, { params });
    return data;
  },

  /**
   * 05. ClubChannels
   */
  postClubChannels: async (payload: PostClubChannelPayload) => {
    const { data } = await chatInstance.post('/club-channels', payload);
    return data;
  },

  /**
   * Get All Available Public Club Channels
   */
  getClubChannelsJoinable: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/club-channels/joinable`, { params: { search } });
    return data;
  },

  /**
   * Get All Joined Club Channels
   */
  getClubChannelsJoined: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/club-channels/joined`, { params: { search } });
    return data;
  },

  /**
   * 05. DMChannels
   */
  postDmChannels: async (payload: { toUserId: number }) => {
    const { data } = await chatInstance.post('/dm-channels', payload);
    return data;
  },

  /**
   * Get All Joined DM Channels
   */
  getDmChannelsJoined: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/dm-channels/joined`, { params: { search } });
    return data;
  },

  /**
   * Get All Available Public Group Channels
   */
  getGroupChannelsJoinable: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/group-channels/joinable`, { params: { search } });
    return data;
  },

  /**
   * Get All Joined Group Channels
   */
  getGroupChannelsJoined: async ({ search }: { search?: string }) => {
    const { data } = await chatInstance.get(`/group-channels/joined`, { params: { search } });
    return data;
  },

  /** ------------------------------------------------------------------------------
   * 
   * 03. GroupChannels (bookmark)
   * 
   ------------------------------------------------------------------------------ */
  /**
   * Set bookmark
   */
  postGroupChannelsChannelIdBookmark: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/bookmark`);
    return data;
  },

  /**
   * Unset bookmark
   */
  putGroupChannelsChannelIdBookmark: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/bookmark`);
    return data;
  },

  /** ------------------------------------------------------------------------------
   * 
   * 03. GroupChannels (manage)
   * 
   ------------------------------------------------------------------------------ */
  postGroupChannelsChannelIdNoticeMessage: async ({
    channelId,
    messageId
  }: {
    channelId: string;
    messageId: string;
  }) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/notice-message/${messageId}`);
    return data;
  },
  deleteGroupChannelsChannelIdNoticeMessage: async ({
    channelId,
    messageId
  }: {
    channelId: string;
    messageId: string;
  }) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}/notice-message/${messageId}`);
    return data;
  },
  postGroupChannelsChannelIdOperators: async ({ channelId, operatorId }: { channelId: string; operatorId: string }) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/operators/${operatorId}`);
    return data;
  },
  deleteGroupChannelsChannelIdOperators: async ({
    channelId,
    operatorId
  }: {
    channelId: string;
    operatorId: string;
  }) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}/operators/${operatorId}`);
    return data;
  },
  /** ------------------------------------------------------------------------------
   *
   * 03. GroupChannels (moderation)
   *
   * ------------------------------------------------------------------------------ */
  postGroupChannelsChannelIdBan: async ({
    channelId,
    userId
  }: {
    channelId: string;
    userId: string;
    seconds?: number;
    description?: string;
  }) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/ban/${userId}`);
    return data;
  },
  patchGroupChannelsChannelIdBan: async ({
    channelId,
    userId,
    seconds,
    description
  }: {
    channelId: string;
    userId: string;
    seconds?: number;
    description?: string;
  }) => {
    const { data } = await chatInstance.patch(`/group-channels/${channelId}/ban/${userId}`, {
      seconds,
      description
    });
    return data;
  },
  getGroupChannelsChannelIdBannedList: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.get(`/group-channels/${channelId}/banned`);
    return data;
  },
  postGroupChannelsChannelIdMute: async ({ channelId, userId }: { channelId: string; userId: string }) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/mute/${userId}`);
    return data;
  },
  getGroupChannelsChannelIdMutedList: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.get(`/group-channels/${channelId}/muted`);
    return data;
  },
  deleteGroupChannelsChannelIdBan: async ({ channelId, userId }: { channelId: string; userId: string }) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}/ban/${userId}`);
    return data;
  },
  deleteGroupChannelsChannelIdMute: async ({ channelId, userId }: { channelId: string; userId: string }) => {
    const { data } = await chatInstance.delete(`/group-channels/${channelId}/mute/${userId}`);
    return data;
  },
  /** ------------------------------------------------------------------------------
   *
   * 03. GroupChannels (transfer)
   *
   * ------------------------------------------------------------------------------ */
  putGroupChannelsChannelIdMaster: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/master`);
    return data;
  },
  putGroupChannelsChannelIdMasterAccept: async ({ channelId }: GroupChannelParams) => {
    const { data } = await chatInstance.put(`/group-channels/${channelId}/master-accept`);
    return data;
  },
  postGroupChannelsChannelIdMaster: async ({ channelId, toUserId }: { channelId: string; toUserId: string }) => {
    const { data } = await chatInstance.post(`/group-channels/${channelId}/master/${toUserId}`);
    return data;
  },

  /**
   * 10. Users
   */
  getUserFriends: async ({ userId }: { userId: string }) => {
    const { data } = await chatInstance.get(`/users/${userId}/friends`);
    return data;
  },

  /**
   * 20. Messages
   */

  postMessagesChannelIdMessageIdLike: async ({ channelId, messageId }: { channelId: string; messageId: string }) => {
    const { data } = await chatInstance.post(`/messages/${channelId}/${messageId}/like`);
    return data;
  },
  deleteMessagesChannelIdMessageIdLike: async ({ channelId, messageId }: { channelId: string; messageId: string }) => {
    const { data } = await chatInstance.delete(`/messages/${channelId}/${messageId}/like`);
    return data;
  },
  getMessagesGroupChannelId: async ({ channelId }: { channelId: string }) => {
    const { data } = await chatInstance.get(`/messages/${channelId}`);
    return data;
  },
  postMessagesGroupChannelIdParentMessageIdReply: async ({
    channelId,
    parentMessageId,
    ...payload
  }: { channelId: string; parentMessageId: number } & UserMessageCreateParams) => {
    const { data } = await chatInstance.post(`/messages/${channelId}/${parentMessageId}/reply`, payload);
    return data;
  },
  postMessagesGroupChannelIdCheckBadWords: async ({ channelId }: { channelId: string }) => {
    const { data } = await chatInstance.post(`/messages/${channelId}/check/bad-words`);
    return data;
  },
  postMessagesGroupChannelIdSend: async ({
    channelId,
    ...payload
  }: { channelId: string } & UserMessageCreateParams) => {
    const { data } = await chatInstance.post(`/messages/${channelId}/send`, payload);
    return data;
  },
  deleteMessagesGroupChannelIdMessageId: async ({ channelId, messageId }: { channelId: string; messageId: number }) => {
    const { data } = await chatInstance.delete(`/messages/${channelId}/${messageId}`);
    return data;
  }
};

export default chatApi;
