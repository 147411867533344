import { atom } from 'recoil';

type AlertState = {
  isOpen: boolean;
  title: string;
  contents: string;
  submit?: () => void;
  cancel?: () => void;
  submitText: string;
  cancelText: string;
  submitOnly?: boolean;
  disableBackdropClick: boolean;
  backdropBlur: boolean;
};

export const alertState = atom<AlertState>({
  key: 'alertState',
  default: {
    isOpen: false,
    title: '',
    contents: '',
    submit: undefined,
    cancel: undefined,
    submitText: '',
    cancelText: '',
    submitOnly: undefined,
    disableBackdropClick: false,
    backdropBlur: false
  }
});
