import React, { MouseEventHandler, useMemo } from 'react';

import LevelCap from './LevelCap';
import TrustRing from './TrustRing';

import classNames from 'components/styled/util';

interface ProfileAvatarProps {
  userInfo: any;
  trustRing?: boolean;
  badge?: boolean;
  level?: boolean;
  size?: number | string;
  className?: string;
  onClick?: MouseEventHandler;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = (props) => {
  const { userInfo, className, onClick, trustRing = false, level = false, badge = false, size = 100 } = props;
  const {
    profile_image_url,
    ring_color: ringColor,
    grade_title: grade,
    badge_image_url: badgeImageUrl
  } = userInfo ?? {};

  const getSize = useMemo<number>(() => {
    const isNumber = typeof size === 'number';

    if (isNumber) {
      return size;
    } else {
      switch (size) {
        case 'sm':
          return 48;
        case 'md':
          return 100;
        case 'lg':
          return 200;
        default:
          return 100;
      }
    }
  }, [size]);

  return (
    <div
      style={{
        width: getSize,
        height: getSize
      }}
      className={classNames(
        'relative z-0 flex shrink-0 cursor-pointer items-center justify-center rounded-full',
        className
      )}
      onClick={onClick}
    >
      {level && (
        <div className="absolute top-[-16%] z-[3] flex justify-center">
          <LevelCap grade={grade} />
        </div>
      )}
      <div className="z-[2] flex size-[92%] items-center justify-center rounded-full">
        {trustRing && <TrustRing ringColor={ringColor} width={getSize} />}
        <img className="!size-[92%] rounded-full object-cover" src={profile_image_url} alt="profile image" />
      </div>
      {badge && (
        <div className="absolute bottom-0 right-0 z-[3] size-[32%]">
          {badgeImageUrl && <img className="!size-full object-cover" src={badgeImageUrl} alt="badge" />}
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
