import { UserMessage } from '@sendbird/chat/message';
import { selectorFamily } from 'recoil';

import { fetchMessage } from '../../libs/sendbird';
import groupChannelsState from '../atoms/groupChannelsState';

const noticesSelector = selectorFamily<UserMessage[], string>({
  key: 'noticesSelector',
  get:
    (channelUrl: string) =>
    async ({ get }) => {
      const channel = get(groupChannelsState)[channelUrl];
      if (!channel) {
        return [];
      }

      const { noticeMessageIds = [] } = channel.data;

      const messages: UserMessage[] = [];
      for (const messageId of noticeMessageIds) {
        try {
          const message = await fetchMessage(channel.url, messageId);
          if (!message) {
            continue;
          }
          messages.push(message as UserMessage);
        } catch (e) {
          console.error(e);
          continue;
        }
      }

      return messages;
    }
});

export default noticesSelector;
