import React from 'react';

import { useRecoilValue } from 'recoil';

import classNames from 'components/styled/util';
import chatRoomExpandedState from 'store/atoms/chatRoomExpandedState';

const ChatDiv = (props: React.PropsWithChildren) => {
  const { children } = props;

  const isChatRoomExpanded = useRecoilValue(chatRoomExpandedState);

  return (
    <div
      className={classNames('relative grid h-screen w-screen bg-white', {
        'grid-cols-[300px_1fr_300px]': !isChatRoomExpanded,
        'grid-cols-[300px_1fr]': isChatRoomExpanded
      })}
    >
      {children}
    </div>
  );
};

export default ChatDiv;
