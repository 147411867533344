import { sendbirdInstance } from 'apis';

type UserParams = {
  userId: string;
};

// interface SendMessagePayload extends UserMessageCreateParams {
//   channelUrl: string;
// }

const sendbirdApi = {
  getUser: async ({ userId }: UserParams) => {
    const { data } = await sendbirdInstance.get(`/users/${userId}`);
    return data;
  },
  sendMessage: async ({ channelUrl, ...payload }: any) => {
    await sendbirdInstance.post(`/group_channels/${channelUrl}/messages`, payload);
  }
};

export default sendbirdApi;
