import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { User } from '@sendbird/chat';
import { MemberState } from '@sendbird/chat/groupChannel';
import { useRecoilCallback } from 'recoil';

import useTextInputChange from '../../hooks/useTextInputChange';
import { getIsMaster, getIsStaff } from '../../libs/helper';

import ChannelSettingContainer, { ChannelSettingTab } from './components/ChannelSettingContainer';

import chatApi from 'apis/chat-api';
import { MemberDto } from 'apis/types/chat.type';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useToast from 'hooks/useToast';

import { getChannelIdByChannelUrl } from 'utils/common';

export enum ChannelSettingPermissionTab {
  CHANNEL,
  BANNED
}

interface BannedListItem {
  user?: User;
  startAt?: number;
  endAt?: number;
  description?: string;
}

const ChannelSettingPermissions: React.FC = () => {
  const { t } = useTranslation();
  const qc = useQueryClient();

  const { data: channel } = useCurrentChannel();

  const toast = useToast();
  const [memberKeyword, onChangeMemberKeyword] = useTextInputChange('', 30);
  const [banReason, onChangeBanReason, , clearBanReason, setBanReason] = useTextInputChange('');
  const [duration, setDuration] = useState<number>(-1);
  const [staffKeyword, onChangeStaffKeyword, , clearStaffKeyword] = useTextInputChange('', 30);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  // const [isOpenedHistory, setIsOpenedHistory] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ChannelSettingPermissionTab>(ChannelSettingPermissionTab.CHANNEL);

  const { data: bannedListResponse } = useQuery({
    queryKey: ['channels', channel?.channelUrl, 'bannedList'],
    queryFn: () => chatApi.getGroupChannelsChannelIdBannedList({ channelId: channel?.id || '' }),
    select: (response) => response.data,
    enabled: !!channel?.id
  });

  const { data: mutedListResponse } = useQuery({
    queryKey: ['channels', channel?.channelUrl, 'mutedList'],
    queryFn: () => chatApi.getGroupChannelsChannelIdMutedList({ channelId: channel?.id || '' }),
    select: (response) => response.data,
    enabled: !!channel?.id
  });

  const staffs = useMemo(() => {
    if (!channel) return [];

    return channel.members.filter((member) => {
      return getIsStaff({ channel, user: member });
    });
  }, [channel, staffKeyword]);

  const searchedStaffCandidates = useMemo(() => {
    if (!channel) {
      return [];
    }

    if (!staffKeyword) {
      return [];
    }

    return channel.members.filter((member) => {
      if (getIsMaster({ channel, user: member })) {
        return false;
      }

      if (getIsStaff({ channel, user: member })) {
        return false;
      }

      return member.nickname.indexOf(staffKeyword) > -1;
    });
  }, [channel, staffKeyword]);

  const bannedList: BannedListItem[] = useMemo(() => {
    if (!channel || !bannedListResponse) return [];

    return bannedListResponse.bannedList.filter(
      (bannedListItem: BannedListItem) =>
        bannedListItem.user && bannedListItem.user.nickname.indexOf(memberKeyword) > -1
    );
  }, [bannedListResponse, memberKeyword, channel]);

  const mutedList = mutedListResponse?.mutedList || [];

  const members = useMemo(() => {
    if (!channel) return [];

    return channel.members.filter((member) => {
      const isMaster = getIsMaster({ channel, user: member });

      if (isMaster) return false;

      if (member.state !== MemberState.JOINED) return false;

      return member.nickname.indexOf(memberKeyword) > -1;
    });
  }, [channel, selectedTab, memberKeyword]);

  const onAddStaff = useRecoilCallback(
    () => (userId: string) => async () => {
      if (!channel) return;
      await chatApi.postGroupChannelsChannelIdOperators({
        channelId: getChannelIdByChannelUrl(channel.channelUrl),
        operatorId: userId
      });

      qc.invalidateQueries(['channels', channel.channelUrl]);
      clearStaffKeyword();
    },
    []
  );

  const onRemoveStaff = useRecoilCallback(
    () => (userId: string) => async () => {
      if (!channel) return;
      await chatApi.deleteGroupChannelsChannelIdOperators({
        channelId: getChannelIdByChannelUrl(channel.channelUrl),
        operatorId: userId
      });
      qc.invalidateQueries(['channels', channel.channelUrl]);
    },
    [channel]
  );

  const onSelectMember = useCallback(
    (member: MemberDto | User) => () => {
      setSelectedUserId((prev) => (prev === member.userId ? null : member.userId));
    },
    []
  );

  const onSelectTab = useCallback(
    (tab: ChannelSettingPermissionTab) => () => {
      setSelectedTab(tab);
      setSelectedUserId(null);
    },
    []
  );

  const isBannedMember = useMemo(() => {
    if (!selectedUserId || !channel) return false;
    return bannedList.some((item) => item.user?.userId === selectedUserId);
  }, [channel, selectedUserId, bannedList]);

  const isSendableMember = useMemo(() => {
    if (!selectedUserId || !channel) return false;
    return !mutedList.some((item: any) => item.userId === selectedUserId);
  }, [channel, selectedUserId]);

  const onToggleSendable = async () => {
    if (isBannedMember || !channel || !selectedUserId) return;

    try {
      if (isSendableMember) {
        await chatApi.postGroupChannelsChannelIdMute({ channelId: channel.id, userId: selectedUserId });
      } else {
        await chatApi.deleteGroupChannelsChannelIdMute({ channelId: channel.id, userId: selectedUserId });
      }

      await qc.invalidateQueries(['channels', channel.channelUrl]);
    } catch (error) {
      console.error(error);
    }
  };

  const onToggleViewChannel = async () => {
    if (!channel || !selectedUserId) return;

    try {
      if (isBannedMember) {
        // unban
        await chatApi.deleteGroupChannelsChannelIdBan({ channelId: channel.id, userId: selectedUserId });
      } else {
        await chatApi.postGroupChannelsChannelIdBan({ channelId: channel.id, userId: selectedUserId });
      }

      await qc.invalidateQueries(['channels', channel.channelUrl]);
      setSelectedTab(ChannelSettingPermissionTab.BANNED);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveBanReason = async () => {
    if (!selectedUserId || !channel) return;

    try {
      const method = isBannedMember ? chatApi.patchGroupChannelsChannelIdBan : chatApi.postGroupChannelsChannelIdBan;
      await method({
        channelId: channel.id,
        userId: selectedUserId,
        description: banReason,
        seconds: duration
      });

      await qc.invalidateQueries(['channels', channel.channelUrl]);
      toast.open({
        content: t('common.alert.save.success'),
        options: { duration: 1000 }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeDuration = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!channel || !selectedUserId) return;

    try {
      const newDuration = parseInt(event.target.value, 10);
      setDuration(newDuration);

      const method = isBannedMember ? chatApi.patchGroupChannelsChannelIdBan : chatApi.postGroupChannelsChannelIdBan;

      await method({
        channelId: channel.id,
        userId: selectedUserId,
        description: banReason,
        seconds: newDuration
      });

      await qc.invalidateQueries(['channels', channel.channelUrl]);

      toast.open({
        content: t('common.alert.save.success'),
        options: { duration: 1000 }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!selectedUserId || bannedList.length === 0) return;

    const item = bannedList.find((item) => item.user?.userId === selectedUserId);

    if (item) {
      setBanReason(item.description || '');
      const duration = ((item.endAt || 0) - (item.startAt || 0)) / 1000;

      // duration이  7d 이상이면 -1로 설정 (무한)
      setDuration(duration > 60 * 60 * 24 * 7 ? -1 : duration);
    }
  }, [selectedUserId, bannedList]);

  if (!channel) return null;

  return (
    <ChannelSettingContainer tab={ChannelSettingTab.PERMISSIONS}>
      {/* Add a Staff */}
      <div className="setting_title staff">{t('chat.settingPopup.permissions.addStaff.label')}</div>
      <div className="drop_wrap">
        <div className="select active">
          <div className="selected_input">
            <div
              className={classNames({
                form_wrap: true,
                error: false
              })}
            >
              <span className="form_cell form_input input_lg search_noline">
                <input
                  type="text"
                  placeholder={t('chat.settingPopup.permissions.addStaff.placeholder')}
                  onChange={onChangeStaffKeyword}
                  value={staffKeyword}
                />
                <button className="btn_input input_search">
                  <span className="a11y">검색</span>
                </button>
              </span>
              <span className="error_txt msg">The same nickname already exists.</span>
            </div>
          </div>
          {/* 닉네임을 검색 후 클릭하면 스태프 목록에 들어오게 됩니다.  */}
          {searchedStaffCandidates.length > 0 && (
            <ul>
              {searchedStaffCandidates.map((member) => {
                return (
                  <li
                    className="option"
                    key={member.userId}
                    onClick={onAddStaff(member.userId)}
                    style={{
                      padding: '0px 15px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <dl className="search_list">
                      <dt style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={member.profileUrl} style={{ width: 32, height: 32 }} />
                      </dt>
                      <dd style={{ fontSize: 12 }}>
                        <span className="point">{member.nickname}</span>
                      </dd>
                    </dl>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
      {/* Staff가 한명 이상일 시 리스트가 나옵니다. /  한명도 없을 시 숨김 - 안내문구만 남음 */}
      <ul className="add_staff_list">
        {staffs.map((member) => (
          <li key={member.userId}>
            <dl>
              <dt>
                <img src={member.profileUrl} width={32} height={32} style={{ borderRadius: 16 }} />
              </dt>
              <dd>{member.nickname}</dd>
              <dd>
                {/* staff 삭제 시 toast 팝업 나옴 (Delete) */}
                <button type="button" className="delete" onClick={onRemoveStaff(member.userId)}>
                  <span className="a11y">삭제</span>
                </button>
              </dd>
            </dl>
          </li>
        ))}
      </ul>
      <ul className="guide popup">
        <li>{t('chat.settingPopup.permissions.addStaff.guide')}</li>
      </ul>
      <div className="setting_title member">{t('chat.settingPopup.permissions.members.label')}</div>
      {/* Input Search */}
      <div className="form_wrap">
        <span className="form_cell form_input input_lg search_noline">
          <input
            type="text"
            placeholder={t('chat.settingPopup.permissions.members.placeholder')}
            onChange={onChangeMemberKeyword}
            value={memberKeyword}
          />
          <button className="btn_input input_search">
            <span className="a11y">검색</span>
          </button>
        </span>
      </div>
      <div className="setting_member">
        <div>
          <div className="channel tabs">
            <div className="tabs_menu size_sm">
              <button
                type="button"
                className={classNames({
                  active: selectedTab === ChannelSettingPermissionTab.CHANNEL
                })}
                onClick={onSelectTab(ChannelSettingPermissionTab.CHANNEL)}
              >
                <span>{t('chat.settingPopup.permissions.membersTab.channel')}</span>
              </button>
              <button
                type="button"
                className={classNames({
                  active: selectedTab === ChannelSettingPermissionTab.BANNED
                })}
                onClick={onSelectTab(ChannelSettingPermissionTab.BANNED)}
              >
                <span>{t('chat.settingPopup.permissions.membersTab.banned')}</span>
              </button>
            </div>
          </div>
          <div className="member_tab01">
            {/* member 클릭시 (active class 추가 시) 해당 멤버에 대한 권한 팝업 */}
            {selectedUserId && (
              <div className="permission">
                {/* <div
                  className={classNames({
                    history_pop: true,
                    // active: isOpenedHistory === true,
                  })}
                >
                  <div className="history_title">
                    <span className="history_title_text">History</span>
                    <button type="button" className="pop_close">
                      <span className="a11y">닫기</span>
                    </button>
                  </div>
                  <div className="history_con">
                    <div className="history_box">
                      <div className="history_date">
                        <span className="status">OFF</span>
                        <span className="date">Sep. 14, 2022 23:31:02</span>
                      </div>
                      <div className="text">
                        <span>username</span> Cursing and rioting at the
                        staff.
                      </div>
                      <div className="due">Due date : indefinite</div>
                    </div>
                    <div className="history_box">
                      <div className="history_date">
                        <span className="status">OFF</span>
                        <span className="date">Sep. 14, 2022 23:31:02</span>
                      </div>
                      <div className="text">
                        <span>username</span> Cursing and rioting at the
                        staff.
                      </div>
                      <div className="due">Due date : indefinite</div>
                    </div>
                    <div className="history_box">
                      <div className="history_date">
                        <span className="status">OFF</span>
                        <span className="date">Sep. 14, 2022 23:31:02</span>
                      </div>
                      <div className="text">
                        <span>username</span> Cursing and rioting at the
                        staff.
                      </div>
                      <div className="due">Due date : indefinite</div>
                    </div>
                  </div>
                </div> */}
                <div className="permission_title">{t('chat.settingPopup.channelPopup.title')}</div>
                <div className="permission_con" style={{ width: '100%' }}>
                  <div className="permission_option">
                    <div className="permission_option_title">
                      <span className="permission_option_text">
                        {t('chat.settingPopup.channelPopup.viewChannel.label')}
                      </span>
                      <div className="form_wrap">
                        <span className="form_cell type_toggle">
                          <input type="checkbox" id="check9" checked={!isBannedMember} onChange={onToggleViewChannel} />
                          <label htmlFor="check9">
                            <span className="a11y">Switch</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="permission_option_box">{t('chat.settingPopup.channelPopup.viewChannel.guide')}</div>
                    {/* S : Folding */}
                    {/* 펼쳐질 때 view_channel에 show class를 추가해주세요 */}
                    <div
                      className={classNames({
                        view_channel: true,
                        show: isBannedMember
                      })}
                    >
                      <div className="view_channel_title">{t('chat.settingPopup.channelPopup.banned.guide')}</div>
                      {/*Radio 버튼입니다. 한개만 선택 됩니다. */}
                      <div className="radio_button">
                        <div className="radio_wrap">
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 5}
                              id="radio1"
                              checked={duration === 60 * 5}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio1">
                              <span>5m</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 30}
                              id="radio2"
                              checked={duration === 60 * 30}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio2">
                              <span>30m</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 60}
                              id="radio3"
                              checked={duration === 60 * 60}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio3">
                              <span>1h</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 60 * 6}
                              id="radio4"
                              checked={duration === 60 * 60 * 6}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio4">
                              <span>6h</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 60 * 24}
                              id="radio5"
                              checked={duration === 60 * 60 * 24}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio5">
                              <span>1d</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={60 * 60 * 24 * 7}
                              id="radio6"
                              checked={duration === 60 * 60 * 24 * 7}
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio6">
                              <span>7d</span>
                            </label>
                          </span>
                          <span className="form_cell btn_radio size_sm">
                            <input
                              type="radio"
                              name="duration"
                              value={-1}
                              checked={duration === -1}
                              id="radio7"
                              onChange={onChangeDuration}
                            />
                            <label htmlFor="radio7">
                              <span />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="view_channel_title">{t('chat.settingPopup.channelPopup.banned.reason')}</div>
                      <div className="form_wrap flex items-center">
                        <span className="form_cell form_input input_sm">
                          <input
                            type="text"
                            placeholder={t('chat.settingPopup.channelPopup.banned.reasonPlaceholder')}
                            value={banReason}
                            onChange={onChangeBanReason}
                          />
                          <button className="btn_reset" onClick={clearBanReason}>
                            <span className="a11y">삭제</span>
                          </button>
                        </span>
                        <button type="button" className="btn primary button_sm" onClick={onSaveBanReason}>
                          <span>{t('common.button.save')}</span>
                        </button>
                      </div>
                    </div>
                    {/* E : Folding */}
                  </div>

                  <div
                    className={classNames('permission_option', {
                      'opacity-30 cursor-not-allowed': isBannedMember
                    })}
                  >
                    <div className="permission_option_title">
                      <span className="permission_option_text">
                        {t('chat.settingPopup.channelPopup.sendMessage.label')}
                      </span>
                      <div className="form_wrap">
                        <span className="form_cell type_toggle">
                          <input type="checkbox" id="check11" checked={isSendableMember} onChange={onToggleSendable} />
                          <label htmlFor="check11">
                            <span className="a11y">Switch</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="permission_option_box">
                      {t('chat.settingPopup.channelPopup.sendMessage.guide')}
                      {/* 버튼 클릭
                      시 active class가 추가되며 해당 팝업이
                      보여집니다.(.history_pop) */}
                      {/* <button
                        type="button"
                        className="history"
                        onClick={onToggleHistory}
                      >
                        <span>History</span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === ChannelSettingPermissionTab.CHANNEL && (
              <>
                {/* 검색결과가 없을 경우 */}
                {members.length === 0 && (
                  <div className="no_result" style={{ textAlign: 'center' }}>
                    {t('chat.settingPopup.permissions.channelTab.noResult')}
                  </div>
                )}
                {/* 검색결과가 있을 경우 */}
                {members.length > 0 && (
                  <div className="member_list_div">
                    {/* master인 경우 master class가 추가됩니다.*/}
                    {/* Staff가 Master 클릭 시 Alert(You do not have permission. / Button : Confirm ) */}
                    {/* click 했을 경우 active class가 추가됩니다.*/}
                    {members.map((member) => (
                      <div
                        className={classNames({
                          member_list: true,
                          master: getIsMaster({ channel, user: member }),
                          active: selectedUserId === member.userId
                        })}
                        key={member.userId}
                        onClick={onSelectMember(member)}
                      >
                        <img src={member.profileUrl} />
                        <div className="nick">{member.nickname}</div>
                        {/* <div className="status on" /> */}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {selectedTab === ChannelSettingPermissionTab.BANNED && (
              <>
                {/* 검색결과가 없을 경우 */}
                {bannedList.length === 0 && (
                  <div className="no_result" style={{ textAlign: 'center' }}>
                    {t('chat.settingPopup.permissions.channelTab.noResult')}
                  </div>
                )}
                {/* 검색결과가 있을 경우 */}
                {bannedList.length > 0 && (
                  <div className="member_list_div">
                    {/* master인 경우 master class가 추가됩니다.*/}
                    {/* Staff가 Master 클릭 시 Alert(You do not have permission. / Button : Confirm ) */}
                    {/* click 했을 경우 active class가 추가됩니다.*/}
                    {bannedList.map((item) => {
                      if (!item.user) return null;
                      return (
                        <div
                          className={classNames({
                            member_list: true,
                            master: getIsMaster({ channel, user: item.user }),
                            active: selectedUserId === item.user.userId
                          })}
                          key={item.user.userId}
                          onClick={onSelectMember(item.user)}
                        >
                          <img src={item.user.profileUrl} />
                          <div className="nick">{item.user.nickname}</div>
                          {/* <div className="status on" /> */}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ChannelSettingContainer>
  );
};

export default ChannelSettingPermissions;
