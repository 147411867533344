import React, { HTMLAttributes, HTMLInputTypeAttribute } from 'react';

import styled, { css } from 'styled-components';

import mixin from '../styled/mixin';

type SwitchSize = 'sm' | 'md' | 'lg';

interface SwitchProps {
  isChecked?: boolean;
  onToggle?: () => void;
  size?: SwitchSize;
  disabled?: boolean;
}

const Switch: React.FC<SwitchProps> = (props) => {
  const { isChecked = false, onToggle = () => {}, size = 'md', disabled = false } = props;

  return (
    <StyledSwitchLabel $size={size}>
      <StyledSwitchInput $size={size} type="checkbox" checked={isChecked} onChange={onToggle} disabled={disabled} />
      <StyledSwitchSlider $size={size} />
    </StyledSwitchLabel>
  );
};

export default Switch;

interface StyledSwitchLabelProps extends HTMLAttributes<HTMLLabelElement> {
  $size: SwitchSize;
}
interface StyledSwitchInputProps extends HTMLAttributes<HTMLInputElement> {
  type: HTMLInputTypeAttribute;
  checked: boolean;
  disabled: boolean;
  $size: SwitchSize;
}

const StyledSwitchLabel = styled.label<StyledSwitchLabelProps>`
  position: relative;
  display: inline-block;

  ${({ $size }) => {
    switch ($size) {
      case 'lg':
        return css`
          width: 50px;
          height: 28px;
        `;
      case 'md':
        return css`
          width: 42px;
          height: 24px;
        `;
      case 'sm':
        return css`
          width: 34px;
          height: 20px;
        `;
    }
  }}
`;

const StyledSwitchInput = styled.input<StyledSwitchInputProps>`
  opacity: 0;
  width: 0;
  height: 0;

  &:hover:not(:disabled) + span {
    cursor: pointer;
  }

  &:checked + span {
    background-color: ${({ theme }) => theme.colors.primary100};
    border: 1px solid ${({ theme }) => theme.colors.primary200};
  }

  &:checked + span:before {
    ${({ $size }) => {
      switch ($size) {
        case 'lg':
          return css`
            transform: translateX(22px);
          `;
        case 'md':
          return css`
            transform: translateX(18px);
          `;
        case 'sm':
          return css`
            transform: translateX(14px);
          `;
      }
    }}
  }

  &:disabled + span {
    opacity: 0.3;
  }

  &:disabled:checked + span {
    opacity: 0.2;
  }
`;

const StyledSwitchSlider = styled.span<StyledSwitchLabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.gray200};
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 24px;
  ${mixin.transitionMixin}

  &:before {
    position: absolute;
    content: '';

    ${({ $size }) => {
      switch ($size) {
        case 'lg':
          return css`
            height: 22px;
            width: 22px;
          `;
        case 'md':
          return css`
            height: 18px;
            width: 18px;
          `;
        case 'sm':
          return css`
            height: 14px;
            width: 14px;
          `;
      }
    }}

    top: 2px;
    left: 2px;
    background-color: white;
    border-radius: 50%;

    ${mixin.transitionMixin}
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
  }
`;
