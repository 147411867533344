import { RefObject } from 'react';
import { useQuery } from 'react-query';

import useInfiniteScroll from './use-infinite-scroll';

import sendCoinApi, { sendCoinQueryKey } from 'apis/send-coin';

export const useGetWalletUserQuery = (params: any, containerRef: RefObject<HTMLElement>) => {
  return useInfiniteScroll({
    queryKey: sendCoinQueryKey.user({
      ...params
    }),
    queryFn: ({ pageParam = 1 }) => sendCoinApi.getUserList({ ...params, page: pageParam }),
    keepPreviousData: true,
    inViewOptions: {
      threshold: 0.1,
      root: containerRef?.current,
      rootMargin: '300px'
    }
  });
};

export const useWalletAddressesQuery = ({ channelId, ...params }: any & { channelId?: string }) => {
  return useQuery({
    queryKey: sendCoinQueryKey.group({ channelId, ...params }),
    queryFn: () => sendCoinApi.getUsersWalletAddresses({ channel: channelId, ...params }),
    select: (response) => {
      return response.data as { id: number; wallet_address: string }[];
    },
    enabled: !!channelId
  });
};
