import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { User } from '@sendbird/chat';
import dayjs from 'dayjs';

import { CustomMessageType } from '../../../types/common';

import { ChannelType, GroupChannelDto, MemberDto } from 'apis/types/chat.type';
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark-star.svg';
import { ReactComponent as DefaultProfileImage } from 'assets/images/default_user_profile_01.svg';
import classNames from 'components/styled/util';

import useNewGroupChannel from 'hooks/use-group-channel';
import useUserMe from 'hooks/use-user-me';

import { getChannelIdByChannelUrl } from 'utils/common';

// import useGroupChannel from 'hooks/useGroupChannel';

interface ChannelThumbnailProps {
  channel: GroupChannelDto;
}

const ChannelThumbnail: React.FC<ChannelThumbnailProps> = (props) => {
  const { channel } = props;

  const myInfo = useUserMe();
  // const { leaveChannel } = useGroupChannel();

  if (channel.customType === ChannelType.CLUB) {
    return <img className="size-full rounded-br-4 rounded-tl-4 object-cover" src={channel.coverUrl} />;
  }
  if (channel.customType === ChannelType.GROUP) {
    return <img className="size-full rounded-3 object-cover" src={channel.coverUrl} />;
  }
  const members = channel.members.filter(
    (member) => Number(member.userId) !== myInfo?.id && !member.userId.includes('Guest')
  );
  if (members.length === 0) {
    // leaveChannel(channel.url, myInfo?.id?.toString());
    return <DefaultProfileImage className="size-full" />;
  }

  return <img src={members?.[0]?.profileUrl} alt="profile image" className="size-full rounded-full object-cover" />;
};

const BookmarkButton: React.FC<{
  className?: string;
  isBookmarked: boolean;
  onClick: MouseEventHandler<HTMLOrSVGElement>;
}> = (props) => {
  const { className = '', isBookmarked, onClick } = props;
  return (
    <BookmarkIcon
      className={classNames(
        'cursor-pointer [&>*]:transition-colors',
        {
          '[&>.bookmark-background]:text-primary-100 [&>.bookmark-shadow]:text-[#710fc8] [&>.bookmark-star-shadow]:text-[#6311ac]':
            isBookmarked,
          '[&>.bookmark-background]:text-[#cdccd2] [&>.bookmark-shadow]:text-[#b2b2bc] [&>.bookmark-star-shadow]:text-[#b2b2bc]':
            !isBookmarked
        },
        className
      )}
      onClick={onClick}
    />
  );
};

export enum FilterType {
  ON_CHAT,
  CLUBS,
  ALL
}

type Props = {
  isNow?: boolean;
  currentUser: User;
  onChange: () => void;
  channel: GroupChannelDto;
};

const ChannelListItem: React.FC<Props> = ({ isNow = false, currentUser, channel, onChange }) => {
  const myInfo = useUserMe();
  const { t } = useTranslation();

  const { addBookmark, removeBookmark } = useNewGroupChannel();

  const lastMessageTime = channel.lastMessage?.createdAt;
  const diffBetweenLastMessage = useMemo(() => {
    const diffSecond = dayjs().diff(dayjs(lastMessageTime), 'second');
    if (diffSecond < 60) {
      return `${diffSecond}s`;
    }
    if (diffSecond < 60 * 60) {
      return `${Math.floor(diffSecond / 60)}m`;
    }
    if (diffSecond < 60 * 60 * 24) {
      return `${Math.floor(diffSecond / 60 / 60)}h`;
    }

    return dayjs(lastMessageTime).format('MMM. D');
  }, [lastMessageTime]);

  const onClick = useCallback(() => {
    onChange();
  }, [onChange]);

  const lastMessage = useMemo(() => {
    if (channel.lastMessage?.customType === CustomMessageType.VIDEO) {
      return CustomMessageType.VIDEO;
    }

    if (channel.lastMessage?.customType === CustomMessageType.FILE) {
      return CustomMessageType.FILE;
    }

    if (channel.lastMessage?.customType === CustomMessageType.IMAGE) {
      return CustomMessageType.IMAGE;
    }

    if (channel.lastMessage?.customType === CustomMessageType.INVITATION) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.MASTER_TRANSFER) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.SYSTEM) {
      return null;
    }

    if (channel.lastMessage?.customType === CustomMessageType.JOIN) {
      return (
        <Trans i18nKey="common.globalChat.joinMsg.text" values={{ username: channel.lastMessage?.message }}>
          <span className="font-medium" />
        </Trans>
      );
    }

    if (channel.lastMessage?.customType === CustomMessageType.LEAVE) {
      return (
        <Trans i18nKey="common.globalChat.leaveMsg.text" values={{ username: channel.lastMessage?.message }}>
          <span className="font-medium" />
        </Trans>
      );
    }

    return channel.lastMessage?.message;
  }, [channel.lastMessage]);

  const isBookMarked = useMemo(() => {
    return channel.bookmarkUserIds?.indexOf(currentUser.userId) > -1;
  }, [channel.bookmarkUserIds, currentUser]);

  const channelTitle = useMemo(() => {
    if (channel.customType === ChannelType.GROUP || channel.customType === ChannelType.CLUB) {
      return channel.name;
    }

    const filteredMembers = channel.members.filter(
      (member: MemberDto) => Number(member.userId) !== myInfo?.id && !member.userId.includes('Guest')
    );

    if (filteredMembers.length === 0) {
      return `(${t('chat.dmChannelInfo.title.unknown')})`;
    }

    return filteredMembers.map((member: MemberDto) => member.nickname).join(', ');
  }, [channel.members, myInfo?.id]);

  const handleToggleBookmark = useCallback(() => {
    if (isBookMarked) {
      removeBookmark(getChannelIdByChannelUrl(channel.channelUrl));
    } else {
      addBookmark(getChannelIdByChannelUrl(channel.channelUrl));
    }
  }, [isBookMarked, addBookmark, removeBookmark]);

  return (
    <div
      className={classNames('flex h-[88px] w-full cursor-pointer gap-3 px-5 py-2.5 transition-all', {
        'bg-primary-100/5': isNow,
        'hover:bg-gray-100': !isNow
      })}
      onClick={onClick}
    >
      <div className="relative size-[68px] shrink-0">
        <ChannelThumbnail channel={channel} />

        {channel.customType !== ChannelType.DM && (
          <BookmarkButton
            isBookmarked={isBookMarked}
            onClick={(e) => {
              e.stopPropagation();
              handleToggleBookmark();
            }}
            className="absolute right-0.5 top-0 size-5"
          />
        )}

        {!!channel.unreadMessageCount && (
          <span className="absolute bottom-1 left-1 flex h-4 items-center justify-center rounded-2 bg-point-100 px-1 text-11 font-medium text-white">
            {channel.unreadMessageCount}
          </span>
        )}
      </div>
      <div className="flex w-full flex-col gap-0.5 py-0.5">
        <div className="flex w-full items-center justify-between gap-2">
          <p
            className={classNames('line-clamp-1 h-6 break-all text-16 font-semibold text-black-body', {
              'text-primary-100': isNow
            })}
          >
            {channelTitle}
          </p>
          <span className="shrink-0 text-14 text-gray-500">{diffBetweenLastMessage}</span>
        </div>
        <div className="line-clamp-2 w-full break-all text-13 text-gray-700">{lastMessage}</div>
      </div>
    </div>
  );
};
export default ChannelListItem;
