import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const TAILWINDCSS_DEFAULT_SPACING = [
  '0',
  'px',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
  '24',
  '28',
  '32',
  '36',
  '40',
  '44',
  '48',
  '52',
  '56',
  '60',
  '64',
  '72',
  '80',
  '96'
];

const twMerge = extendTailwindMerge({
  prefix: '',
  extend: {
    classGroups: {
      'font-size': [
        { text: ['11', '12', '13', '14', '15', '16', '17', '18', '20', '21', '22', '24', '26', '32', '44'] }
      ],
      rounded: [
        {
          rounded: TAILWINDCSS_DEFAULT_SPACING
        }
      ]
    }
  }
});

function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export default classNames;
