import React, { SVGProps, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MemberState } from '@sendbird/chat/groupChannel';
import { useRecoilCallback } from 'recoil';

import ChannelInfoMemberList from './ChannelInfoMemberList';
import ChannelInfoMemberSearchList from './ChannelInfoMemberSearchList';
import ChatRoomInfoNoticeList from './ChatRoomInfoNoticeList';
import ChatRoomSharedFileList from './ChatRoomSharedFileList';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/ic-chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/ic-search.svg';
import { ReactComponent as BookmarkIcon } from 'assets/icons/post-bookmark.svg';
import Input from 'components/jayden/Input';
import classNames from 'components/styled/util';
import chatRoomExpandedState from 'store/atoms/chatRoomExpandedState';

import { useCurrentChannel } from 'hooks/use-current-channel';
import useNewGroupChannel from 'hooks/use-group-channel';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetWindowHeight } from 'hooks/useGetWindowHeight';
import useMessageInput from 'hooks/useMessageInput';
import useTextInputChange from 'hooks/useTextInputChange';

interface BookmarkButtonProps extends SVGProps<SVGSVGElement> {
  isBookmarked: boolean;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = (props) => {
  const { isBookmarked, className = '', ...nativeProps } = props;

  return (
    <BookmarkIcon
      className={classNames(
        'size-6 cursor-pointer [&>*]:transition-all [&>.bookmark]:stroke-[2.5px]',
        {
          '[&>.bookmark]:fill-primary-100 [&>.bookmark]:stroke-primary-200 [&>.star]:opacity-100': isBookmarked
        },
        {
          '[&>.bookmark]:stroke-black-title [&>.star]:opacity-0 [&>.bookmark]:fill-transparent': !isBookmarked
        },
        className
      )}
      {...nativeProps}
    />
  );
};

const ChannelInfo = () => {
  const { channelUrl } = useParams();
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();

  const { data: newChannel } = useCurrentChannel();
  const channel = newChannel;

  // const { show, close } = useAlert();
  const { addBookmark, removeBookmark } = useNewGroupChannel();

  const { closeMemberSearch } = useMessageInput();
  const [isExpandedIntro, setIsExpandedIntro] = useState(true);
  const [isExpandedMemberList, setIsExpandedMemberList] = useState(false);

  const [keyword, onChangeKeyword, , clearKeyword] = useTextInputChange('', 30);
  const onCloseMemberSearch = () => clearKeyword();

  const memberList = useMemo(() => {
    if (!newChannel) return [];

    // 마스터가 맨 위로 오도록 정렬
    const sortedMembers = newChannel.members.sort((a, b) => {
      if (newChannel.masterIds.includes(a.userId)) return -1;
      if (newChannel.masterIds.includes(b.userId)) return 1;
      return 0;
    });

    if (!isExpandedMemberList) {
      return sortedMembers.slice(0, 4);
    }

    return sortedMembers;
  }, [newChannel, isExpandedMemberList]);

  const searchedMemberList = useMemo(() => {
    if (!keyword || !newChannel) {
      return [];
    }

    return newChannel.members.filter(
      (member) => member.nickname.indexOf(keyword) > -1 && member.state === MemberState.JOINED
    );
  }, [keyword, newChannel]);

  const isBookMarked = useMemo(() => {
    if (!channel || !currentUser?.userId) return false;

    return channel.bookmarkUserIds?.indexOf(currentUser.userId) > -1;
  }, [channel, currentUser]);

  const onToggleBookMark = useCallback(async () => {
    if (!channel) return;

    if (isBookMarked) {
      await removeBookmark(channel.id);
    } else {
      await addBookmark(channel.id);
    }
  }, [channel, isBookMarked]);

  const onFocusKeyword = useCallback(async () => {
    closeMemberSearch();
  }, []);

  const onExpand = useRecoilCallback(
    ({ set }) =>
      () => {
        set(chatRoomExpandedState, true);
      },
    []
  );

  useEffect(() => {
    clearKeyword();
    setIsExpandedMemberList(false);
  }, [channelUrl]);

  const { height } = useGetWindowHeight();

  if (!channelUrl || !newChannel || !channel) return null;

  return (
    <div className="h-full w-[300px] px-5 pt-5">
      <div className="flex items-center justify-between pb-5">
        <div className="flex items-center gap-1 truncate">
          <button type="button" onClick={onToggleBookMark}>
            <BookmarkButton isBookmarked={isBookMarked} />
          </button>
          <div className="truncate text-18 font-semibold text-black-title">{newChannel.name}</div>
        </div>
        <button type="button" className="size-6" onClick={onExpand}>
          <CloseIcon className="size-6" />
        </button>
      </div>
      <div
        className="-mr-5 flex h-full flex-col gap-4 !overflow-y-scroll pr-5"
        style={{
          maxHeight: height - 44 - 40
        }}
      >
        {/* S : Introduction */}
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-1.5">
            <span className="text-13 font-medium text-gray-600">{t('chat.channelInfo.introduction.label')}</span>
            <button
              type="button"
              className="size-6 p-1"
              onClick={() => {
                setIsExpandedIntro(!isExpandedIntro);
              }}
            >
              <ChevronDownIcon
                className={classNames('size-4 text-gray-600 transition-all hover:text-black-title', {
                  'rotate-180': isExpandedIntro
                })}
              />
            </button>
          </div>

          {/* 소개글 수정은 방장이 채팅방 설정에서 가능 */}
          <div
            className={classNames('whitespace-pre-wrap break-all text-14 font-medium text-black-body', {
              'line-clamp-2 h-full': !isExpandedIntro,
              '!h-0': !isExpandedIntro
            })}
          >
            {newChannel.introduction}
          </div>
        </div>

        <div className="h-[1px] w-full bg-gray-300" />

        {/* E : Introduction */}

        <ChatRoomInfoNoticeList />

        {/* S : Member */}
        <div className="">
          <div className="flex items-center gap-1.5">
            <span className="text-13 font-medium text-gray-600">
              {t('chat.channelInfo.members.label')} - {newChannel.memberCount}
            </span>
            <button
              type="button"
              className="size-6 p-1"
              onClick={() => {
                setIsExpandedMemberList(!isExpandedMemberList);
              }}
            >
              <ChevronDownIcon
                className={classNames('size-4 text-gray-600 transition-all hover:text-black-title', {
                  'rotate-180': isExpandedMemberList
                })}
              />
            </button>
          </div>
          <div className="mb-4 mt-3">
            {/* 멤버리스트 펼쳤을 때 나오는 검색영역입니다. (접혔을때는 검색없이 멤버 4명만 나옵니다.) */}
            {/* 채널 첫 화면에서는 검색창 없이 master만 표시됩니다. 이후 Join하는 멤버들이 하단에 리스트로 쌓이면서 검색영역이 활성화 됩니다. */}
            {isExpandedMemberList && (
              <div className="mb-4">
                <div className="w-[260px]">
                  <div className={classNames('relative', {})}>
                    <Input
                      type="text"
                      className="peer"
                      placeholder={t('chat.channelInfo.members.search.placeholder')}
                      onChange={onChangeKeyword}
                      onFocus={onFocusKeyword}
                      value={keyword}
                      suffix={
                        <SearchIcon className="absolute right-3 top-1/2 inline-block size-6 -translate-y-1/2 cursor-pointer text-gray-500 transition-all peer-focus:text-black-body" />
                      }
                    />
                    <ChannelInfoMemberSearchList
                      searchedMemberList={searchedMemberList}
                      keyword={keyword}
                      onClose={onCloseMemberSearch}
                    />
                  </div>
                </div>
              </div>
            )}
            <ChannelInfoMemberList memberList={memberList} />
          </div>
        </div>
        {/* E : Member */}

        <ChatRoomSharedFileList />
      </div>
    </div>
  );
};

export default ChannelInfo;
