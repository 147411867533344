import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilState } from 'recoil';

import alertsState, { AlertState } from '../store/atoms/alertsState';

const useAlert = () => {
  const [alertsStateValue, setAlertsStateValue] = useRecoilState(alertsState);
  const { t } = useTranslation();

  const show = useCallback((params: AlertState) => {
    setAlertsStateValue((prev) => {
      const isDuplicated = prev.find((item) => params.type === item.type);
      if (params.type != null && isDuplicated) return prev;
      return [...prev, params];
    });
  }, []);

  const close = useCallback(() => {
    setAlertsStateValue(alertsStateValue.slice(0, alertsStateValue.length - 1));
  }, [alertsStateValue]);

  const showConfirm = useCallback(
    (content: string | ReactElement) => {
      show({
        content: content,
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ]
      });
    },
    [t]
  );

  return {
    show,
    close,
    alerts: alertsStateValue,
    showConfirm
  };
};

export default useAlert;
