import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import type { IntersectionOptions } from 'react-intersection-observer';
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQueryClient
} from 'react-query';

import { DjangoListResponse } from 'apis/types/django';
interface UseInfiniteScrollParams<T> extends Omit<UseInfiniteQueryOptions, 'select'> {
  inViewOptions?: IntersectionOptions;
  select?: (response: InfiniteData<DjangoListResponse<T>>) => any;
}

export type UseInfiniteScrollResult<T> = Omit<UseInfiniteQueryResult, 'data'> & {
  observerRef?: (node?: Element | null | undefined) => void;
  totalCount?: number;
  data: T[];
};

const useInfiniteScroll = <T>({
  inViewOptions,
  queryKey,
  queryFn,
  select,
  ...queryOptions
}: UseInfiniteScrollParams<T>): UseInfiniteScrollResult<T> => {
  const { ref, inView } = useInView(inViewOptions);
  const queryClient = useQueryClient();

  const infiniteQuery = useInfiniteQuery<DjangoListResponse<T>, unknown, DjangoListResponse<T>, any>({
    queryKey,
    queryFn,
    getNextPageParam: (lastPage: DjangoListResponse<T>, allPage: DjangoListResponse<T>[]) => {
      if (!lastPage.next) {
        return null;
      }
      return allPage.length + 1;
    },
    ...queryOptions
  } as UseInfiniteQueryOptions);

  const count: number = (queryClient.getQueryData(queryKey as QueryKey) as any)?.pages?.[0]?.count ?? 0;

  useEffect(() => {
    const { isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQuery;
    if (inView && !isFetchingNextPage && !isLoading && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const selectedData = useMemo(() => {
    if (!infiniteQuery.data) {
      return [];
    }

    if (select) {
      return select(infiniteQuery.data);
    }

    return infiniteQuery.data?.pages?.flatMap((page) => page.data) ?? [];
  }, [infiniteQuery.data, select]);

  return {
    observerRef: ref,
    totalCount: count,
    ...infiniteQuery,
    data: selectedData
  };
};

export default useInfiniteScroll;
