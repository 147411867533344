import { commonInstance } from 'apis';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import { DjangoInterface } from 'libs/apis/response.type';

const userApi = {
  userMe: async () => {
    const { data } = await commonInstance.get<DjangoInterface<SuperClubUserInterface>>('user/me');
    return data;
  },
  getUserById: async ({ userId }: { userId: number }) => {
    const { data } = await commonInstance.get<DjangoInterface<SuperClubUserInterface>>(`user/${userId}`);
    return data;
  }
};

export default userApi;
