import { type AxiosRequestConfig } from 'axios';

import { SuperClubUserInterface } from '../ApiRepository';

import { chatAxios, clubAxios, forumAxios, superClubAxios, voteAxios } from './axios.config';
import {
  LocusMeetingListParams,
  PostLocusMeetingsPayload,
  PostReportChannelRequestInterface,
  PostReportMessageRequestInterface,
  VoteParamsInterface
} from './request.type';
import { DjangoInterface, LocusMeeting, ReportChoiceInterface, VoteInterface } from './response.type';

const getVoteById = async (
  id: number,
  config?: AxiosRequestConfig,
  params?: VoteParamsInterface
): Promise<DjangoInterface<VoteInterface>> => {
  const response = await voteAxios.get<DjangoInterface<VoteInterface>>(`/vote/${id}`, { ...config, params });

  return response.data;
};

const postClubAgendaApproval = async (
  id: number,
  data?: any,
  config?: AxiosRequestConfig
): Promise<DjangoInterface<any>> => {
  const response = await clubAxios.post<DjangoInterface<any>>(`/admin/agenda/${id}/approval`, data, { ...config });

  return response.data;
};

const postClubAgendaDisapproval = async (id: number): Promise<DjangoInterface<any>> => {
  const response = await clubAxios.post<DjangoInterface<any>>(`/admin/agenda/${id}/disapproval`);

  return response.data;
};

const postForumAgendaApproval = async (id: number): Promise<DjangoInterface<any>> => {
  const response = await forumAxios.post<DjangoInterface<any>>(`/admin/agenda/${id}/approval`);

  return response.data;
};

const postForumAgendaDisapproval = async (id: number): Promise<DjangoInterface<any>> => {
  const response = await forumAxios.post<DjangoInterface<any>>(`/admin/agenda/${id}/disapproval`);

  return response.data;
};

const getReportChoices = async (): Promise<DjangoInterface<ReportChoiceInterface[]>> => {
  const response = await superClubAxios.get<DjangoInterface<ReportChoiceInterface[]>>(`/report-choices`);

  return response.data;
};

const postReportChannel = async (data: PostReportChannelRequestInterface): Promise<DjangoInterface<any>> => {
  const response = await chatAxios.post<DjangoInterface<any>>(`/report/channel`, data);

  return response.data;
};

const postReportMessage = async (data: PostReportMessageRequestInterface): Promise<DjangoInterface<any>> => {
  const response = await chatAxios.post<DjangoInterface<any>>(`/report/message`, data);

  return response.data;
};

const patchUserById = async (props: { id: number; data: { description: string } }): Promise<DjangoInterface<any>> => {
  const response = await superClubAxios.patch<DjangoInterface<any>>(`/user/${props.id}`, props.data);

  return response.data;
};

const postUserNickname = async (props: { id: number; data: { nickname: string } }): Promise<DjangoInterface<any>> => {
  const response = await superClubAxios.post(`user/${props.id}/nickname`, props.data);
  return response.data;
};

const getUserById = async ({ id }: { id: number }): Promise<DjangoInterface<SuperClubUserInterface>> => {
  const response = await superClubAxios.get<DjangoInterface<SuperClubUserInterface>>(`/user/${id}`);

  return response.data;
};

const getMyClubIdList = async (): Promise<DjangoInterface<{ id: number }[]>> => {
  const response = await clubAxios.get<DjangoInterface<{ id: number }[]>>(`/clubs`, {
    params: { fields: 'id', is_joined: true }
  });

  return response.data;
};

const getLocusMeetingList = async (params?: LocusMeetingListParams) => {
  const response = await chatAxios.get<DjangoInterface<LocusMeeting[]>>(`/locus-meetings`, { params });

  return response.data;
};

const postLocusMeetings = async (payload: PostLocusMeetingsPayload) => {
  const response = await chatAxios.post<DjangoInterface<LocusMeeting>>(`/locus-meetings`, payload);

  return response.data;
};

const postLocusMeetingsBookmark = async ({ channelId }: { channelId: string }) => {
  const response = await chatAxios.post<DjangoInterface<LocusMeeting>>(`/locus-meetings/${channelId}/bookmark`);

  return response.data;
};

const postLocusMeetingsUnBookmark = async ({ channelId }: { channelId: string }) => {
  const response = await chatAxios.post<DjangoInterface<LocusMeeting>>(`/locus-meetings/${channelId}/unbookmark`);

  return response.data;
};

const postLocusMeetingsJoin = async ({ channelId }: { channelId: string }) => {
  const response = await chatAxios.post<DjangoInterface<LocusMeeting>>(`/locus-meetings/${channelId}/join`);

  return response.data;
};

const getInviteMessageList = async () => {
  const response = await chatAxios.get<DjangoInterface<{ id: string; content: string }[]>>(`/invite-messages`);

  return response.data;
};

const postInviteMessage = async (payload: { content: string }) => {
  const response = await chatAxios.post<DjangoInterface<{ id: string; content: string }>>(`/invite-messages`, payload);
  return response.data;
};

export default {
  getVoteById,
  postClubAgendaApproval,
  postClubAgendaDisapproval,
  postForumAgendaApproval,
  postForumAgendaDisapproval,
  getReportChoices,
  postReportChannel,
  postReportMessage,
  patchUserById,
  postUserNickname,
  getUserById,
  getMyClubIdList,
  getLocusMeetingList,
  postLocusMeetings,
  postLocusMeetingsBookmark,
  postLocusMeetingsUnBookmark,
  postLocusMeetingsJoin,
  getInviteMessageList,
  postInviteMessage
};

// POST / admin / agenda / { id } / approval;

// POST / admin / agenda / { id } / disapproval;
