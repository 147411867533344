import React, { PropsWithChildren } from 'react';

import { CloseIcon } from 'assets/iicon';
import classNames from 'components/styled/util';

interface ModalLayoutProps {
  title: string | React.ReactNode;
  close?: () => void;
  className?: string;
}

const ModalLayout = ({ title, close = () => {}, children, className }: PropsWithChildren<ModalLayoutProps>) => {
  return (
    <div className={classNames('flex min-w-[480px] flex-col rounded-5 bg-white shadow-modal', className)}>
      {/* header */}
      <div className="relative px-6 pb-5 pt-[22px]">
        <span className="text-22 font-semibold leading-[1.36] text-black-title">{title}</span>
        <CloseIcon className="absolute right-4 top-4 size-8 cursor-pointer" onClick={close} />
      </div>

      {/* content */}
      {children}
    </div>
  );
};

export default ModalLayout;
