import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GiphyFetch } from '@giphy/js-fetch-api';

import useMessageInput from '../../../hooks/useMessageInput';

import ChatMessageEmoticonEmojiInput from './ChatMessageEmoticonEmojiInput';
import ChatMessageEmoticonGifInput from './ChatMessageEmoticonGifInput';

import { ReactComponent as CloseIcon } from 'assets/icons/ic-close.svg';
import classNames from 'components/styled/util';

enum Tab {
  EMOJI,
  GIF
}

const ChatMessageEmoticonInput = () => {
  const [tab, setTab] = useState<Tab>(Tab.EMOJI);
  const { closeEmojiPicker } = useMessageInput();
  const { t } = useTranslation();

  const refGf = useRef<GiphyFetch | null>(null);

  const onChangeTab = useCallback(
    (selectedTab: Tab) => () => {
      setTab(selectedTab);
    },
    []
  );

  useEffect(() => {
    if (!refGf.current) {
      refGf.current = new GiphyFetch('t8C4qqiT51Q8OG9K8bLoBN6LZtmGd2dz');
    }
  }, []);

  const tabs = [
    { key: Tab.EMOJI, label: t('chat.stickerPopup.tabs.emoji') },
    { key: Tab.GIF, label: t('chat.stickerPopup.tabs.gif') }
  ];

  const tabRefs = useRef<HTMLButtonElement[]>([]);

  return (
    <div className="absolute bottom-0 right-0 top-[-486px] z-20 h-[478px] w-[318px] rounded-5 bg-white p-5 shadow-layer2">
      <div className="mb-3 flex items-center justify-between">
        <div className="text-22 font-semibold text-black-title">{t('chat.stickerPopup.title')}</div>
        <button type="button" className="close" onClick={closeEmojiPicker}>
          <CloseIcon className="size-8" />
        </button>
      </div>

      <div className="relative mt-0 border-b border-gray-400">
        <div className="flex items-center gap-7">
          {tabs.map((t, i) => (
            <button
              key={t.key}
              ref={(el) => {
                if (el) tabRefs.current[i] = el;
              }}
              type="button"
              className={classNames('group px-0.5 pb-2 text-14 text-gray-600', {
                'font-semibold text-primary-100': tab === t.key
              })}
              onClick={onChangeTab(t.key)}
            >
              <div className="flex items-center gap-0.5">
                <span>{t.label}</span>
              </div>
            </button>
          ))}
          {/* tab bar */}
          <div
            className="absolute bottom-0 h-0.5 bg-primary-100 transition-all"
            style={{
              width: tabRefs.current?.[tab]?.offsetWidth || 0,
              left: tabRefs?.current?.[tab]?.offsetLeft || 0
            }}
          />
        </div>
      </div>

      {tab === Tab.EMOJI && <ChatMessageEmoticonEmojiInput />}
      {tab === Tab.GIF && <ChatMessageEmoticonGifInput />}
    </div>
  );
};
export default ChatMessageEmoticonInput;
