import { CustomChannelType } from '../../types/common';

export interface VoteParamsInterface {}

export interface PostReportChannelRequestInterface {
  title: string;
  content: string;
  description?: string;
  channel_url: string;
  custom_type: CustomChannelType;
}

export interface PostReportMessageRequestInterface extends PostReportChannelRequestInterface {
  message_id: number;
  channel_type: 'group_channels' | 'open_channels';
}

export enum LocusMeetingPublicType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  CLUB_MEMBER = 'CLUB_MEMBER'
}
export interface PostLocusMeetingsPayload {
  title: string;
  description: string;
  thumbnail_image_url: string;
  public_type: LocusMeetingPublicType;
  club_id?: number;
}

export interface LocusMeetingListParams {
  joined?: boolean;
  ordering?: 'created' | 'memberCount';
  bookmarked?: boolean;
  search?: string;
}
