import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/ic-close.svg';
import { StyledCenter, StyledColumn, StyledRow } from '../../components/styled/styled';
import useAlert from '../../hooks/useAlert';
import useGroupChannel from '../../hooks/useGroupChannel';
import useVoteIframe, { voteUrl } from '../../hooks/useVoteIframe';
import api from '../../libs/apis';
import queryKeys from '../../libs/apis/queryKeys';
import { VoteInterface } from '../../libs/apis/response.type';
import { CustomMessageType } from '../../types/common';
import { ResponseCommand, ResponseCommandMap } from '../../types/postMessageCommand';

import GalleryVoteOption from './GalleryVoteOption';

type Params = {
  channelUrl: string;
  pollId: string;
};

const ChannelPollVote: React.FC = () => {
  const navigate = useNavigate();
  const { pollId } = useParams<Params>();
  const { sendMessageToCurrentChannel } = useGroupChannel();
  const { show, close } = useAlert();
  const { t } = useTranslation();

  const voteIframeRef = useRef<HTMLIFrameElement>(null);
  const votersIframeRef = useRef<HTMLIFrameElement>(null);

  // 투표중지에만 사용! 투표 중지 후 투표 결과를 받아오기 위한 voteId (pollId와 같음)
  const [voteId, setVoteId] = useState(0);
  const [isOpenVoters, setIsOpenVoters] = useState(false);
  const [isOpenedGallery, setIsOpenedGallery] = useState(false);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [imageSourceList, setImageSourceList] = useState<string[]>([]);

  const voteQuery = useQuery<VoteInterface>({
    queryKey: [...queryKeys.vote.getItem(voteId)],
    queryFn: async () => {
      const response = await api.getVoteById(voteId);
      return response.data;
    },
    enabled: !!voteId
  });

  useEffect(() => {
    if (voteQuery.isSuccess) {
      sendMessageToCurrentChannel({
        message: voteQuery.data?.title,
        customType: CustomMessageType.POLL,
        data: JSON.stringify(voteQuery.data)
      });
    }
  }, [voteQuery.isSuccess]);

  //

  const handleStopVote = useCallback((event: MessageEvent) => {
    // voteId 로 vote GET 해서 메세지 쏘기..
    const voteId = event.data?.data?.voteId;
    if (voteId == null) return;

    setVoteId(voteId);
  }, []);

  const handleClickVoters = useCallback(() => {
    setIsOpenVoters((prev) => !prev);
  }, []);

  const handleClickVoteOptionImage = useCallback((event: MessageEvent) => {
    try {
      const { index, sourceList } = event?.data?.data ?? {};
      setIsOpenedGallery(true);
      setImageIndex(index);
      setImageSourceList(sourceList);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleVoteSuccess = useCallback(() => {
    show({
      content: t('chat.pollPopup.voteAlert.complete'),
      buttons: [
        {
          title: t('common.button.confirm'),
          onClick: close
        }
      ]
    });
  }, [t]);

  const handleVoteEditSuccess = useCallback(() => {}, []);

  const handleNavigateVoteList = useCallback(() => {
    setIsOpenVoters(false);
    close();
  }, []);

  const commandMap = useMemo<ResponseCommandMap>(
    () =>
      new Map([
        [ResponseCommand.StopVote, handleStopVote],
        [ResponseCommand.ClickVoters, handleClickVoters],
        [ResponseCommand.ClickVoteOptionImage, handleClickVoteOptionImage],
        [ResponseCommand.VoteSuccess, handleVoteSuccess],
        [ResponseCommand.EditSuccess, handleVoteEditSuccess],
        [ResponseCommand.NavigateVoteList, handleNavigateVoteList]
      ]),
    []
  );
  useVoteIframe({ ref: voteIframeRef, commandMap });
  useVoteIframe({ ref: votersIframeRef });

  const handleClickPollVoteClose = () => {
    navigate(`${location.pathname.split('/').slice(0, 3).join('/')}`);
  };

  const handleClickVotersClose = () => {
    setIsOpenVoters(false);
  };

  const handleClickGalleryClose = useCallback(() => {
    setIsOpenedGallery(false);
  }, []);

  return (
    <StyledBackdrop>
      <StyledChannelPollVoteWrapper>
        <StyledPollVoteModal>
          <StyledModalHeader>
            <StyledModalHeaderLabel>{t('chat.pollPopup.title')}</StyledModalHeaderLabel>
            <StyledModalCloseIcon onClick={handleClickPollVoteClose} />
          </StyledModalHeader>

          <StyledVoteIframeWrapper>
            {pollId != null && (
              <iframe ref={voteIframeRef} src={`${voteUrl}/vote/${pollId}/chat`} width="100%" height="100%" />
            )}
          </StyledVoteIframeWrapper>
        </StyledPollVoteModal>

        {isOpenVoters && (
          <StyledPollVotersModal>
            <StyledModalHeader>
              <StyledModalHeaderLabel>{t('chat.pollPopup.voters.title')}</StyledModalHeaderLabel>
              <StyledModalCloseIcon onClick={handleClickVotersClose} />
            </StyledModalHeader>

            <iframe ref={votersIframeRef} src={`${voteUrl}/voters/${pollId}`} width="100%" height={566} />
          </StyledPollVotersModal>
        )}

        {isOpenedGallery === true && (
          <GalleryVoteOption index={imageIndex} sourceList={imageSourceList} onClose={handleClickGalleryClose} />
        )}
      </StyledChannelPollVoteWrapper>
    </StyledBackdrop>
  );
};

export default ChannelPollVote;

const StyledModalContainerMixin = css`
  width: 600px;
  height: 640px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
`;

const StyledPollVotersModal = styled(StyledColumn)`
  ${StyledModalContainerMixin}
  width: 320px;
  height: auto;
`;

const StyledVoteIframeWrapper = styled.div`
  height: 566px;
  overflow: overlay;
`;

const StyledModalCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  fill: ${({ theme }) => theme.colors.gray800};
`;

const StyledModalHeaderLabel = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.44px;
  color: ${({ theme }) => theme.colors.black200};
`;

const StyledModalHeader = styled(StyledRow)`
  align-items: center;
  flex-shrink: 0;
  height: 72px;
  padding: 2px 24px 0 24px;
  position: relative;
`;

const StyledPollVoteModal = styled(StyledColumn)`
  ${StyledModalContainerMixin}
`;

const StyledChannelPollVoteWrapper = styled(StyledRow)`
  gap: 6px;

  align-items: flex-end;
`;

const StyledBackdrop = styled(StyledCenter)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(0, 0, 0, 0.4);

  z-index: 30;
`;
