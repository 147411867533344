/* eslint-disable no-undef */

interface RequestType {
  promise: Promise<any>;
  reject: (reason?: any) => void;
}

interface MessageResponse {
  success: boolean;
  [key: string]: any;
}

let currentRequest: RequestType | null = null;

export const sendMessageToExtension = async (
  extensionId: string,
  message: { type: string; [key: string]: any }
): Promise<any> => {
  if (!extensionId) {
    throw new Error('not exist extensionId');
  }
  if (!chrome?.runtime) {
    window.open(
      `https://chromewebstore.google.com/detail/Creta%20Wallet/khkgonklnkgfcglcoegbmaenlkoncgjd?hl=ko`,
      '_blank'
    );
    throw new Error('크레타 월렛 확장프로그램이 필요합니다');
  }

  // 이전 요청이 있다면 reject
  if (currentRequest) {
    currentRequest.reject(new Error('새로운 요청으로 인해 취소됨'));
  }

  // 새로운 Promise 생성
  let rejectFn: (reason?: any) => void;
  const promise = new Promise((resolve, reject) => {
    rejectFn = reject;
    chrome.runtime.sendMessage(extensionId, message, (response: MessageResponse) => {
      if (chrome.runtime.lastError) {
        reject(chrome.runtime.lastError);
      } else {
        response.success ? resolve(response) : reject(response);
      }
    });
  });

  // reject 함수를 Promise와 함께 저장
  currentRequest = { promise, reject: rejectFn! };

  return promise;
};

export const CRETA_WALLET_EXTENSION_ID = 'khkgonklnkgfcglcoegbmaenlkoncgjd';
export const connectMessage = { type: 'connect' };
