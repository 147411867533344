import React from 'react';

import { MemberDto } from 'apis/types/chat.type';
import classNames from 'components/styled/util';

type Props = {
  mentionList: MemberDto[];
  keyword?: string;
  onMention: (member: MemberDto) => void;
  selectIndex?: number;
};

const ChatMessageMentionInput: React.FC<Props> = ({ mentionList, keyword = '', onMention, selectIndex = 0 }) => {
  return (
    <div className="absolute bottom-[53px] left-0 h-fit max-h-[420px] w-[260px] overflow-y-auto rounded-3 border border-gray-300 bg-white pb-3 pt-5">
      <ul>
        {mentionList.map((member, index) => (
          <li
            key={member.userId}
            className={classNames('flex h-10 items-center gap-2 px-4', {
              'bg-gray-100': selectIndex === index
            })}
            onClick={() => onMention(member)}
          >
            <img className="size-7 shrink-0 rounded-full" src={member.profileUrl} />
            <div className="to-black-body text-13 font-medium">
              {member.nickname.split(new RegExp(`(${keyword})`, 'gi')).map((part, index) => {
                if (part.toLowerCase() === keyword.toLowerCase()) {
                  return (
                    <span key={index} className="text-point-100">
                      {part}
                    </span>
                  );
                }
                return <span key={index}>{part}</span>;
              })}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatMessageMentionInput;
