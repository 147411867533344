import React from 'react';

import Ballon from 'components/jayden/ballon';

type Props = {
  isMe: boolean;
  text: string;
};

const ChatNotificationMessage: React.FC<Props> = ({ text, isMe }) => {
  return (
    <Ballon isMe={isMe}>
      <span className="chat_con">
        <span className="important">{text}</span>
      </span>
    </Ballon>
  );
};

export default ChatNotificationMessage;
