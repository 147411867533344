import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import styled from 'styled-components';

import { ReactComponent as UserBlockIcon } from '../../../assets/icons/ic-user-block.svg';
import mixin from '../../../components/styled/mixin';
import { StyledColumn, StyledText } from '../../../components/styled/styled';
import useAlert from '../../../hooks/useAlert';
import { AlertType } from '../../../store/atoms/alertsState';

import commonApi from 'apis/common-api';
import { SuperClubUserInterface } from 'libs/ApiRepository';
import queryKeys from 'libs/apis/queryKeys';

interface BlockButtonProps {
  user: SuperClubUserInterface;
}

const BlockButton: React.FC<BlockButtonProps> = ({ user }) => {
  const qc = useQueryClient();
  const { show, close } = useAlert();
  const { t } = useTranslation();

  const postBanMutation = useMutation({
    mutationFn: commonApi.postUserBan
  });

  const postUnbanMutation = useMutation({
    mutationFn: commonApi.postUserUnban
  });

  const handleButtonClick = () => {
    show({
      content: (
        <Trans
          i18nKey={
            user.is_blocking ? 'chat.miniProfile.blockAlert.unblockSure' : 'chat.miniProfile.blockAlert.blockSure'
          }
        >
          {{ nickname: user.username || '' }}
        </Trans>
      ),

      buttons: [
        {
          title: t('common.button.cancel'),
          onClick: close
        },
        {
          title: t('common.button.confirm'),
          onClick: async () => {
            close();
            await handleConfirm(user);
          }
        }
      ],
      type: AlertType.UserBlock
    });
  };

  const handleConfirm = async (userData: SuperClubUserInterface) => {
    try {
      if (userData.is_blocking) {
        await postUnbanMutation.mutateAsync({ id: userData.id });
      } else {
        await postBanMutation.mutateAsync({ id: userData.id, content: 'chat' });
      }

      await qc.invalidateQueries(['channel']);
      await qc.invalidateQueries(queryKeys.user.getItem(userData.id));

      show({
        content: (
          <Trans
            i18nKey={
              userData.is_blocking
                ? 'chat.miniProfile.blockAlert.unblockSuccess'
                : 'chat.miniProfile.blockAlert.blockSuccess'
            }
          >
            {{ nickname: user.username || '' }}
          </Trans>
        ),
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ],
        type: AlertType.UserBlock
      });
    } catch (error) {
      show({
        content: (error as Error).message,
        buttons: [
          {
            title: t('common.button.confirm'),
            onClick: close
          }
        ],
        type: AlertType.UserBlock
      });
    }
  };

  return (
    <StyledBlockButtonWrapper>
      <button
        className="group flex size-12 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white hover:bg-gray-100"
        onClick={handleButtonClick}
      >
        <UserBlockIcon className="size-6 group-active:fill-primary-100" />
      </button>
      <StyledButtonLabel>
        {user.is_blocking ? t('chat.miniProfile.button.unblock') : t('chat.miniProfile.button.block')}
      </StyledButtonLabel>
    </StyledBlockButtonWrapper>
  );
};

export default BlockButton;

const StyledButtonLabel = styled(StyledText)`
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.26px;
  color: ${({ theme }) => theme.colors.gray800};
`;

const StyledBlockButtonWrapper = styled(StyledColumn)`
  ${mixin.transitionMixin}
  gap: 4px;

  :active {
    ${StyledButtonLabel} {
      color: ${({ theme }) => theme.colors.primary100};
    }
  }
`;
