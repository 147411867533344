import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { UserMessage } from '@sendbird/chat/message';

import useMessageInput from '../../../hooks/useMessageInput';
import { UploadToS3Response } from '../../../libs/s3';
import { fetchMessages } from '../../../libs/sendbird';
import { CustomMessageType } from '../../../types/common';

import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';

const ChatMessageAttachmentGallerySearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToChannel } = useMessageInput();
  const [images, setImages] = useState<UploadToS3Response[]>([]);

  const { data: currentChannel } = useCurrentChannel();

  const [selected, setSelected] = useState<UploadToS3Response[]>([]);

  const onSelectImage = (image: UploadToS3Response) => () => {
    if (selected.includes(image)) {
      setSelected(selected.filter((item) => item !== image));
      return;
    }

    setSelected((prev) => [...prev, image]);
  };

  const onConfirm = async () => {
    if (!currentChannel) return;

    await sendMessageToChannel(currentChannel, {
      message: 'upload image',
      data: JSON.stringify(selected),
      customType: CustomMessageType.IMAGE
    });
    navigate(-1);
  };

  useEffect(() => {
    const search = async () => {
      if (!currentChannel) {
        return;
      }

      const messages = (await fetchMessages(currentChannel.channelUrl, {
        customTypesFilter: [CustomMessageType.IMAGE],
        limit: 8
      })) as UserMessage[];

      setImages(
        messages.flatMap<UploadToS3Response>((message) => {
          try {
            if (message.data) {
              return JSON.parse(message.data);
            } else {
              return JSON.parse(message.message);
            }
          } catch {
            return [];
          }
        })
      );
    };

    search();
  }, []);

  return (
    <div className="h-full pt-5">
      <div className="mr-[-5px] h-full max-h-[266px] overflow-x-hidden overflow-y-scroll">
        <ul className="grid grid-cols-2 gap-1">
          {images.map((image, index) => {
            return (
              <li key={index} onClick={onSelectImage(image)} className="h-[128px] w-[134px]">
                <img
                  className={classNames('h-[128px] w-[134px] shrink-0 object-cover', {
                    'border-2 border-primary-100': selected.includes(image)
                  })}
                  src={image.cloudFrontUrl}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="py-5">
        <Button
          color="primary"
          size="lg"
          className="w-full text-center"
          onClick={onConfirm}
          disabled={selected.length === 0}
        >
          {t('common.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default ChatMessageAttachmentGallerySearch;
