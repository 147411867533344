import React from 'react';

import { ArrowUpIcon } from 'assets/iicon';
import { CretaLogo } from 'assets/images';
import classNames from 'components/styled/util';
import MaskingAddressBadge from 'components/wallet/components/masking-address-badge';

interface CretaSelectProps {
  address?: string;
  arrow?: boolean;
  isOpen?: boolean;
  onClick?: () => void;
}

const CretaSelect = (props: CretaSelectProps) => {
  const { address = '', arrow, isOpen, onClick } = props;
  return (
    <div className="relative" onClick={onClick}>
      <div className="absolute left-4 top-1/2 size-6 -translate-y-1/2">
        <img draggable={false} className="size-6" src={CretaLogo} alt="1" />
      </div>
      {/* <div className="w-full select-none px-4 py-[13px] pl-12 text-16 font-medium"> */}
      <div className="w-full select-none px-4 py-[13px] pl-12 text-16 font-medium">CRETA Wallet</div>
      <div className="absolute right-4 top-1/2 flex -translate-y-1/2 items-center gap-3">
        <p className="text-16 text-gray-700">
          <MaskingAddressBadge address={address} />
        </p>
        {arrow && (
          <ArrowUpIcon
            className={classNames('size-6 rotate-180 text-gray-300 transition-all', {
              'rotate-0 text-gray-500': isOpen
            })}
          />
        )}
      </div>
    </div>
  );
};

export default CretaSelect;
