import axios, { AxiosError, type CreateAxiosDefaults, type InternalAxiosRequestConfig } from 'axios';

import { getStorage } from '../../utils/storage';

import { AuthStorage } from 'constants/storage';

const superClubBaseURL = `${process.env.REACT_APP_COMMON_API_URL}/v1/`;
const chatBaseURL = `${process.env.REACT_APP_CHAT_API_URL}/v1/`;
const clubBaseURL = `${process.env.REACT_APP_CLUB_API_URL}/v1/`;
const forumBaseURL = `${process.env.REACT_APP_FORUM_API_URL}/v1/`;
const voteBaseURL = `${process.env.REACT_APP_VOTE_API_URL}/v1/`;

const axiosConfig: CreateAxiosDefaults<any> = {
  timeout: 6000,
  headers: { 'Content-Type': 'application/json' }
};

const superClubAxios = axios.create({
  baseURL: superClubBaseURL,
  ...axiosConfig
});

const setTokenIfTokenExist = (config: InternalAxiosRequestConfig) => {
  const token = getStorage(AuthStorage.ACCESS_TOKEN);
  if (token !== null) {
    config.headers.Authorization = token;
  }
  return config;
};

// 요청 인터셉터: 헤더에 토큰 추가
superClubAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config = setTokenIfTokenExist(config);

    const languageCode = getStorage('i18nextLng');
    if (languageCode && config.method === 'get') {
      config.params = { ...config.params, language_code: languageCode };
    }
    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

const chatAxios = axios.create({
  baseURL: chatBaseURL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
});

// 요청 인터셉터: 헤더에 토큰 추가
chatAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config = setTokenIfTokenExist(config);
    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

const clubAxios = axios.create({
  baseURL: clubBaseURL,
  ...axiosConfig
});

// 요청 인터셉터: 헤더에 토큰 추가
clubAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config = setTokenIfTokenExist(config);
    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

const forumAxios = axios.create({
  baseURL: forumBaseURL,
  ...axiosConfig
});

// 요청 인터셉터: 헤더에 토큰 추가
forumAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config = setTokenIfTokenExist(config);
    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

const voteAxios = axios.create({
  baseURL: voteBaseURL,
  ...axiosConfig
});

// 요청 인터셉터: 헤더에 토큰 추가
voteAxios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config = setTokenIfTokenExist(config);
    return config;
  },
  async (error: AxiosError) => {
    return await Promise.reject(error);
  }
);

export { chatAxios, clubAxios, forumAxios, superClubAxios, voteAxios };
