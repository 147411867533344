import React, { PropsWithChildren } from 'react';

import classNames from 'classnames';

interface BallonProps extends PropsWithChildren {
  isMe?: boolean;
  className?: string;
}

const Ballon = (props: BallonProps) => {
  const { children, className, isMe } = props;

  const commonBallonClassNames = 'inline-block border border-gray-300 py-1.5 px-3 relative max-w-[450px]';

  return (
    <div
      className={classNames(
        commonBallonClassNames,
        'rounded-b-5',
        {
          'rounded-tl-5 rounded-tr-0 bg-gray-300': isMe,
          'rounded-tr-5 rounded-tl-0 bg-white': !isMe
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Ballon;
