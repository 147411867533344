import type { DefaultTheme } from 'styled-components';

const colors = {
  white: 'white',
  black: 'black',
  blue: 'blue',
  red: 'red',
  pink: 'pink',
  green: 'green',

  black100: '#121212',
  black200: '#242A3C',

  gray100: '#f8f8f8',
  gray200: '#eeeeee',
  gray300: '#e0e0e0',
  gray400: '#cdcdd1',
  gray500: '#bdbdbd',
  gray600: '#a7a8ab',
  gray700: '#777777',
  gray800: '#545557',

  gray900: '#777777',
  gray1000: '#666',
  gray1100: '#555',
  gray1200: '#444',
  gray1300: '#333',
  gray1400: '#222',
  gray1500: '#111',

  // grayF8: '#f8f8f8',
  // grayEE: '#eeeeee',
  // grayE0: '#e0e0e0',
  // grayCD: '#cdcdd1',
  // grayBD: '#bdbdbd',
  // grayA7: '#a7a8ab',
  // gray77: '#777777',
  // gray54: '#545557',

  primary100: '#9013fe',
  primary200: '#7d0edf',
  primary300: '#6b09c3',

  secondary100: '#6563ff',
  secondary200: '#5752d0',
  secondary300: '#423dbe',

  point100: '#ff45a1',
  point200: '#ed348f',
  point300: '#d31773',

  success100: '#00b78b',
  success200: '#01a37c',
  success300: '#039572',

  warning100: '#fcd332',
  warning200: '#f7d100',
  warning300: '#ebc702',

  error100: '#ff2a55',
  error200: '#ed0f3c',
  error300: '#d30c34',

  like: '#27a2ff',
  new: '#ffd300',
  label: '#5752d0',
  body: '#f5f5f5'
} as const;

export type ColorsType = typeof colors;

const theme: DefaultTheme = {
  colors
};

export default theme;
