import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '@sendbird/chat';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { fetchUser } from '../../../libs/sendbird';
import likedUserListPopupState from '../../../store/atoms/likedUserListPopupState';

import { ReactComponent as LikeIcon } from 'assets/icons/ic-chat-heart-on.svg';
import classNames from 'components/styled/util';

type Props = {
  countLiked?: number;
  messageId: number;
  reactionsUserIds?: string[];
  isMine: boolean;
};

type LikedUser = User | null;

const LikedUserList: React.FC<Props> = ({ countLiked = 0, messageId, reactionsUserIds, isMine }) => {
  const navigate = useNavigate();
  const popupMessageId = useRecoilValue(likedUserListPopupState);
  const [likedUsers, setLikedUsers] = useState<LikedUser[]>([]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onPopup = useRecoilCallback(
    ({ snapshot, set }) =>
      () => {
        const popupMessageId = snapshot.getLoadable(likedUserListPopupState).getValue();
        if (popupMessageId === messageId) {
          set(likedUserListPopupState, null);
        } else {
          set(likedUserListPopupState, messageId);
        }
      },
    []
  );

  useEffect(() => {
    async function init() {
      const promises: Promise<LikedUser>[] = [];
      for (const userId of reactionsUserIds || []) {
        promises.push(fetchUser(userId));
      }

      const results = await Promise.allSettled(promises);

      const fulfilledUsers = results
        .filter((result): result is PromiseFulfilledResult<LikedUser> => result.status === 'fulfilled')
        .map((result) => result.value);

      if (fulfilledUsers.length > 0) {
        setLikedUsers(fulfilledUsers);
      }
    }
    init();
  }, [reactionsUserIds]);

  const onProfile = useCallback(
    (userId: string) => () => {
      navigate(`miniprofile/${userId}`, {
        replace: false
      });
    },
    [navigate]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onPopup();
      }
    };
    const active = messageId === popupMessageId;
    if (active) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      if (active) {
        document.removeEventListener('click', handleClickOutside);
      }
    };
  }, [messageId, popupMessageId, onPopup, wrapperRef.current]);

  return (
    // love_area
    <div
      className={classNames('mt-1 flex', {
        'pl-16': !isMine,
        'pr-16 justify-end': isMine
      })}
    >
      <div className="relative inline-block w-fit" ref={wrapperRef}>
        <button
          type="button"
          className="flex h-[25px] items-center gap-1 rounded-full bg-white p-1 pr-1.5"
          onClick={onPopup}
        >
          <LikeIcon className="size-4" />
          <span className="text-12 text-black-title">{countLiked}</span>
        </button>

        <div
          // love_div
          className={classNames(
            'absolute top-1/2 w-[158px] -translate-y-1/2 rounded-3 border border-gray-300 bg-white p-4 shadow-layer',
            {
              'right-full -translate-x-[7px]': isMine,
              'left-full translate-x-[7px]': !isMine
            },
            {
              flex: messageId === popupMessageId,
              hidden: messageId !== popupMessageId
            }
          )}
        >
          <ul className="-mr-5 flex max-h-[192px] w-full flex-col gap-2 overflow-y-auto">
            {likedUsers.map((likedUser) => {
              if (!likedUser) {
                return null;
              }

              return (
                <li
                  key={likedUser.userId}
                  onClick={onProfile(likedUser.userId)}
                  className="flex cursor-pointer items-center"
                >
                  <img className="size-8 rounded-4" src={likedUser.profileUrl} />
                  <span className="ml-2 inline-block truncate text-13 font-medium text-black-body">
                    {likedUser.nickname}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LikedUserList;
