import { User } from '@sendbird/chat';
import { atom } from 'recoil';

export type UserState = User;

const blockedUserListState = atom<UserState[]>({
  key: 'blockedUserListState',
  default: []
});

export default blockedUserListState;
