import React from 'react';

import { useRecoilCallback } from 'recoil';

import useCurrentUser from '../../../hooks/useCurrentUser';
import useGroupChannel from '../../../hooks/useGroupChannel';
import useMessageInput from '../../../hooks/useMessageInput';
import { fetchGroupChannel, fetchMessage } from '../../../libs/sendbird';
import messageMoreState, { MessageMoreState } from '../../../store/atoms/messageMoreState';
import { ChatMessage } from '../../../types/common';

import ChatMessageMoreMenu from './ChatMessageMoreMenu';

import { ReactComponent as HeartOnIcon } from 'assets/icons/ic-chat-heart-on.svg';
import { ReactComponent as HeartOutlineIcon } from 'assets/icons/ic-chat-heart-outline.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/ic-more.svg';
import { ReactComponent as ReplyIcon } from 'assets/icons/ic-reply-sm-bk.svg';
import classNames from 'components/styled/util';

import { useCurrentChannel } from 'hooks/use-current-channel';

type Props = {
  isMine: boolean;
  message: ChatMessage;
  moreState: MessageMoreState;
  isLikedByMe: boolean;
  refChattingArea: React.RefObject<HTMLDivElement>;
};

const ChatMessageMoreButton: React.FC<Props> = ({ message, moreState, isLikedByMe, isMine }) => {
  const { updateMessage } = useGroupChannel();
  const { data: currentChannel } = useCurrentChannel();
  const { currentUser } = useCurrentUser();
  const { reply } = useMessageInput();

  const onReply = useRecoilCallback(
    ({ set }) =>
      () => {
        reply(message);
        set(messageMoreState, (state) => ({
          ...state,
          popup: false
        }));
      },
    [reply, message]
  );

  const onLike = async () => {
    if (!currentUser?.userId) {
      return;
    }

    if (!currentChannel) return;

    const groupChannel = await fetchGroupChannel(currentChannel.channelUrl);
    const groupChannelMessage = await fetchMessage(currentChannel.channelUrl, message.messageId);

    // await currentChannel.deleteReaction(message, currentUser.userId);
    if (isLikedByMe === true) {
      await groupChannel.deleteReaction(groupChannelMessage, 'like');
      const reactionUserIds = message.reactionUserIds.filter((reactionUserId) => reactionUserId !== currentUser.userId);
      const reactionCount = reactionUserIds.length;
      await updateMessage(message.messageId, {
        reactionCount,
        reactionUserIds
      });
    } else {
      await groupChannel.addReaction(groupChannelMessage, 'like');
      const reactionUserIds = [...message.reactionUserIds, currentUser.userId];
      const reactionCount = reactionUserIds.length;
      await updateMessage(message.messageId, {
        reactionCount,
        reactionUserIds
      });
    }
  };

  const onMore = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageMoreState, (state) => ({
          ...state,
          popup: true,
          messageId: message.messageId
        }));
      },
    []
  );

  return (
    <div
      className={classNames('invisible relative inline-flex h-fit items-end gap-1 px-0.5 group-hover:visible', {
        'w-0 group-hover:w-fit': isMine
      })}
    >
      {message?.parentMessage == null && (
        <button type="button" className="text-gray-900" onClick={onReply}>
          <span className="sr-only">답글</span>
          <ReplyIcon className={classNames('size-5 text-gray-500 hover:text-gray-900', {})} />
        </button>
      )}

      <button type="button" className={classNames({})} onClick={onLike}>
        {isLikedByMe && <HeartOnIcon className="size-5" />}
        {!isLikedByMe && <HeartOutlineIcon className="size-5 text-gray-500 hover:text-gray-900" />}

        <span className="sr-only">좋아요</span>
      </button>

      <button type="button" className="relative" onClick={onMore}>
        <MoreIcon className="size-5 text-gray-500 hover:text-gray-900" />
        <span className="sr-only">더보기</span>

        <div
          className={classNames({
            'absolute z-10 w-screen -left-1/2 -translate-x-1/2 h-4': moreState.popup
          })}
        />
        {moreState.popup === true && moreState.messageId === message.messageId && (
          <ChatMessageMoreMenu message={message} />
        )}
      </button>
    </div>
  );
};
export default ChatMessageMoreButton;
