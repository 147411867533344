import { atom } from 'recoil';

import { MemberDto } from 'apis/types/chat.type';

const mentionUserIdsState = atom<MemberDto[]>({
  key: 'mentionUserIdsState',
  default: []
});

export default mentionUserIdsState;
