import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecoilState } from 'recoil';

import OnePerInvalidate from '../components/one-per-invalidate';
import { multiCoinSendMapState } from '../recoil';
import { decimalInput, numberUI, splitPerUser, totalVolume } from '../util';

import { InfoIcon } from 'assets/iicon';
import ModalLayout from 'components/alert/modal-layout';
import Button from 'components/jayden/Button';
import classNames from 'components/styled/util';

interface EnterAtOnceModalProps {
  userList: any[];
  close?: () => void;
}

const EnterAtOnceModal = (props: EnterAtOnceModalProps) => {
  const { userList, close = () => {} } = props;
  const { t } = useTranslation();

  const [isEqual, setIsEqual] = useState(true);
  const [input, setInput] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (isEqual) {
      decimalInput(value, setInput, 8);
    } else {
      decimalInput(value, setInput, 4);
    }
  };

  const [_, setMap] = useRecoilState(multiCoinSendMapState);

  const onSubmit = () => {
    const userPerPrice = isEqual ? Number(input) : splitPerUser(input, userList.length, 5);
    setMap(
      userList.map((user) => ({ id: user.id, amount: String(numberUI(userPerPrice, 5)), address: user.wallet_address }))
    );
    close();
  };

  return (
    <ModalLayout title={t('sendCoin.enterAtOnce.title')} close={close} className="w-[480px]">
      <main className="px-6">
        <div className="flex items-center justify-between">
          <h4 className="text-16 font-semibold text-black-title">{t('sendCoin.enterAtOnce.volume')}</h4>

          <div className="flex items-center gap-1 text-gray-600">
            <InfoIcon className="size-5" />
            <p className="text-14 font-light">
              {isEqual ? t('sendCoin.enterAtOnce.guide.eight') : t('sendCoin.enterAtOnce.guide.four')}
            </p>
          </div>
        </div>

        <div className="mt-3 flex items-center gap-3 bg-gray-100 p-5">
          <input
            value={input}
            onChange={handleInputChange}
            className="flex-1 border-b-2 border-black-body bg-transparent pb-2 text-20 font-semibold text-black-body placeholder:font-normal"
            placeholder={t('wallet.sendCoin.input.placeholder')}
          />

          <p className="shrink-0 text-18 text-black-body">CRETA</p>
        </div>

        <div className="mt-6">
          <div className="flex items-end justify-between">
            <h3 className="text-16 font-semibold">
              <span className="text-20 text-primary-100">{userList.length}</span>
              &nbsp;User
            </h3>

            <div className="flex items-center gap-1.5">
              <button
                className={classNames(
                  'table h-9 rounded-lg border border-gray-300 px-4 text-14 font-medium text-black-body hover:bg-gray-200 active:bg-gray-300',
                  {
                    'text-white font-medium bg-gradient-point shadow-[0_0_8px_0_rgba(0,0,0,0.15)]': isEqual
                  }
                )}
                onClick={() => {
                  setIsEqual(true);
                  setInput('');
                }}
              >
                <p className="table-cell align-middle">{t('sendCoin.enterAtOnce.tab.equally')}</p>
              </button>

              <button
                className={classNames(
                  'table h-9 rounded-lg border border-gray-300 px-4 text-14 font-medium text-black-body hover:bg-gray-200 active:bg-gray-300',
                  {
                    'text-white font-medium bg-gradient-point shadow-[0_0_8px_0_rgba(0,0,0,0.15)]': !isEqual
                  }
                )}
                onClick={() => {
                  setIsEqual(false);
                  setInput('');
                }}
              >
                <p className="table-cell align-middle">{t('sendCoin.enterAtOnce.tab.split')}</p>
              </button>
            </div>
          </div>

          <div className="mt-3 flex items-center justify-between rounded-2 bg-gray-100 py-[17.5px] pl-5 pr-[22px]">
            <p className="text-16 font-medium text-gray-800">1 {t('sendCoin.userPer.label')}</p>

            <p className="text-16 text-black-body">
              <span className="text-21 font-semibold">
                {isEqual ? numberUI(Number(input)) : splitPerUser(input, userList.length, 5)}
              </span>
              &nbsp;CRETA
            </p>
          </div>
          <div className="mt-2 flex items-center justify-between rounded-2 bg-[#faf5ff] py-[17.5px] pl-5 pr-[22px]">
            <p className="text-16 font-medium text-primary-100">{t('common.total.label')}</p>

            <p className="text-16 text-primary-100">
              <span className="text-21 font-semibold">
                {isEqual
                  ? totalVolume(input, userList.length)
                  : numberUI(Number(splitPerUser(input, userList.length, 5)) * userList.length, 5)}
              </span>
              &nbsp;CRETA
            </p>
          </div>

          <OnePerInvalidate
            show={
              !isEqual && input.trim() !== '' && Number(splitPerUser(input, userList.length)) * userList.length === 0
            }
          />
        </div>
      </main>

      <footer className="p-5">
        <Button
          className="w-full"
          center
          onClick={onSubmit}
          disabled={!input || totalVolume(input, userList.length) === '0'}
          size="lg"
          color="primary"
        >
          {t('common.button.submit')}
        </Button>
      </footer>
    </ModalLayout>
  );
};

export default EnterAtOnceModal;
