import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs, { Dayjs } from 'dayjs';

import { DateFormatType } from 'constants/date.type';

const MINUTES_IN_YEAR = 365 * 24 * 60;
const MINUTES_IN_WEEK = 7 * 24 * 60;
const MINUTES_IN_DAY = 24 * 60;
const MINUTES_IN_HOUR = 60;

const useDateFormatter = () => {
  const { t, i18n } = useTranslation();
  const currentYear = dayjs().year();

  const calculate = useCallback((dateProps: string) => {
    const date = dayjs(dateProps);
    const now = dayjs();
    const minute = now.diff(date, 'minute');
    const second = now.diff(date, 'second');

    return {
      date,
      minute,
      second
    };
  }, []);

  const dateFormatLong = useCallback((dateProps: any) => {
    const { date, minute } = calculate(dateProps);

    switch (true) {
      case minute > MINUTES_IN_YEAR:
        return date.format('MMM. D, YYYY');

      case minute > MINUTES_IN_WEEK:
        return date.format('MMM. D');

      case minute > MINUTES_IN_DAY: {
        const day = Math.floor(minute / MINUTES_IN_DAY);
        if (day === 1) return t('common.dateFormat.longSingle.withinWeek', { day });
        return t('common.dateFormat.longPlural.withinWeek', { day });
      }

      case minute > MINUTES_IN_HOUR: {
        const hour = Math.floor(minute / MINUTES_IN_HOUR);
        if (hour === 1) return t('common.dateFormat.longSingle.withinDay', { hour });
        return t('common.dateFormat.longPlural.withinDay', { hour });
      }

      default: {
        if (minute === 1) return t('common.dateFormat.longSingle.withinHour', { minute });
        return t('common.dateFormat.longPlural.withinHour', { minute });
      }
    }
  }, []);

  const formattedDate = useCallback(
    (date: Dayjs, formatType: any) => {
      let format = '';
      const isEnglish = i18n.language === 'en';

      switch (formatType) {
        case 'MMM. D, YYYY t hh:mm a':
          format = isEnglish ? 'MMM. D, YYYY t hh:mm a' : 'MM.DD.YYYY t hh:mm a';
          break;
        case 'MMM. D, YYYY':
          format = isEnglish ? 'MMM. D, YYYY' : 'MM.DD.YYYY';
          break;
        case 'MMM. D':
          format = isEnglish ? 'MMM. D' : 'MM. DD';
          break;

        case 'MMM.D. HH:mm:ss':
          format = isEnglish ? 'MMM.D. HH:mm:ss' : 'MM.DD. HH:mm:ss';
          break;

        case 'MMM.D,YYYY':
          format = isEnglish ? 'MMM.D,YYYY' : 'MM.DD.YYYY';
          break;

        case 'MMM.D,YYYY hh:mm a':
          format = isEnglish ? 'MMM.D,YYYY hh:mm a' : 'MM.DD.YYYY hh:mm a';
          break;
        case 'MMM.D,YYYY HH:mm':
          format = isEnglish ? 'MMM.D,YYYY HH:mm' : 'MM.DD.YYYY HH:mm';
          break;

        default:
          break;
      }

      return dayjs(date).format(format);
    },
    [i18n.language]
  );

  const dateFormatShort = useCallback((dateProps: string) => {
    const { minute } = calculate(dateProps);

    switch (true) {
      case minute > MINUTES_IN_YEAR:
        return t('common.dateFormat.short.moreThanYear', { week: Math.floor(minute / MINUTES_IN_WEEK) });

      case minute > MINUTES_IN_WEEK:
        return t('common.dateFormat.short.withinYear', { week: Math.floor(minute / MINUTES_IN_WEEK) });

      case minute > MINUTES_IN_DAY:
        return t('common.dateFormat.short.withinWeek', { day: Math.floor(minute / MINUTES_IN_DAY) });

      case minute > MINUTES_IN_HOUR:
        return t('common.dateFormat.short.withinDay', { hour: Math.floor(minute / MINUTES_IN_HOUR) });

      default:
        return t('common.dateFormat.short.withinHour', { minute });
    }
  }, []);

  const dateFormatNormal = useCallback((dateProps: string) => {
    const { date, minute } = calculate(dateProps);

    switch (true) {
      case currentYear !== date.year():
      case minute > MINUTES_IN_YEAR:
        return formattedDate(date, 'MMM. D, YYYY');

      case minute > MINUTES_IN_WEEK:
        return formattedDate(date, 'MMM. D');

      case minute > MINUTES_IN_DAY:
        return t('common.dateFormat.normal.withinWeek', { day: Math.floor(minute / MINUTES_IN_DAY) });

      case minute > MINUTES_IN_HOUR:
        return t('common.dateFormat.normal.withinDay', { hour: Math.floor(minute / MINUTES_IN_HOUR) });

      default:
        return t('common.dateFormat.normal.withinHour', { minute });
    }
  }, []);

  /**
   * 연도 상관없이 모든 날짜 표시
   *
   * @example 04.09.2024
   */
  const dateFormatAll = useCallback((dateProps: string) => {
    const { date } = calculate(dateProps);

    return formattedDate(date, 'MMM. D, YYYY');
  }, []);

  const dateFormat = useCallback((dateProps: string, formatType = DateFormatType.SHORT) => {
    switch (formatType) {
      case DateFormatType.NORMAL:
        return dateFormatNormal(dateProps);

      case DateFormatType.LONG:
        return dateFormatLong(dateProps);

      default:
        return dateFormatShort(dateProps);
    }
  }, []);

  /**
   * 글로버 채팅에서 사용하는 날짜 포맷
   */
  const dateFormatChat = useCallback((dateProps: string) => {
    const { date, minute, second } = calculate(dateProps);

    switch (true) {
      case currentYear !== date.year():
      case minute > MINUTES_IN_YEAR:
        return formattedDate(date, 'MMM. D, YYYY');

      case minute > MINUTES_IN_WEEK:
        return formattedDate(date, 'MMM. D');

      case minute > MINUTES_IN_DAY:
        return t('common.dateFormat.short.withinWeek', { day: Math.floor(minute / MINUTES_IN_DAY) });

      case minute > MINUTES_IN_HOUR:
        return t('common.dateFormat.short.withinDay', { hour: Math.floor(minute / MINUTES_IN_HOUR) });

      case minute > 0:
        return t('common.dateFormat.short.withinHour', { minute });

      case !minute && second > 0:
        return t('common.dateFormat.short.withinMinute', { second });

      default:
        return t('common.dateFormat.short.withinMinute', { second });
    }
  }, []);

  return {
    dateFormatShort,
    dateFormatNormal,
    dateFormatAll,
    dateFormatLong,
    dateFormat,
    formattedDate,
    dateFormatChat
  };
};

export default useDateFormatter;
