import { useCallback } from 'react';

import {
  MentionType,
  PushNotificationDeliveryOption,
  UserMessage,
  type UserMessageCreateParams
} from '@sendbird/chat/message';
import { useRecoilCallback, useRecoilState } from 'recoil';

import mentionedUsersState from '../store/atoms/mentionedUsersState';
import messageInputState from '../store/atoms/messageInputState';
import { ChatMessage } from '../types/common';

import useMessages from './use-messages';
import useGroupChannel from './useGroupChannel';

import { ChannelType, GroupChannelDto } from 'apis/types/chat.type';
import { fetchMessage } from 'libs/sendbird';

const useMessageInput = () => {
  const { updateNewMessage } = useGroupChannel();
  const { postMessageSendMutation, postMessageReplyMutation } = useMessages();

  const [messageInputStateValue, setMessageInputStateValue] = useRecoilState(messageInputState);

  const openEmojiPicker = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: true,

      isOpenedMemberSearchPopup: false
    }));
  }, [setMessageInputStateValue]);

  const openMemberSearch = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: false,

      isOpenedMemberSearchPopup: true
    }));
  }, [setMessageInputStateValue]);

  const closeEmojiPicker = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedEmojiPopup: false
    }));
  }, [setMessageInputStateValue]);

  const closeMemberSearch = useCallback(() => {
    setMessageInputStateValue((state) => ({
      ...state,
      isOpenedMemberSearchPopup: false
    }));
  }, [setMessageInputStateValue]);

  const reply = useCallback(
    (parentMessage: ChatMessage) => {
      setMessageInputStateValue((state) => ({
        ...state,
        parentMessage,
        isReplyMode: true
      }));
    },
    [setMessageInputStateValue]
  );

  const closeReply = useRecoilCallback(
    ({ set }) =>
      () => {
        set(messageInputState, (state) => ({
          ...state,
          parentMessage: null,
          isReplyMode: false
        }));
      },
    []
  );

  const setMessageText = useRecoilCallback(
    ({ set }) =>
      (messageText: string) => {
        set(messageInputState, (state) => {
          return {
            ...state,
            messageText
          };
        });
      },
    []
  );
  const sendMessageToChannel = useRecoilCallback(
    ({ snapshot, set }) =>
      async (channel: GroupChannelDto, params: UserMessageCreateParams): Promise<UserMessage> => {
        // 언급된 사용자 아이디 추출 및 중복 제거

        const mentionedUsersStateValue = snapshot.getLoadable(mentionedUsersState).getValue();
        const filteredMentionedUserIds = [
          ...new Set(
            mentionedUsersStateValue
              .filter((user) => params.message.includes(`@${user.nickname}`))
              .map((user) => user.userId)
              .concat(params.mentionedUserIds ?? [])
          )
        ];

        // 최종 메시지 파라미터 설정
        const finalParams: UserMessageCreateParams = {
          ...params,
          mentionedUserIds: filteredMentionedUserIds,
          mentionType: MentionType.USERS,
          pushNotificationDeliveryOption:
            PushNotificationDeliveryOption[channel.customType === ChannelType.DM ? 'DEFAULT' : 'SUPPRESS']
        };

        // 메시지 전송 처리 함수
        const sendMessage = async (channelId: string, messageParams: UserMessageCreateParams) => {
          if (params.parentMessageId) {
            const { data } = await postMessageReplyMutation.mutateAsync({
              channelId,
              parentMessageId: params.parentMessageId,
              ...messageParams
            });
            return data.message_id;
          } else {
            const { data } = await postMessageSendMutation.mutateAsync({
              channelId,
              ...messageParams
            });
            return data.message_id;
          }
        };

        // 메시지 전송 및 후속 처리
        const channelId = channel.id;
        const messageId = await sendMessage(channelId, finalParams);
        const message = await fetchMessage(channel.channelUrl, messageId);

        await updateNewMessage(channel.channelUrl, message);

        // 이벤트 디스패치 및 후속 처리
        window.dispatchEvent(
          new CustomEvent('sentNewMessage', {
            detail: { customType: message.customType }
          })
        );

        // 상태 및 답장 모드 초기화
        closeReply();
        set(mentionedUsersState, []);

        return message;
      },
    [
      messageInputStateValue, // 의존성
      closeReply,
      updateNewMessage
    ]
  );

  return {
    isOpenedEmojiPopup: messageInputStateValue.isOpenedEmojiPopup,
    isOpenedMemberSearchPopup: messageInputStateValue.isOpenedMemberSearchPopup,
    isReplyMode: messageInputStateValue.isReplyMode,
    parentMessage: messageInputStateValue.parentMessage,
    messageText: messageInputStateValue.messageText,
    setMessageText,
    openEmojiPicker,
    openMemberSearch,
    closeEmojiPicker,
    closeMemberSearch,
    reply,
    closeReply,
    sendMessageToChannel
  };
};

export default useMessageInput;
